/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  handleFileDownload,
  requestExportPatientDebt,
  requestPatientDebt,
} from 'app/api/accounting'
import { IParamsAccounting } from 'app/api/accounting/model'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { DATE, NOTIFICATION_TYPE } from 'app/common/constants'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { PaymentMethodTranslateEnum } from 'app/components/tables/BaseTableManagement/FilterDeptTable/type'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { notificationController } from 'app/controllers/notification-controller'
import { formatMoneyWithoutSpace, useDebounce } from 'app/hook'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { changeMessage } from 'app/redux/slices/profileSlice'
import { get, isEqual } from 'lodash'
import moment from 'moment'
import { Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handlePermission } from '../common'
import { IGeneralDebtTable, initialPagination } from './common-model'
import { ALink } from './styles'
import BaseText from 'app/components/common/BaseText'

interface IMetadata {
  totalNetAmount?: number
  totalPaid?: number
  totalCash?: number
  totalCard?: number
  totalTransfer?: number
  totalApp?: number
  totalRemain?: number
  totalRefund?: number
}

export const useTableHook = () => {
  const { t } = useTranslation()

  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [filterResponse, setFilterResponse] = useState<IParamsAccounting>()
  const [exportFile, setExportFile] = useState<string[]>([])
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()
  const profile = useAppSelector(state => state.profileSlice)
  const notificationMessage = profile?.message
  const dispatch = useAppDispatch()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_DAILY_INVOICE,
      R.strings.role,
    )
    setExportFile([])
  }, [])

  useEffect(() => {
    if (notificationMessage?.type !== NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT)
      return

    const payload = JSON.parse(notificationMessage?.payload || '')
    const successData = payload?.successData || []
    const errorData = payload?.errorData || []

    if (errorData?.length) {
      downloadNotificationController.downloadErrored({
        duration: 2,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT,
        message: t(R.strings.manage_customer_debt),
        description: t(R.strings.download_errored),
        cancel: t(R.strings.cancel),
      })
    } else if (!isEqual(exportFile, successData)) {
      setExportFile(successData)
      downloadNotificationController.close(
        NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT,
      )
    }
  }, [notificationMessage?.currentTime, notificationMessage?.type])

  const downloadFileForExport = () => {
    if (!exportFile?.length) return

    exportFile.forEach(file =>
      handleFileDownload(
        file,
        t,
        t(R.strings.manage_customer_debt),
        NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT,
      ),
    )

    setExportFile([])
    dispatch(changeMessage({}))
  }

  useEffect(() => {
    downloadFileForExport()

    return downloadFileForExport
  }, [exportFile])

  const [metadata, setMetadata] = useState<IMetadata>({})
  const [pagination, setPagination] = useState(initialPagination)
  const [data, setData] = useState<IGeneralDebtTable[]>([])
  const [total, setTotal] = useState(0)

  const filterResponseDebounce = useDebounce(filterResponse, 500)

  const getData = async () => {
    setIsLoading(true)
    try {
      if (
        filterResponseDebounce?.startDate &&
        filterResponseDebounce?.endDate
      ) {
        const response = await requestPatientDebt({
          treatments: filterResponseDebounce?.treatments ?? '',
          params: {
            page: (pagination?.current || 0) - 1,
            pageSize: pagination?.pageSize,
            keyword: filterResponseDebounce?.search,
            startDate: filterResponseDebounce?.startDate,
            endDate: filterResponseDebounce?.endDate,
            location: filterResponseDebounce?.location,
            paymentMethod: filterResponseDebounce?.paymentMethod,
          },
        })

        setMetadata(response?.metadata)
        setData(
          response?.data?.map((item, index) => ({
            ...item,
            key: index,
          })) ?? [],
        )

        setTotal(response?.metadata?.total)
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [filterResponseDebounce, pagination])

  const handleChangeFilter = (filter: any) => {
    setFilterResponse(filter)
  }

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const onClickExport = async params => {
    setIsLoadingExport(true)
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        await requestExportPatientDebt({
          treatments: params?.treatments,
          params: {
            keyword: params?.search,
            startDate: params?.startDate,
            endDate: params?.endDate,
            location: params?.location,
            paymentMethod: params?.paymentMethod,
          },
        })

        await handleNotification()
      }
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    } finally {
      setIsLoadingExport(false)
    }
  }

  const handleNotification = async () => {
    const permissionNotification = await handlePermission()
    if (
      permissionNotification?.state === 'granted' &&
      permissionNotification?.status === true
    ) {
      return downloadNotificationController.waiting({
        duration: 0,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT,
        message: t(R.strings.manage_customer_debt),
        description: t(R.strings.download_waiting),
        cancel: t(R.strings.cancel),
      })
    }

    if (!permissionNotification?.status)
      notificationController.warning({
        message: t(R.strings.download_warning),
        description: t(R.strings.download_warning_description),
      })

    notificationController.success({
      message: t(R.strings.success),
      description: t(R.strings.export_excel_sendmail_message),
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.date_time),
        key: 'date',
        className: 'date-time-column',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.date).format(DATE.DD_MM_YYYY_HH_MM)}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patient',
        className: 'patient-name-column',
        fixed: 'left',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patient?._id,
              )
            }}
            style={{ fontSize: 12 }}
          >
            {record?.patient?.name}
          </ALink>
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patient',
        className: 'patient-code-column',
        render: (_, record) => (
          <RenderValueTable value={record?.patient?.id} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.doctor),
        key: 'doctorPlan',
        className: 'patient-name-column',
        render: (_, record) => (
          <RenderValueTable value={record?.doctorPlan?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_clinic_name),
        key: 'location',
        className: 'clinic-column',
        render: (_, record) => (
          <RenderValueTable value={record?.location?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.search_appointment_treatment),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.treatments?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.payment_method),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={t(
              PaymentMethodTranslateEnum[
                String(record?.treatments?.paymentMethod)
              ],
            )}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.debt),
        key: 'totalRemain',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.totalRemain ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.treatment_fee),
        key: 'totalNetAmount',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.totalNetAmount ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.paid),
        key: 'totalPaid',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.totalPaid ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.refund),
        key: 'totalRefund',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.totalRefund ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_tip_start_date),
        key: 'dateStartProcedure',
        className: 'date-column',
        render: (_, record) => {
          const dateStartProcedure = get(record, 'dateStartProcedure[0]', '')
          return (
            <RenderValueTable
              value={
                dateStartProcedure
                  ? moment(dateStartProcedure).format(DATE.DD_MM_YYYY)
                  : ''
              }
              type="Base"
            />
          )
        },
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_debt_tip_end_name),
        key: 'endProcedureTreatmentName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record.endProcedureTreatmentName}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_debt_tip_end_date),
        key: 'dateEndProcedure',
        className: 'date-column',
        render: (_, record) => {
          return (
            record.endProcedureTreatmentName &&
            record.dateEndProcedure && 
            (
              <RenderValueTable
                value={moment(record.dateEndProcedure).format(DATE.DD_MM_YYYY)}
                type="Base"
              />
            )
          )
        },
      }),
    ]
  }, [t])

  return {
    columns,
    tableData: {
      pagination: {
        ...pagination,
        total,
      },
      data,
      loading: isLoading,
    },
    metadata,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    isLoadingExport,
  }
}
