import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'

import { requestExportExcelPatientSearch } from 'app/api/treatment'
import { TreatmentMessageCode } from 'app/api/treatment/constant'
import {
  INoteList,
  IParamsGetPatientSearch,
  IPatientSearch,
  ITreatmentList,
} from 'app/api/treatment/model'
import { getDetailPatientUrl } from 'app/common/helpers'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { notificationController } from 'app/controllers/notification-controller'
import { formatPhoneNumber, useDebounce, usePagination } from 'app/hook'
import { useGetPatientSearch } from 'app/react-query/hook/treatment'
import { isEqual } from 'lodash'
import { DateUtil, Pagination, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ServiceText } from './styles'
import { IDataTable } from './type'
import { TreatmentList } from './components'

export const useTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 50,
  })
  const [isLoadingReport, setIsLoadingReport] = useState(false)

  const [filter, setFilter] = useState<IParamsGetPatientSearch>(
    {} as IParamsGetPatientSearch,
  )

  const { flattenDataList } = usePagination()
  const filterDebounce = useDebounce<IParamsGetPatientSearch>(filter, 500)

  const { data: dataInfinity, isLoading } = useGetPatientSearch({
    keyword: filterDebounce?.keyword ?? '',
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    startDate: filterDebounce?.startDate,
    endDate: filterDebounce?.endDate,
    clinicOldIds: filterDebounce?.clinicOldIds,
    doctorIds: filterDebounce?.doctorIds,
    treatmentIds: filterDebounce?.treatmentIds,
  })

  const dataFlatten = useMemo(() => {
    return flattenDataList(dataInfinity)
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlatten])

  const handleExportExcel = async () => {
    try {
      console.log({ clinicOldIds: filterDebounce?.clinicOldIds })

      if (
        !filterDebounce?.clinicOldIds ||
        filterDebounce?.clinicOldIds?.length <= 0
      ) {
        notificationController?.error({
          message: t(R.strings.clinic_label_required),
        })
        return
      }
      setIsLoadingReport(true)
      const response = await requestExportExcelPatientSearch({
        keyword: filterDebounce?.keyword ?? '',
        page: pagination.current,
        pageSize: pagination.pageSize,
        pagesize: pagination.pageSize,
        startDate: filterDebounce?.startDate,
        endDate: filterDebounce?.endDate,
        clinicOldIds: filterDebounce?.clinicOldIds || [],
        doctorIds: filterDebounce?.doctorIds,
        treatmentIds: filterDebounce?.treatmentIds,
      })

      if (
        isEqual(
          response?.msgcode,
          TreatmentMessageCode.PatientSearch.successExport,
        )
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      setIsLoadingReport(false)
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.customer_name),
        key: 'name',
        fixed: 'left',
        className: 'patient-name-column',
        render: (_, record: IPatientSearch) => {
          return (
            <RenderValueTable
              value={record?.name ?? '-'}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record?._id)}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.patient_id),
        key: 'patient_id',
        className: 'patient-code-column',
        render: (_, record: IPatientSearch) => {
          return (
            <BaseText
              children={record?.code}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.birthday),
        key: 'birthday',
        className: 'date-column',
        render: (_, record: IPatientSearch) => {
          return (
            <BaseText
              children={record?.dob ? DateUtil.formatDDMMYYY(record?.dob) : '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.phone),
        key: 'phone',
        className: 'phone-column',
        render: (_, record: IPatientSearch) => {
          return (
            <BaseText
              children={formatPhoneNumber(record?.contact ?? '')}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.service_used),
        key: 'service_used',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record: IPatientSearch) => {
          const treatmentList: ITreatmentList[] = []
          record?.records?.forEach((i, index: number) => {
            treatmentList?.push({ index, data: [...(i?.treatments || [])] })
          })

          return <TreatmentList data={treatmentList} />
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.doctor),
        key: 'doctor',
        className: 'patient-name-column',
        render: (_, record: IPatientSearch) => {
          return (
            <BaseText
              children={record?.doctor?.name}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.clinic),
        key: 'clinic',
        className: 'clinic-column',
        render: (_, record: IPatientSearch) => {
          return (
            <BaseText
              children={record?.clinic?.name}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.note),
        key: 'note',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record: IPatientSearch) => {
          const noteList: INoteList[] = []
          record?.records?.forEach((i, index: number) => {
            if (i?.note) {
              noteList?.push({ index, note: i?.note })
            }
          })

          return (
            <>
              {noteList?.map(item => (
                <ServiceText
                  children={`${(item?.index || 0) + 1}. ${item?.note}`}
                  textAlign="left"
                />
              ))}
            </>
          )
        },
      }),
    ]
    return option
  }, [t, filterDebounce])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IParamsGetPatientSearch) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    handleExportExcel,
    isLoadingReport,
  }
}
