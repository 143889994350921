import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'

import { requestExportExcelReport21 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IParamsGetReport21, IReport021 } from 'app/api/report/model/report-21'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { formatMoneyWithoutSpace, useDebounce, usePagination } from 'app/hook'
import { useGetFilterReport21 } from 'app/react-query/hook/report'
import { isEqual } from 'lodash'
import { DateUtil, Pagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'

const MIN_AMOUNT = 700000 // rules from accounting

export const useTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const [filter, setFilter] = useState<IParamsGetReport21>({
    isFullData: false,
  })

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IParamsGetReport21>(filter, 500)

  const referer = filterDebounce?.referer
    ? { referer: filterDebounce?.referer }
    : {}
  const { data: dataInfinity, isLoading } = useGetFilterReport21({
    keyword: filterDebounce?.keyword ?? '',
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    appliedAtStart: filterDebounce?.appliedAtStart,
    appliedAtEnd: filterDebounce?.appliedAtEnd,
    startUsingAtStart: filterDebounce?.startUsingAtStart,
    startUsingAtEnd: filterDebounce?.startUsingAtEnd,
    treatmentIds: filterDebounce?.treatmentIds,
    isFullData: filter?.isFullData,
    ...referer,
  })

  const dataFlatten = useMemo(() => {
    const res = flattenDataList(dataInfinity)
    return res
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      } as IDataTable
    })
  }, [dataFlatten])

  const totalItem = useMemo(() => {
    return dataFlatten?.total ?? 0
  }, [dataFlatten])

  const handleExportExcel = async () => {
    setIsLoadingExport(true)
    try {
      const response = await requestExportExcelReport21({
        appliedAtStart: filterDebounce?.appliedAtStart,
        appliedAtEnd: filterDebounce?.appliedAtEnd,
        startUsingAtStart: filterDebounce?.startUsingAtStart,
        startUsingAtEnd: filterDebounce?.startUsingAtEnd,
        keyword: filterDebounce?.keyword ?? '',
        treatmentIds: filterDebounce?.treatmentIds,
        isFullData: filter?.isFullData,
        ...referer,
      })

      if (
        isEqual(response?.msgcode, ReportMessageCode.Report21.successExportData)
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      setIsLoadingExport(false)
    }
  }

  const columns = useMemo(() => {
    const option = [
      {
        title: t(R.strings.referral_to_info),
        children: [
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.full_name)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'name',
            fixed: 'left',
            className: 'name-patient-column',
            render: (_, record: IReport021) => {
              return (
                <RenderValueTable
                  value={record?.referralTo?.name ?? '-'}
                  type="OtherLink"
                  hrefOtherLink={getDetailPatientUrl(
                    record?.referralTo?.patientId,
                  )}
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.patient_code)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'patient_code',
            fixed: 'left',
            classNameWidthColumnOverwrite: 'medium-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.referralTo?.code}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="center"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.referral_source)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'referral_to_source',
            fixed: 'left',
            classNameWidthColumnOverwrite: 'medium-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.referralTo?.referer}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="left"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.clinic)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'clinic_to',
            fixed: 'left',
            className: 'clinic-patient-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.referralTo?.defaultLocationName}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="left"
                />
              )
            },
          }),
        ],
      },
      {
        title: t(R.strings.referral_from_info),
        children: [
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.full_name)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'referral_from_name',
            className: 'name-patient-column',
            render: (_, record: IReport021) => {
              return record?.referralFrom?.name ? (
                <RenderValueTable
                  value={record?.referralFrom?.name}
                  type="OtherLink"
                  hrefOtherLink={getDetailPatientUrl(
                    record?.referralFrom?.patientId,
                  )}
                />
              ) : null
            },
          }),
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.patient_code)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'referral_from_code',
            classNameWidthColumnOverwrite: 'medium-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.referralFrom?.code}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="left"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.referral_source)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'referral_source',
            classNameWidthColumnOverwrite: 'medium-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.referralFrom?.referer}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="left"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: (
              <BaseText
                children={t(R.strings.clinic)}
                textAlign="left"
                fontWeight="semibold"
                opacity="0.7"
              />
            ),
            key: 'clinic_from',
            className: 'clinic-patient-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.referralFrom?.defaultLocationName}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="left"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.remain_debt),
            key: 'remain_debt',
            className: 'money-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={
                    record?.referralFromDebt?.remain
                      ? formatMoneyWithoutSpace(
                          record?.referralFromDebt?.remain,
                          'đ',
                        )
                      : ''
                  }
                  fontWeight="medium"
                  opacity={
                    record?.referralFromDebt?.remain &&
                    record?.referralFromDebt?.remain < MIN_AMOUNT
                      ? '1'
                      : '0.5'
                  }
                  colorText={
                    record?.referralFromDebt?.remain &&
                    record?.referralFromDebt?.remain < MIN_AMOUNT
                      ? 'statesRedColor'
                      : 'primaryColor'
                  }
                  textAlign="right"
                />
              )
            },
          }),
        ],
      },
      {
        title: t(R.strings.customer_A_service_used_info),
        children: [
          convertColumnTable<IDataTable>({
            title: t(R.strings.voucher_applied_date),
            key: 'voucher_applied_date',
            className: 'date-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={
                    record?.appliedAt
                      ? DateUtil.formatDate(record?.appliedAt, 'DD/MM/YYYY')
                      : '-'
                  }
                  fontWeight="medium"
                  textAlign="center"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.voucher_code),
            key: 'voucher_code',
            className: 'voucher-code-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.code}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="left"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.voucher_applied_package_name),
            key: 'voucher_applied_package_name',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record: IReport021) => {
              return (
                <BaseSpace size={4}>
                  {record?.treatments?.map(item => (
                    <BaseText
                      children={item?.name}
                      fontWeight="medium"
                      opacity="0.5"
                      textAlign="left"
                    />
                  ))}
                </BaseSpace>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.total_value_contract),
            key: 'total_value_contract',
            className: 'money-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={
                    record?.netAmount === undefined
                      ? ''
                      : formatMoneyWithoutSpace(record?.netAmount, 'đ')
                  }
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="right"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.start_date_using_service),
            key: 'start_date_using_service',
            classNameWidthColumnOverwrite: 'medium-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={
                    record?.startUsingTreatments?.date
                      ? DateUtil.formatDate(
                          record?.startUsingTreatments?.date,
                          'DD/MM/YYYY',
                        )
                      : ''
                  }
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="center"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.treatment_name_start_service),
            key: 'treatment_name_start_service',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IReport021) => {
              return (
                <BaseText
                  children={record?.startUsingTreatments?.name}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="right"
                />
              )
            },
          }),
        ],
      },
    ]
    return option
  }, [t, filterDebounce])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IParamsGetReport21) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: totalItem,
    },
    totalItem: totalItem,
    handleChangeFilter,
    handleExportExcel,
    setIsLoadingExport,
    isLoadingExport,
  }
}
