import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { isEmpty, isEqual } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { NotificationsDropdown } from '../components/NotificationsDropdown'
import { ProfileDropdown } from '../components/profile/ProfileDropdown'
import * as S from '../styles'
import { FONT_SIZE } from 'parkway-web-common'

const SPACE_PATH = ' › '

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  isTwoColumnsLayout,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const path = useMemo(() => {
    if (isEqual(pathname, DASHBOARD_PATH)) {
      return {
        lastPath: t(R.strings.dashboard),
        middlePathName: '',
        middlePath: '',
      }
    }

    const convertedPath = pathname.replace(/-/g, '_')
    const pathComponents = convertedPath
      .split('/')
      .filter(component => component !== '')

    if (pathComponents.length > 1) {
      const splitIndex = pathComponents.length - 1
      const middleArray = pathComponents.slice(0, splitIndex)
      const lastArray = pathComponents.slice(splitIndex)
      return {
        lastPath: lastArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middlePathName: middleArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middleArray,
        middlePath: middleArray.map(component => component).join(SPACE_PATH),
      }
    }

    const translatedPath = pathComponents
      .map(component => `${t(`${component}`)}`)
      .join(SPACE_PATH)

    return {
      lastPath: translatedPath,
      middlePathName: '',
      middlePath: '',
    }
  }, [pathname, t])

  const renderPath = (() => {
    let prefix = ''
    return (
      <S.CurrentPath>
        <S.HomeText onClick={() => navigate(DASHBOARD_PATH)}>
          {`${t(R.strings.home)}`}
          <span style={{ fontSize: FONT_SIZE.md }}>{SPACE_PATH}</span>
        </S.HomeText>
        {!isEmpty(path?.middleArray) &&
          path?.middleArray?.map((pathChild, index) => {
            prefix += `/${pathChild.replace(/_/g, '-')}`
            const navigationPath = prefix

            return (
              <S.HomeText key={index} onClick={() => navigate(navigationPath)}>
                {`${t(pathChild)}`}
                <span style={{ fontSize: FONT_SIZE.md }}>{SPACE_PATH}</span>
              </S.HomeText>
            )
          })}
        {path.lastPath}
      </S.CurrentPath>
    )
  })()

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          {renderPath}
        </BaseCol>
        <BaseCol></BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol xl={12} xxl={12}>
        {renderPath}
      </BaseCol>
      <BaseCol></BaseCol>
    </>
  )

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow
          align="middle"
          justify="end"
          gutter={[12, 8]}
          wrap={false}
          style={{ maxWidth: '100%' }}
        >
          <BaseCol>
            <NotificationsDropdown />
          </BaseCol>
          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  )
}
