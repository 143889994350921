import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import styled from 'styled-components'

export const TableWrapper = styled(BaseTableManagement)`
  .month-column {
    min-width: 70px !important;
  }
    
  .total-orthodontic {
    min-width: 85px !important;
  }

  .date-column {
    min-width: 75px !important;
  }
`
