import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { useGetHeightTable } from 'app/hook'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, RootWrapper } from './styles'
import { IProps } from './type'

export const Report23Container = (props: IProps) => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    handleExportExcel,
    isHavePermissionExport,
    hideClinicFilter,
  } = useTable(props)

  return (
    <RootWrapper>
      <SectionBase style={{ padding: 8, paddingBottom: 6 }}>
        <BaseSpace size={8}>
          <FilterLayout
            formData={filter}
            onChange={handleChangeFilter}
            hideClinicFilter={hideClinicFilter}
            handleExportExcel={handleExportExcel}
            isHavePermissionExport={isHavePermissionExport}
          />

          <BaseTableReport
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={isLoading}
            onChange={handleTableChange}
            rowClassName="row-overwrite-style"
            scroll={{
              x: true,
              y: data?.length ? useGetHeightTable(240, 15) : undefined,
            }}
          />
        </BaseSpace>
      </SectionBase>
    </RootWrapper>
  )
}
