import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'
import { useHook } from './hook'
import { FilterLayout } from './layouts/Filter'
import { useGetHeightTable } from 'app/hook'

const Report015Container = () => {
  const {
    columns,
    data,
    filter,
    onChangeFilter,
    onChangeTable,
    pagination,
    isLoading,
  } = useHook()
  return (
    <RootWrapper>
      <ContentWrapper size={4}>
        <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
        <BaseTableReportWrapper
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          rowClassName="row-overwrite-style"
          pagination={pagination}
          loading={isLoading}
          scroll={{
            x: true,
            y: data?.length ? useGetHeightTable(235, 15) : undefined,
          }}
        />
      </ContentWrapper>
    </RootWrapper>
  )
}

export default Report015Container

const RootWrapper = styled(BaseSpace)`
  padding: 8px;
  padding-bottom: 4px;
`
const ContentWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const BaseTableReportWrapper = styled(BaseTableReport)`
  .ant-table-thead .ant-table-cell {
    padding: 8px !important;
  }

  .all-column {
    padding: 8px !important;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0px !important;
  }

  table > thead {
    opacity: 0.7;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
    padding: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .index-column {
    min-width: 14px !important;
  }

  .clinic-column {
    min-width: 150px !important;
  }

  .money-column {
    min-width: 100px !important;
    text-align: right !important;
  }
`
