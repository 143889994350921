import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, RootWrapper } from './styles'
import { useGetHeightTable } from 'app/hook'

export const Report020Container = () => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    handleExportExcel,
  } = useTable()
  const { isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  return (
    <RootWrapper>
      <SectionBase style={{padding: 8, paddingBottom: 4 }}>
        <BaseSpace size={4}>
          <FilterLayout
            isHavePermissionExport={isHavePermissionFunction(
              ModulePermissionEnum.REPORT,
              FunctionPermissionEnum.REPORT_EXPORT_REPORT_20,
            )}
            handleExportExcel={handleExportExcel}
            formData={filter}
            onChange={handleChangeFilter}
          />

          <BaseTableReport
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={isLoading}
            onChange={handleTableChange}
            rowClassName="row-overwrite-style"
            scroll={{
              x: true,
              y: data?.length ? useGetHeightTable(192, 15) : undefined,
            }}
          />
        </BaseSpace>
      </SectionBase>
    </RootWrapper>
  )
}
