/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReportMessageCode } from 'app/api/report/constant'

import { requestGetReport14 } from 'app/api/report'
import {
  IFilterReport14,
  ResponseReport14Type,
} from 'app/api/report/model/report-14'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import {
  randomMoney,
  randomPercent,
} from 'app/components/tables/common-table/constant'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import {
  convertAgeGroupToArrayAge,
  convertDataApiToDataTableReport14Case,
  convertDataApiToDataTableReport14Revenue,
  convertGenderToParams,
  useCommonHook,
} from '../common-hook'
import { IDataReport014 } from './data'
import { initPagination } from 'app/constant'

export const useTableCaseHook = (props: IFilterReport14) => {
  const getDataWeek = async (
    pagination: Pagination,
    params: IFilterReport14,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IDataReport014>
    try {
      const ageData = convertAgeGroupToArrayAge(params?.age ?? props?.age)
      const response: ResponseReport14Type = await requestGetReport14({
        year: Number(params?.year ?? props?.year),
        patientGender: convertGenderToParams(params?.gender ?? props?.gender),
        location: params?.clinic ?? props?.clinic,
        patientAgeFrom: ageData?.patientAgeFrom,
        patientAgeTo: ageData?.patientAgeTo,
      })

      if (
        isEqual(response?.msgcode, ReportMessageCode.Report14.successGetData)
      ) {
        const data = response?.data ?? []

        let dataTableReportCase: IDataReport014[] = []

        const detailData = data.detail

        const groupData = data.group

        const totalTable = data?.total

        const detailListKeys = Object.keys(detailData)

        detailListKeys.forEach((serviceGroup, indexGroupService) => {
          const detailValue = detailData[serviceGroup]
          const groupValue = groupData[serviceGroup]

          const listServiceKeys = Object.keys(detailValue)
          const [firstServiceKey, ...otherServiceKeys] = listServiceKeys ?? []
          const dataObjectTableTotalCase =
            convertDataApiToDataTableReport14Case({
              serviceData: groupValue,
              type: 'total',
            })

          const dataObjectTableTotalRevenue =
            convertDataApiToDataTableReport14Revenue({
              serviceData: groupValue,
              type: 'total',
            })

          const firstItemData = detailValue[firstServiceKey]
          const dataObjectTableFirstItemCase =
            convertDataApiToDataTableReport14Case({
              serviceData: firstItemData,
              type: 'child',
            })
          const dataObjectTableFirstItemRevenue =
            convertDataApiToDataTableReport14Revenue({
              serviceData: firstItemData,
              type: 'child',
            })

          const key1 = (indexGroupService + 1) * 10
          const newResponse: IDataReport014 = {
            key: key1,
            groupService: serviceGroup,
            service: firstServiceKey,
            ...dataObjectTableTotalCase,
            ...dataObjectTableTotalRevenue,

            ...dataObjectTableFirstItemCase,
            ...dataObjectTableFirstItemRevenue,

            children: otherServiceKeys?.map((service, idx) => {
              const serviceDetail = detailValue[service]

              const dataObjectTableCase = convertDataApiToDataTableReport14Case(
                {
                  serviceData: serviceDetail,
                  type: 'child',
                },
              )

              const dataObjectTableRevenue =
                convertDataApiToDataTableReport14Revenue({
                  serviceData: serviceDetail,
                  type: 'child',
                })

              return {
                key:
                  (indexGroupService + 1) * 1000 +
                  randomPercent() * randomPercent(),
                index: idx + 1,
                service: service,
                ...dataObjectTableCase,
                ...dataObjectTableRevenue,
              }
            }),
          }

          dataTableReportCase = dataTableReportCase.concat(newResponse)
        })
        const dataOfTotalTableCase = convertDataApiToDataTableReport14Case({
          serviceData: totalTable,
          type: 'child',
        })

        const dataOfTotalTableRevenue =
          convertDataApiToDataTableReport14Revenue({
            serviceData: totalTable,
            type: 'child',
          })

        return {
          pagination: pagination ?? initPagination,
          data: [
            ...dataTableReportCase,
            {
              isTotalTable: true,
              key: randomMoney() * randomPercent(),
              groupService: 'Tổng',
              ...dataOfTotalTableCase,
              ...dataOfTotalTableRevenue,
            },
          ],
        } as IDataBaseTable<IDataReport014>
      }
      return initData
    } catch (error) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataWeek })

  const { columns, exportExcel, isLoadingExport } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    typeHook: props?.type,
    currentFilter: props,
  })

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    exportExcel,
    handleResetExpand,
    fetch,
    fetchLoading,
    isLoadingExport,
  }
}
