/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  INotificationHistoryOfUser,
  NotificationHistoryEnum,
} from 'app/api/company/notification/type'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSpin } from 'app/components/common/BaseSpin'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { REPORT_023_FULL_PATH } from 'app/components/router/route-path'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { useDebounce, usePagination } from 'app/hook'
import {
  useGetNotificationHistory,
  useHandleReadNotification,
} from 'app/react-query/hook/notification'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
function timeFromNow(date: any) {
  const now = dayjs()
  const targetDate = dayjs(date)
  const diffInSeconds = now.diff(targetDate, 'second')
  const diffInMinutes = now.diff(targetDate, 'minute')
  const diffInHours = now.diff(targetDate, 'hour')
  const diffInDays = now.diff(targetDate, 'day')

  if (diffInSeconds < 60) {
    return `${diffInSeconds} giây trước`
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} phút trước`
  } else if (diffInHours < 24) {
    const minutes = diffInMinutes % 60
    const currentDay = now.get('date')
    const targetDay = targetDate.get('date')
    if (!isEqual(targetDay, currentDay)) {
      return `${currentDay - targetDay} ngày trước`
    }
    return `${diffInHours} giờ ${minutes} phút trước`
  } else {
    return `${diffInDays} ngày trước`
  }
}

const ItemNotification = ({
  item,
  hideModal,
  isNotShowChecked,
}: {
  item: INotificationHistoryOfUser
  hideModal?: () => void
  isNotShowChecked?: boolean
}) => {
  const [checked, setChecked] = useState(item?.isRead)
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { mutateAsync, isLoading: isLoadingReadNotification } =
    useHandleReadNotification()

  const handleReadNotification = async (
    notificationId?: string,
    isRead?: 1 | 0,
  ) => {
    await mutateAsync?.({ notificationId, isRead })
  }

  const onClickNotification = () => {
    switch (item?.notification?.type) {
      case NotificationHistoryEnum.ZNS:
        navigate(REPORT_023_FULL_PATH)
        break
      case NotificationHistoryEnum.BOOKING:
      case NotificationHistoryEnum.OTHER:
    }

    hideModal?.()

    if (!item?.isRead) {
      handleReadNotification?.(item?.notification?._id)
    }
  }

  const onPressCheckbox = () => {
    handleReadNotification?.(item?.notification?._id, item?.isRead ? 0 : 1)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setChecked(item?.isRead)
    }, 300)

    return () => clearTimeout(timer)
  }, [item?.isRead])

  return (
    <ItemWrapper $isRead={checked} size={4} onClick={onClickNotification}>
      <BaseRow
        justify={'space-between'}
        align={'middle'}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <BaseCol>
          <BaseSpace size={4}>
            {item?.notification?.notificationHtml ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: `<span style="font-size: 12px; font-weight: normal"> KH <span style="font-weight: bold">${
                    item?.notification?.sendBy?.name
                  } </span> ${item?.notification?.notificationHtml?.toLowerCase()}</span>`,
                }}
              />
            ) : (
              <BaseText children={item?.notification?.notification} />
            )}

            <BaseText
              children={timeFromNow(item?.notification?.createdAt)}
              style={{ color: '#858C94' }}
              fontWeight="medium"
              fontSize="xxxs"
            />
          </BaseSpace>
        </BaseCol>

        <BaseCol>
          <BaseRow
            gutter={16}
            align={'middle'}
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <BaseCol>
              <div onClick={onClickNotification}>
                <BaseText
                  children={t(R.strings.see_detail)}
                  colorText="collapseBackgroundColor"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </BaseCol>

            <BaseCol style={{ paddingRight: 0 }}>
              {isLoadingReadNotification ? (
                <BaseSpin size="small" />
              ) : (
                <BaseCheckbox
                  onClick={e => {
                    e?.stopPropagation()
                    onPressCheckbox()
                    if (!isNotShowChecked) {
                      setChecked(!checked)
                    }
                  }}
                  checked={checked}
                />
              )}
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </ItemWrapper>
  )
}

const ItemWrapper = styled(BaseSpace)<{ $isRead?: boolean }>`
  padding: 12px 20px;
  cursor: pointer;
  position: relative;
  background: ${props =>
    props?.$isRead ? convertedVariables.backgroundColor : '#EFF6FF'};

  transition: all 1s ease;
`

export const ListNotificationHistory = ({
  isRead,
  hideModal,
}: {
  isRead?: boolean
  hideModal?: () => void
}) => {
  const { flattenData } = usePagination()

  const [loadMoreTreatment, setLoadMoreTreatment] = useState(0)
  const loadMoreTreatmentDebounce = useDebounce(loadMoreTreatment, 200)

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetNotificationHistory({
      isRead,
    })

  const dataFlatten: INotificationHistoryOfUser[] = useMemo(() => {
    return flattenData(data)
  }, [data])

  const handleScroll = (e: any) => {
    const heightChange =
      (e?.target?.scrollTop ?? 0) + (e?.target?.clientHeight ?? 0)
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPage) {
      setLoadMoreTreatment(randomMoney())
    }
  }

  useEffect(() => {
    if (loadMoreTreatmentDebounce) {
      fetchNextPage()
    }
  }, [loadMoreTreatmentDebounce])

  return (
    <>
      <RootWrapper onScroll={handleScroll}>
        {dataFlatten?.length ? (
          <BaseSpace
            split={
              <div
                style={{
                  backgroundColor: '#EFF6FF',
                  width: '100%',
                  height: 0.8,
                }}
              />
            }
            size={0}
          >
            {dataFlatten?.map((item, index) => {
              return (
                <ItemNotification
                  item={item}
                  key={`${item?._id} ${index}`}
                  hideModal={hideModal}
                  isNotShowChecked={isRead === false}
                />
              )
            })}

            {isFetchingNextPage ? (
              <BaseRow align={'middle'} justify={'center'}>
                <BaseCol>
                  <BaseSpin size={'small'} />
                </BaseCol>
              </BaseRow>
            ) : null}
          </BaseSpace>
        ) : (
          <BaseEmpty />
        )}
      </RootWrapper>
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </>
  )
}

const RootWrapper = styled.div`
  position: relative;

  transition: all 1.5s ease;
  height: 500px;
  overflow: auto;

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 0.8px solid transparent;
  }

  .ant-checkbox-inner {
    border-width: 0.8px;
    border-radius: 4px;
  }
`
