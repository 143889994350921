import { Rule } from 'antd/es/form'

export interface IFormData {
  firstName?: string
  lastName?: string
  employeeId?: string
  phone?: string
  email?: string
  status?: string
  code?: string
  password?: string
}

export interface IFormRule {
  firstName?: Rule[]
  lastName?: Rule[]
  employeeId?: Rule[]
  phone?: Rule[]
  email?: Rule[]
  status?: Rule[]
  code?: Rule[]
}

export const initValues: IFormData = {
  code: '',
  firstName: '',
  lastName: '',
  employeeId: '',
  phone: '',
  email: '',
}

export enum StatusEnum {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export type StatusAccountType = keyof typeof StatusEnum
