import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { isEqual } from 'lodash'
import moment from 'moment'
import { SelectFilterEnum, SelectQuarterEnum } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableMonthlyHook } from './Monthly/hook'
import { useTableQuarterHook } from './Quarter/hook'
import { initialPagination } from './common-model'

export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
    quarter: SelectQuarterEnum.QUARTER_1,
  })

  const hookQuarter = useTableQuarterHook({
    year: filterResponse.year,
    quarter: SelectQuarterEnum.QUARTER_1,
  })
  const hookMonthly = useTableMonthlyHook({
    year: filterResponse.year,
    month: filterResponse.month,
  })

  const expandedRowKeys = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.expandedRowKeys
      case SelectFilterEnum.MONTH:
        return hookMonthly.expandedRowKeys
      default:
        return hookQuarter.expandedRowKeys
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const tableData = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.tableData
      case SelectFilterEnum.MONTH:
        return hookMonthly.tableData
      default:
        return hookMonthly.tableData
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const handleTableChange = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.handleTableChange
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleTableChange
      default:
        return hookMonthly.handleTableChange
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const handleExpand = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.handleExpand
      case SelectFilterEnum.MONTH:
        return hookMonthly.handleExpand
      default:
        return hookMonthly.handleExpand
    }
  }, [filterResponse?.selectFilterType, hookQuarter, hookMonthly])

  const handleChangeFilter = (filter: IResponseFilter) => {
    const { year, month, quarter } = filter
    setFilterResponse(filter)
    if (
      !isEqual(filterResponse?.year, year) ||
      !isEqual(filterResponse?.month, month)
    ) {
      hookMonthly.fetch(initialPagination, { year, month })
    }

    if (
      !isEqual(filterResponse?.year, year) ||
      !isEqual(filterResponse?.quarter, quarter)
    ) {
      hookQuarter.fetch(initialPagination, { year, quarter })
    }
  }

  const columns = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.columns
      case SelectFilterEnum.MONTH:
        return hookMonthly.columns
      default:
        return hookMonthly.columns
    }
  }, [t, expandedRowKeys, filterResponse?.selectFilterType])

  const exportExcel = useMemo(() => {
    switch (filterResponse?.selectFilterType) {
      case SelectFilterEnum.QUARTER:
        return hookQuarter.exportExcel
      case SelectFilterEnum.MONTH:
        return hookMonthly.exportExcel
      default:
        return hookMonthly.exportExcel
    }
  }, [filterResponse])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    handleChangeFilter,
    filterResponse,
    exportExcel,
    isLoadingExport: false,
  }
}
