import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ImportButtonArea } from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'

const ImportMarketingPlanContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    urlDownload,
    onClickImport,
  } = useTableHook()

  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={4}>
        <BaseRow align={'middle'} justify={'space-between'} gutter={16}>
          <BaseCol xl={18}>
            <FilterReport
              isHaveChooseWeek={false}
              isSelectFilter
              handleChangeFilter={handleChangeFilter}
            />
          </BaseCol>
          <BaseCol>
            <ImportButtonArea
              urlExample={urlDownload.url}
              onPressImport={onClickImport}
            />
          </BaseCol>
        </BaseRow>

        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record =>
              record?.children && record?.children?.length > 0,
          }}
          rowClassName="row-overwrite-style"
          pagination={false}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default ImportMarketingPlanContainer
