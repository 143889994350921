import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { CreateJobTitle } from './actions/CreateJobTitle'
import { useHookTable } from './hook'
import { BaseTableManagementWrapper } from './styles'
import { useGetHeightTable } from 'app/hook'
import { FilterLayout } from 'app/components/tables/BaseTableManagement/FilterManagementTableV2'

const JobTitleManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    fetchNewData,
    filter,
    handleChangeFilter,
  } = useHookTable()
  return (
    <S.BaseManagementWrapper>
      <S.RootWrapper size={8}>
        <BaseRow justify={'space-between'} align={'middle'} gutter={[16, 8]}>
          <BaseCol>
            <FilterLayout
              filterData={filter}
              handleChangeFilter={handleChangeFilter}
            />
          </BaseCol>
          <BaseCol>
            <BaseRow justify={'end'} align={'middle'} gutter={8}>
              <CreateJobTitle fetchTable={fetchNewData} />
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseTableManagementWrapper
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(195, 30) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default JobTitleManagementContainer
