import { ExportOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React, { useMemo } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useTableHook } from './hook'
import { useGetHeightTable } from 'app/hook'

const Report006Container: React.FC = () => {
  const {
    columns,
    tableData,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
  } = useTableHook()
  const { t } = useTranslation()

  const { isHavePermissionFunction, userData } = useVerifyPermission()

  const isCanExport = useMemo(() => {
    return isHavePermissionFunction(
      ModulePermissionEnum.REPORT,
      FunctionPermissionEnum.REPORT_EXPORT_REPORT_7,
    )
  }, [userData])
  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={8}>
        <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
          <BaseCol xl={isCanExport ? 21 : 24}>
            <FilterReport
              isSelectFilter
              handleChangeFilter={handleChangeFilter}
              selectFilterGroup="WEEK_MONTH"
              isUsingWeekMonthCorrect
              rootStyle={{ paddingBottom: 0 }}
            />
          </BaseCol>
          {isCanExport ? (
            <BaseCol xl={3}>
              <BaseRow justify={'end'} align={'middle'}>
                <BaseCol>
                  <BaseButton
                    children={
                      <BaseRow gutter={8}>
                        <BaseCol>
                          <ExportOutlined rev={undefined} />
                        </BaseCol>
                        <BaseCol>{t(R.strings.export_excel)}</BaseCol>
                      </BaseRow>
                    }
                    onClick={exportExcel}
                    // loading={isLoadingExport}
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          ) : null}
        </BaseRow>
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record =>
              record?.children && record?.children?.length > 0,
          }}
          rowClassName="row-overwrite-style"
          pagination={false}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(250, 0) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default Report006Container
