/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { getArray } from 'app/components/tables/common-table/constant'
import { convertToTitleCase } from 'app/hook'
import {
  LocationEnum,
  PeriodEnum,
  PeriodType,
  ResponseType,
  SelectFilterType,
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport08Monthly } from './Monthly/data'
import { IDataReport08Weekly } from './Weekly/data'
import { IParamsGetReport08 } from 'app/api/report/model/report-08'
import { IResponseExportExcel } from 'app/api/report/model/common'
import { requestExportExcelTopTenProduct } from 'app/api/report'
import { IParamsHookReport } from 'app/components/tables/BaseTableReport/type'
import { ReportMessageCode } from 'app/api/report/constant'
import { notificationController } from 'app/controllers/notification-controller'

export const useCommonHook = ({
  typeHook,
  currentFilter,
  fetchLoading,
}: {
  typeHook: PeriodType
  currentFilter?: IParamsHookReport
  fetchLoading?: (isLoading: boolean) => void
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) {
        return
      }

      let params: IParamsGetReport08 = {}

      switch (typeHook) {
        case 'week':
          params = {
            ...params,
            year: currentFilter?.year,
            month: currentFilter?.month,
            location: getLocationByTypeFiler(currentFilter?.location),
            type: PeriodEnum.week,
          }
          break
        case 'month':
          params = {
            ...params,
            year: currentFilter?.year,
            location: getLocationByTypeFiler(currentFilter?.location),
            type: PeriodEnum.month,
          }
          break
      }

      fetchLoading?.(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelTopTenProduct(params)

      if (
        res?.msgcode === ReportMessageCode.Report08.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading?.(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport08Weekly | IDataReport08Monthly

    let options = [
      convertColumnTable<TypeColumn>({
        title: '#',
        key: 'firstNumber',
        fixed: 'left',
        className: 'index-column',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            valueString: record?.firstNumber,
          })
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.product),
        key: 'product',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            valueString: convertToTitleCase(record?.product ?? ''),
            justify: 'start',
          })
        },
      }),
    ]

    if (typeHook === 'week') {
      options = options.concat(
        getArray(6).map(week => {
          return convertColumnTable<any>({
            title: t(R.strings.week_no, { week }),
            key: `revenueWeek${week}`,
            className: 'value-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.[`revenueWeek${week}`] ?? 0,
                percent: record?.[`percentDevelopWeek${week}`] ?? 0,
                isShowPercent: true,
                justify: 'space-between',
                isShowDevelopment: true
              })
            },
          })
        }),
      )
    }

    if (typeHook === 'month') {
      options = options.concat(
        getArray(12).map(month => {
          return convertColumnTable<any>({
            title: t(R.strings.month_no, { month }),
            key: `month${month}`,
            className: 'value-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.[`month${month}`],
                percent: record?.[`percentDevelopMonth${month}`] ?? 0,
                isShowPercent: true,
              })
            },
          })
        }),
      )
    }

    options = options.concat([
      convertColumnTable<TypeColumn>({
        title: t(R.strings.total_revenue),
        key: `totalRevenue`,
        className: 'money-column',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.[`totalRevenue`],
          })
        },
      }),
    ])

    return options
  }, [t, typeHook])

  return {
    columns,
    exportExcel,
  }
}

export const getLocationByTypeFiler = (type?: SelectFilterType) => {
  switch (type) {
    case 'ALL_SYSTEM':
      return
    case 'HANOI':
      return LocationEnum.HANOI
    case 'HOCHIMINH':
      return LocationEnum.HOCHIMINH
    case 'PROVINCE':
      return LocationEnum.PROVINCE
    default:
      return
  }
}
