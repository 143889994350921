import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 8px;
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .all-column {
    padding: 8px !important;
  }

  .ant-table-thead > tr > th {
    text-align: left !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }

  .time-column {
    min-width: 122px !important;
  }

  .cline-column {
    min-width: 135px !important;
  }

  .patient-code-column {
    min-width: 110px !important;
  }

  .patient-name-column {
    min-width: 120px !important;
  }

  .phone-column {
    min-width: 98px !important;
  }

  .discount-amount {
    min-width: 75px !important;
    text-align: right;
  }

  .discount-value {
    min-width: 60px !important;
    text-align: right;
  }

  .discount-type {
    min-width: 70px !important;
  }

  .retail-price {
    min-width: 100px !important;
    text-align: right;
  }

  .discounted-total {
    min-width: 100px !important;
    text-align: right;
  }

  .voucher-code {
    min-width: 80px !important;
  }

  .priority-column {
    min-width: 55px !important;
  }
    
  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`
