import { DeleteOutlined, RedoOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { Button } from 'app/components/common/BaseButton/styles'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const BaseTableManagementWrapper = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    border: 0.5px solid var(--neutral-black-2-color) !important;
    text-align: left !important;
    font-size: 12px !important;
    font-weight: ${FONT_WEIGHT.bold};
  }
  & .ant-pagination-item {
    border-radius: ${BORDER_RADIUS};
    min-width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
  }
`

export const BaseWrapper = styled.div`
  background-color: var(--states-orange-color);
  color: var(--background-color);
`

export const BaseActionWrapper = styled.div`
  padding: 8px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`

export const ButtonActionGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

export const ListWorkInfo = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`

export const HeaderWorkInfo = styled.div`
  gap: 10px;
  display: flex;
  justify-content: space-between;
`

export const WrapperWorkInfo = styled.div`
  background-color: #f9fafb;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

export const WrapperButton = styled(Button)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const ButtonSubmit = styled(BaseButton)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`
export const ButtonCancel = styled(BaseButton)`
  min-width: 120px;
`

export const BaseFormWrapper = styled(BaseForm)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const DeleteButton = styled(DeleteOutlined)`
  color: var(--states-red-color);
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RefreshButton = styled(RedoOutlined)`
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const TitleFormList = styled.h3`
  margin-bottom: 1rem;
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``

export const DatePicker = styled(BaseDatePicker)`
  min-width: 160px;
`
