import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ExportExcelButton } from 'app/components/header/components/HeaderPage'
import { FilterBracesTray } from 'app/components/tables/BaseTableManagement/FilterBracesTrayTable'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { useGetHeightTable } from 'app/hook'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import { useTableHook } from './hook'
import './styles.css'

const BracesTrayContainer: React.FC = () => {
  const {
    columns,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    data,
    isLoading,
    pagination,
    isLoadingExport,
  } = useTableHook()

  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={4}>
        <BaseRow align={'middle'} justify={'space-between'}>
          <FilterBracesTray
            handleChangeFilter={handleChangeFilter}
            filterData={filterResponse}
          />

          <BaseCol>
            <ExportExcelButton
              onPressExport={onClickExport}
              isLoadingExport={isLoadingExport}
            />
          </BaseCol>
        </BaseRow>
        <BaseTableReportWrapper
          columns={columns}
          dataSource={data}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={pagination}
          scroll={{
            x: true,
            y: data?.length ? useGetHeightTable(270, 0) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default BracesTrayContainer

const BaseTableReportWrapper = styled(BaseTableReport)`
  .count-service-column {
    min-width: 60px !important;
  }
  .clinic-column {
    min-width: 90px !important;
  }
`
