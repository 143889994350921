/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportExcelPNL } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseExportExcel } from 'app/api/report/model/common'
import {
  IGeneralValuePNL,
  IParamsGetPNL,
} from 'app/api/report/model/report-PNL'
import R from 'app/assets/R'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  AreaText,
  ExpandedNotHaveChildRowWrapper,
} from 'app/components/tables/BaseTableReport/styles'
import {
  IBaseColumnReport,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { PeriodType, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataPNLMonthly } from './Monthly/data'
import { IDataPNLQuarter } from './Quarter/data'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  currentFilter,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  typeHook: PeriodType | 'quarter'
  currentFilter?: IParamsHookReport
  fetchLoading: (isLoading: boolean) => void
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) return

      let params: IParamsGetPNL = {
        year: currentFilter?.year,
      }

      switch (typeHook) {
        case 'month':
          if (!currentFilter?.month) return
          params = {
            ...params,
            periodtype: 'month',
            month: currentFilter?.month,
          }
          break
        case 'quarter':
          params = {
            ...params,
            periodtype: 'quarter',
            quarter: currentFilter?.quarter,
          }
          break
      }

      fetchLoading(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelPNL(params)

      if (
        res?.msgcode === ReportMessageCode.PNL.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataPNLQuarter | IDataPNLMonthly
    const options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.ordinal_number),
        key: 'firstNumber',
        fixed: 'left',
        className: 'index-column',
        render: (_, record) => {
          let className = ''

          if (record?.index && record?.index % 2 === 0) {
            className = 'is-expanded'
          }

          if (record?.isTotalGroupTable) {
            className = 'is-total-group-table'
          }

          return (
            <ExpandedTableValue
              value={`${record?.firstNumber ? record?.firstNumber : ''}`}
              isNotHaveActionExpanded
              className={className}
            />
          )
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.clinic),
        key: 'clinic',
        fixed: 'left',
        className: 'clinic-column',
        render: (text, record) => {
          let className = ''

          if (record?.index && record?.index % 2 === 0) {
            className = 'is-expanded'
          }

          if (record?.isTotalGroupTable) {
            className = 'is-total-group-table'
          }

          if (!record?.children?.length) {
            return (
              <ExpandedNotHaveChildRowWrapper className={className}>
                <AreaText>{text}</AreaText>
              </ExpandedNotHaveChildRowWrapper>
            )
          }

          return (
            <ExpandedTableValue
              value={record?.clinic}
              isNotHaveActionExpanded
            />
          )
        },
      }),
      {
        title: t(R.strings.total_revenue_achieved),
        children: [
          {
            title: t(R.strings.orthodontic_correction),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.orthodontic),
                key: 'orthodontic',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.orthodontic ?? 0,
                    isFillBlueInTotal: true,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.invisalign),
                key: 'invisalign',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.invisalign ?? 0,
                    isFillBlueInTotal: true,
                    isBold: false,
                    expandedRowKeys,
                  })
                },
              }),
            ],
          },
          convertColumnTable<TypeColumn>({
            title: t(R.strings.implant),
            key: 'implant',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.implant ?? 0,
                expandedRowKeys,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.general_treatment),
            key: 'generalTreatment',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.generalTreatment ?? 0,
                expandedRowKeys,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.others_cosmetics),
            key: 'totalRevenueOthersCosmetics',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.totalRevenueOthersCosmetics ?? 0,
                isFillBlueInTotal: false,
                expandedRowKeys,
                isBold: false,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.cost_of_goods_sold),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.cp_nvl_live),
            key: 'cpNvlLive',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.cpNvlLive ?? 0,
                expandedRowKeys,
                isBold: false,
              })
            },
          }),
          {
            title: t(R.strings.salary_expenses_allowances),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.doctor_department),
                key: 'doctorDepartment',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.doctorDepartment ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.nursing_department),
                key: 'nursingDepartment',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.nursingDepartment ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.advisory_department),
                key: 'advisoryDepartment',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.advisoryDepartment ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.x_ray_technical_department),
                key: 'xRayTechnicalDepartment',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.xRayTechnicalDepartment ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.reception_department),
                key: 'receptionDepartment',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.receptionDepartment ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.security_and_housekeeping_department),
                key: 'securityAndHousekeepingDepartment',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue:
                      record?.securityAndHousekeepingDepartment ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
            ],
          },
          {
            title: t(R.strings.depreciation_expenses),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.initial_construction),
                key: 'initialConstruction',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.initialConstruction ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.machinery_and_equipment),
                key: 'machineryAndEquipment',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.machineryAndEquipment ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.ccdc),
                key: 'ccdc',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.ccdc ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
            ],
          },
          {
            title: t(R.strings.location_rental_expenses),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.rental_expenses),
                key: 'rentalExpenses',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.rentalExpenses ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.location_rental_tax_expenses),
                key: 'locationRentalTaxExpenses',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.locationRentalTaxExpenses ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
            ],
          },
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_operating_expenses),
            key: 'otherOperatingExpenses',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherOperatingExpenses ?? 0,
                expandedRowKeys,
                isBold: false,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.selling_expenses),
        children: [
          {
            title: t(R.strings.marketing),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.performance),
                key: 'performance',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.performance ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.branch),
                key: 'branch',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.branch ?? 0,
                    expandedRowKeys,
                    isBold: false,
                  })
                },
              }),
            ],
          },
          convertColumnTable<TypeColumn>({
            title: t(R.strings.call_center),
            key: 'callCenter',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.callCenter ?? 0,
                expandedRowKeys,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.card_swipe_installment_fees),
            key: 'cardSwipeInstallmentFees',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.cardSwipeInstallmentFees ?? 0,
                expandedRowKeys,
                isBold: false,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.business_management_expenses),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.marketing_bp),
            key: 'marketingBP',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.marketingBP ?? 0,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.operation_bp),
            key: 'operationBP',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.operationBP ?? 0,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.training_bp),
            key: 'trainingBP',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.trainingBP ?? 0,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.hr_bp),
            key: 'hrBP',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.hrBP ?? 0,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.accounting_bp),
            key: 'accountingBP',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.accountingBP ?? 0,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.purchasing_bp),
            key: 'purchasingBP',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.purchasingBP ?? 0,
                isBold: false,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_expenses),
            key: 'otherExpenses',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherExpenses ?? 0,
                isBold: false,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.other_profits),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_income),
            key: 'otherIncome',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherIncome ?? 0,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.interest_on_deposits),
            key: 'interestOnDeposits',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.interestOnDeposits ?? 0,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_profits),
            key: 'otherProfits',
            className: 'money-column',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherProfits ?? 0,
              })
            },
          }),
        ],
      },
    ]
    return options
  }, [t, expandedRowKeys, typeHook])

  return {
    columns,
    exportExcel,
  }
}

export const convertGeneralPNLValue = (item: IGeneralValuePNL) => {
  return {
    childs: {
      cpNvlLive: item?.costNVLTrucTiep,
      doctorDepartment: item?.costSalaryDoctor,
      nursingDepartment: item?.costSalaryNurse,
      advisoryDepartment: item?.costSalaryConsultant,
      xRayTechnicalDepartment: item?.costSalaryTechnician,
      receptionDepartment: item?.costReceptionist,
      securityAndHousekeepingDepartment: item?.costJanitorSecurityGuard, // change
      initialConstruction: item?.costInitConstruction,
      machineryAndEquipment: item?.costOfDevice,
      rentalExpenses: item?.costOfRental,
      locationRentalTaxExpenses: item?.costOfRentalHouse,
      otherOperatingExpenses: item?.costOtherOperation,
      cardSwipeInstallmentFees: item?.costOfPayment,
      othersCosmetics: item?.revenueKhac,
      planNumber: item?.costOfRentalHouse,
      performance: item?.costPerformanceMarketing,
      callCenter: item?.costOfCallCenter,
      ccdc: item?.costOfTool,
      branch: item?.costBrandMarketing,
      orthodontic: item?.revenueMaccai,
      invisalign: item?.revenueInvisalign,
      implant: item?.revenueImplant,
      generalTreatment: item?.revenueTongQuat,
      totalRevenueOthersCosmetics: item?.revenueKhac,

      marketingBP: item?.costOfMarketingBP,
      operationBP: item?.costOfOperationsBP,
      trainingBP: item?.costOfTrainingBP,
      hrBP: item?.costOfHumanResourcesBP,
      accountingBP: item?.costOfAccountingBP,
      purchasingBP: item?.costOfPurchasingBP,
      otherExpenses: item?.costOfOtherExpensesBP,
      otherIncome: item?.otherIncome,
      interestOnDeposits: item?.interestOnDeposits,
      otherProfits: item?.otherProfits,
    },
    total: {
      cpNvlLiveTotal: item?.costNVLTrucTiep,
      doctorDepartmentTotal: item?.costSalaryDoctor,
      nursingDepartmentTotal: item?.costSalaryNurse,
      advisoryDepartmentTotal: item?.costSalaryConsultant,
      xRayTechnicalDepartmentTotal: item?.costSalaryTechnician,
      receptionDepartmentTotal: item?.costReceptionist, // change
      securityAndHousekeepingDepartmentTotal: item?.costJanitorSecurityGuard, // change
      initialConstructionTotal: item?.costInitConstruction,
      machineryAndEquipmentTotal: item?.costOfDevice,
      rentalExpensesTotal: item?.costOfRental,
      locationRentalTaxExpensesTotal: item?.costOfRentalHouse,
      otherOperatingExpensesTotal: item?.costOtherOperation,
      cardSwipeInstallmentFeesTotal: item?.costOfPayment,
      othersCosmeticsTotal: item?.revenueKhac,
      planNumberTotal: item?.costOfRentalHouse,
      performanceTotal: item?.costPerformanceMarketing,
      callCenterTotal: item?.costOfCallCenter,
      ccdcTotal: item?.costOfTool,
      branchTotal: item?.costBrandMarketing,

      marketingBPTotal: item?.costOfMarketingBP,
      operationBPTotal: item?.costOfOperationsBP,
      trainingBPTotal: item?.costOfTrainingBP,
      hrBPTotal: item?.costOfHumanResourcesBP,
      accountingBPTotal: item?.costOfAccountingBP,
      purchasingBPTotal: item?.costOfPurchasingBP,
      otherExpensesTotal: item?.costOfOtherExpensesBP,
      otherIncomeTotal: item?.otherIncome,
      interestOnDepositsTotal: item?.interestOnDeposits,
      otherProfitsTotal: item?.otherProfits,
    },
  }
}
