import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import 'dayjs/locale/vi'
import { BORDER_RADIUS, colors } from 'parkway-web-common'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import { FilterReport14 } from './components/FilterReport14'
import { useTableHook } from './hook'
import { useGetHeightTable } from 'app/hook'

const Report14Container: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
    isLoadingExport,
  } = useTableHook()

  const { isHavePermissionFunction } = useVerifyPermission()
  console.log({ expandedRowKeys }, 'tableData.data', tableData.data)

  return (
    <RootWrapper>
      <ContentWrapper size={8}>
        <FilterReport14
          onChangeFilterReport14={handleChangeFilter}
          isHavePermissionExport={isHavePermissionFunction(
            ModulePermissionEnum.REPORT,
            FunctionPermissionEnum.REPORT_EXPORT_REPORT_14,
          )}
          handleExport={exportExcel}
          isLoadingExport={isLoadingExport}
        />
        <BaseReportWrapper
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record =>
              record?.children && record?.children?.length > 0,
          }}
          rowClassName="row-overwrite-style"
          pagination={false}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(265, 0) : undefined,
          }}
        />
      </ContentWrapper>
    </RootWrapper>
  )
}

export default Report14Container

const RootWrapper = styled(BaseSpace)`
  padding: 8px;
`

const ContentWrapper = styled(BaseSpace)`
  background-color: ${colors.white};
  padding: 8px;
  border-radius: ${BORDER_RADIUS};
`

const BaseReportWrapper = styled(BaseTableReport)`
  .ant-table-thead .ant-table-cell {
    padding: 8px !important;
  }
`
