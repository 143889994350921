import {
  requestExportListReferralVoucherCode,
  requestGetReferralDiscountVoucher,
} from 'app/api/marketing/discount-voucher'
import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import { IParamsGetReferralInfo } from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_, initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce } from 'app/hook'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, IFilterDataTableReport } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [usersSelected, setServicesSelected] = useState<IDataTable[]>([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState(initPagination)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState<IFilterDataTableReport>({})

  const data = useMemo(() => {
    return usersSelected?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [usersSelected])

  const params: IParamsGetReferralInfo = useMemo(() => {
    return {
      keyword: isEmpty(filter?.keyword) ? undefined : filter?.keyword,
      page: pagination.current,
      pageSize: pagination.pageSize,
      pagesize: pagination.pageSize,
      referralTypes: filter?.referralTypes ?? [],
      year: isEmpty(filter?.year) ? undefined : filter?.year,
      month: isEmpty(filter?.month) ? undefined : filter?.month,
    }
  }, [pagination, filter])

  const paramsDebounce = useDebounce(params, 500)

  const getData = async () => {
    try {
      setIsLoading(true)

      const res: ResponseType<IDataTable[]> =
        await requestGetReferralDiscountVoucher(paramsDebounce)

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        setServicesSelected(res?.data)
        setTotal(res?.metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [paramsDebounce])

  const exportVoucherCode = async () => {
    setIsLoading(true)
    try {
      const res: ResponseType<{ url?: string; Url?: string }> =
        await requestExportListReferralVoucherCode(paramsDebounce)
      if (res?.msgcode === DiscountVoucherMessageCode.DiscountVoucher.success) {
        window.open(res?.data?.url ?? res?.data?.Url, '_blank')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      } else {
        notificationController.error({
          message: t(R.strings.export_excel),
          description: t(R.strings.error),
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeFilter = (filter?: IFilterDataTableReport) => {
    setFilter(prev => ({
      ...prev,
      ...filter,
    }))

    setPagination(prev => ({ ...prev, current: 1 }))
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.code),
        key: 'id',
        fixed: 'left',
        className: 'voucher-code-column',
        width: '9%',
        render: value => {
          return <BaseText children={value} fontWeight="semibold" />
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: value => {
          return <BaseText children={value} fontWeight="semibold" />
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_code),
        key: 'vouchers',
        classNameWidthColumnOverwrite: 'medium-column',
        width: '50%',
        render: (_, record) => {
          return (
            <BaseRow gutter={[16, 16]} align={'middle'}>
              {record?.vouchers?.map((item, index) => {
                return (
                  <BaseCol key={index}>
                    <BaseText
                      children={item?.code}
                      fontWeight="medium"
                      opacity="0.7"
                    />
                  </BaseCol>
                )
              })}
            </BaseRow>
          )
        },
      }),
    ]
  }, [t, usersSelected])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    data,
    columns,
    loading,
    pagination,
    setPagination,
    total,
    handleTableChange,
    exportVoucherCode,
    onChangeFilter,
    filter,
  }
}
