import { CloseOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import {
  IFilterGetExportBill,
  IPayloadUpdateExportBill,
} from 'app/api/accounting/model/export-bill'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseTableReport } from '../../styles'
import { FilterLayout } from './Filter'
import { useTable } from './hook'
import {
  ButtonConfirmModal,
  ButtonFooterModal,
  EmptyList,
  FooterModalWrapper,
  FormContainer,
  FormInput,
  FormItem,
  ServiceContainer,
  ServiceRow,
  ServiceTitleRow,
  TitleText,
} from './styles'
import styled from 'styled-components'
import { useGetHeightTable } from 'app/hook'
interface IProps {
  reloadTable: number
  setReloadTable: React.Dispatch<React.SetStateAction<number>>
  handleExportExcel: (
    body: IFilterGetExportBill,
    setLoading: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>
  exportPermission: boolean
}

export const NeedUpdate = (props: IProps) => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    setIsLoading,
    handleTableChange,
    pagination,
    form,
    visible,
    handleSubmit,
    handleCancel,
    rules,
    onTaxCodeChange,
    selectedServices,
    onCheckBoxPress,
    onCheckAllPress,
    usedServices,
    isLoadingTable,
    onTaxCodeBlur,
  } = useTable({
    reloadTable: props?.reloadTable,
    setReloadTable: props?.setReloadTable,
  })

  const { t } = useTranslation()
  const initValues: IPayloadUpdateExportBill = {} as IPayloadUpdateExportBill

  return (
    <BaseSpace size={4}>
      <FilterLayout
        formData={filter}
        onChange={handleChangeFilter}
        setIsLoading={setIsLoading}
        handleExportExcel={props?.handleExportExcel}
        exportPermission={props?.exportPermission}
      />

      <BaseTableReportWrapper
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={isLoading || isLoadingTable}
        onChange={handleTableChange}
        rowClassName="row-overwrite-style"
        scroll={{
          x: true,
          y: data?.length ? useGetHeightTable(335, 0) : undefined,
        }}
      />
      <BaseModal
        open={visible}
        width={800}
        footer={
          <FooterModalWrapper justify={'end'} align={'middle'}>
            <ButtonFooterModal onClick={handleCancel}>
              {t(R.strings.cancel)}
            </ButtonFooterModal>
            <ButtonConfirmModal onClick={form?.submit} loading={isLoading}>
              {t(R.strings.update)}
            </ButtonConfirmModal>
          </FooterModalWrapper>
        }
        onCancel={handleCancel}
        closeIcon={<CloseOutlined rev={undefined} onClick={handleCancel} />}
      >
        <TitleText children={t(R.strings.bill_information)} />
        <FormContainer
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <FormItem
            name="taxId"
            label={t(R.strings.tax_id)}
          >
            <FormInput
              onChange={onTaxCodeChange}
              placeholder={t(R.strings.enter_tax_code, {
                field: t(R.strings.tax_id),
              })}
              onBlur={onTaxCodeBlur}
            />
          </FormItem>
          <FormItem
            name="companyName"
            label={t(R.strings.company_name_customer_name)}
            rules={rules.companyName}
            required
          >
            <FormInput />
          </FormItem>
          <FormItem
            name="address"
            label={t(R.strings.address)}
            rules={rules.address}
            required
          >
            <FormInput />
          </FormItem>
          <FormItem
            name="email"
            label={t(R.strings.email)}
            rules={rules.email}
            required
          >
            <FormInput
              placeholder={t(R.strings.enter_email, {
                field: t(R.strings.email),
              })}
            />
          </FormItem>

          <Divider dashed />

          {usedServices?.length > 0 ? (
            <ServiceContainer size={0}>
              <ServiceTitleRow align={'middle'}>
                <BaseText children={t(R.strings?.service)} />
                <BaseCheckbox
                  checked={selectedServices?.length === usedServices?.length}
                  onChange={onCheckAllPress}
                />
              </ServiceTitleRow>
              {usedServices?.map((item, index) => {
                const selected = !!selectedServices?.find(
                  i => i?._id === item?._id,
                )
                return (
                  <ServiceRow align={'middle'} key={`${index}`}>
                    <BaseText children={item?.name} />
                    <BaseCheckbox
                      checked={selected}
                      onChange={e => onCheckBoxPress(e, item)}
                    />
                  </ServiceRow>
                )
              })}
            </ServiceContainer>
          ) : (
            <EmptyList>{t(R.strings.no_service_info)}</EmptyList>
          )}
        </FormContainer>
      </BaseModal>
    </BaseSpace>
  )
}

const BaseTableReportWrapper = styled(BaseTableReport)`
  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`
