/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collapse } from 'antd'
import { requestBracesTray, requestExportBracesTray } from 'app/api/accounting'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import BaseText from 'app/components/common/BaseText'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import moment from 'moment'
import { Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ALink } from '../DebtContract/styles'
import { IBracesTray, IBracesTrayResponse } from './type'
import { IFilterBracesTray } from 'app/components/tables/BaseTableManagement/FilterBracesTrayTable'
import { formatMoneyWithoutSpace } from 'app/hook'

export const useTableHook = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)
  const [data, setData] = useState<IBracesTray[]>([])
  const [filterResponse, setFilterResponse] = useState<IFilterBracesTray>()
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  const [isLoadingExport, setIsLoadingExport] = useState(false)

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_BRACES_TRAY,
      R.strings.role,
    )
  }, [])

  const getData = async () => {
    try {
      setIsLoading(true)
      if (filterResponse?.startDate && filterResponse?.endDate) {
        const response: IBracesTrayResponse = await requestBracesTray({
          page: (pagination?.current || 0) - 1,
          pageSize: pagination?.pageSize,
          startDate: String(filterResponse?.startDate),
          endDate: String(filterResponse?.endDate),
          paidTypes: filterResponse?.paidType?.join(','),
          keyword: filterResponse?.search,
        })

        setTotal(response?.metadata?.total)

        setData(
          response?.data?.map(patient => ({
            ...patient,
            key: patient?._id,
          })),
        )
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [filterResponse])

  const onClickExport = async () => {
    setIsLoadingExport(true)
    try {
      if (!filterResponse?.startDate && !filterResponse?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        const response = await requestExportBracesTray({
          keyword: filterResponse?.search,
          startDate: filterResponse?.startDate,
          endDate: filterResponse?.endDate,
          paidTypes: filterResponse?.paidType?.join(','),
        })
        window.open(response?.data, '_blank', 'noopener,noreferrer')
      }
    } catch (error) {
      // console.log({ error })
    }
    setIsLoadingExport(false)
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<{
        patient: { id: string }
      }>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patient',
        classNameWidthColumnOverwrite: 'medium-column',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable value={record?.patient?.id} type="Main" />
        ),
      }),
      convertColumnTable<{
        patient: { name: string; _id: string }
        patient_name: any
      }>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patient_name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patient?._id,
              )
            }}
            style={{ fontSize: 12 }}
          >
            {record?.patient?.name}
          </ALink>
        ),
      }),
      convertColumnTable<{
        locationName: string
      }>({
        title: t(R.strings.location),
        key: 'locationName',
        className: 'clinic-column',
        render: (_, record) => (
          <RenderValueTable value={record?.locationName} type="Base" />
        ),
      }),
      convertColumnTable<{
        treatmentStart: { name: string | number | undefined }
      }>({
        title: t(R.strings.item_management_content_treatment_name),
        key: 'treatmentStart',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.treatmentStart?.name} type="Base" />
        ),
      }),
      convertColumnTable<{
        treatmentStart: { total: string | number | undefined }
        tip_price: string
      }>({
        title: t(R.strings.tip_price),
        key: 'tip_price',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(
              record?.treatmentStart?.total ?? 0,
            )}
            textAlign="right"
            fontWeight="medium"
            opacity="0.7"
          />
        ),
      }),
      convertColumnTable<{
        treatments: any[]
      }>({
        title: t(R.strings.package_name),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          const treatments = record?.treatments?.map(
            (treatment: { name: any }) => treatment?.name,
          )
          const items: any = [
            {
              key: '1',
              label: (
                <BaseText
                  children={treatments.length ? treatments[0] : ''}
                  fontWeight="medium"
                />
              ),
              children: treatments
                ?.filter((_: any, index: number) => index !== 0)
                ?.map((treatment: string | number | undefined) => (
                  <>
                    <RenderValueTable
                      styleText={{ fontSize: 12 }}
                      value={treatment}
                      type="Base"
                    />
                    <hr />
                  </>
                )),
            },
          ]

          return <Collapse items={items} className="Collapse-treatment" />
        },
      }),
      convertColumnTable<{
        treatmentTotal: string | number | undefined
      }>({
        title: t(R.strings.package_price),
        key: 'treatmentTotal',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.treatmentTotal ?? 0)}
            textAlign="right"
            fontWeight="medium"
            opacity="0.7"
          />
        ),
      }),
      convertColumnTable<{
        payment: { total: string | number | undefined }
      }>({
        title: t(R.strings.net_amount),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.total ?? 0)}
            textAlign="right"
            fontWeight="medium"
            opacity="0.7"
          />
        ),
      }),
      convertColumnTable<{
        payment: { paid: any | string | string[] }
      }>({
        title: t(R.strings.paid),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.paid ?? 0)}
            textAlign="right"
            fontWeight="medium"
            opacity="0.7"
          />
        ),
      }),
      convertColumnTable<{
        paidType: string
      }>({
        title: t(R.strings.manage_customer_payment_type),
        key: 'paidType',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={t(record?.paidType)} type="Base" />
        ),
      }),
      convertColumnTable<{
        date: string
      }>({
        title: t(R.strings.manage_customer_tip_start_date),
        key: 'date',
        className: 'date-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.date).format('DD/MM/YYYY')}
            type="Base"
          />
        ),
      }),
      convertColumnTable<{
        contractCompanyValue: number
      }>({
        title: t(R.strings.contract_company_value),
        key: 'contractCompanyValue',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(
              record?.contractCompanyValue ?? 0,
            )}
            textAlign="right"
            fontWeight="medium"
            opacity="0.7"
          />
        ),
      }),
      convertColumnTable<{
        contractClinicValue: number
      }>({
        title: t(R.strings.contract_clinic_value),
        key: 'contractClinicValue',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.contractClinicValue ?? 0)}
            textAlign="right"
            fontWeight="medium"
            opacity="0.7"
          />
        ),
      }),
      convertColumnTable<{
        contract: { SHD: string }
      }>({
        title: t(R.strings.contract_code),
        key: 'contract',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable value={record?.contract?.SHD} type="Base" />
        ),
      }),
      convertColumnTable<{
        isInvoicing: boolean
        patient: { isInvoicing: any }
      }>({
        title: t(R.strings.note_print),
        key: 'isInvoicing',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={
              record?.patient?.isInvoicing ? t(R.strings.yes) : t(R.strings.no)
            }
            type="Base"
          />
        ),
      }),
      convertColumnTable<{
        treatmentCount: number
      }>({
        title: t(R.strings.service_amount),
        key: 'treatmentCount',
        className: 'count-service-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.treatmentCount ?? 0, '')}
            textAlign="right"
            fontWeight="medium"
            opacity="0.7"
          />
        ),
      }),
    ]
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (filter: IFilterBracesTray) => {
    setFilterResponse(filter)
  }

  return {
    columns,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    data,
    isLoading,
    pagination: {
      ...pagination,
      total,
    },
    isLoadingExport,
  }
}
