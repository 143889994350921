import { ExportOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { useGetHeightTable } from 'app/hook'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SelectServiceComponent from './components/SelectServiceComponent'
import { useTableHook } from './hook'
import styled from 'styled-components'

const Report004DoctorContainer: React.FC = () => {
  const {
    columns,
    tableData,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
    handleChangeService,
  } = useTableHook()
  const { t } = useTranslation()

  const { isHavePermissionFunction, userData } = useVerifyPermission()

  const isCanExport = useMemo(() => {
    return isHavePermissionFunction(
      ModulePermissionEnum.REPORT,
      FunctionPermissionEnum.REPORT_EXPORT_REPORT_4,
    )
  }, [userData])
  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={8}>
        <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
          <BaseCol xl={isCanExport ? 21 : 24}>
            <FilterReport
              handleChangeFilter={handleChangeFilter}
              isSelectFilter
              selectFilterGroup="WEEK_MONTH_QUARTER_YEAR"
              isUsingWeekMonthCorrect
              isSelectQuarter
              otherFilterComponent={
                <BaseCol>
                  <SelectServiceComponent onChange={handleChangeService} />
                </BaseCol>
              }
              rootStyle={{ paddingBottom: 0 }}
            />
          </BaseCol>
          {isCanExport ? (
            <BaseCol xl={3}>
              <BaseRow justify={'end'} align={'middle'}>
                <BaseCol>
                  <BaseButton
                    children={
                      <BaseRow gutter={8}>
                        <BaseCol>
                          <ExportOutlined rev={undefined} />
                        </BaseCol>
                        <BaseCol>{t(R.strings.export_excel)}</BaseCol>
                      </BaseRow>
                    }
                    onClick={exportExcel}
                    // loading={isLoadingExport}
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          ) : null}
        </BaseRow>
        <BaseTableReportWrapper
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record =>
              record?.children && record?.children?.length > 0,
          }}
          rowClassName="row-overwrite-style"
          pagination={false}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(210, 0) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default Report004DoctorContainer

const BaseTableReportWrapper = styled(BaseTableReport)`
  .clinic-column-ovr {
    min-width: 150px !important;
  }

  .area-column {
    min-width: 130px !important;
  }

  .number-column {
    min-width: 50px !important;
  }

  .doctor-column {
    min-width: 150px !important;
  }

  .percent-crv-3-with-period {
    min-width: 100px !important;
  }
`
