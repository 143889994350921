import { Button } from 'antd'
import { scheduleStatusSearchAppointment } from 'app/api/appointment/constant'
import { EScheduleStatus } from 'app/api/appointment/model'
import R from 'app/assets/R'
import { DATE } from 'app/common/constants'
import { APPOINTMENT_VIEW_TYPE } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { FilterSearchAppointment } from 'app/components/tables/BaseTableManagement/FilterSearchAppointmentTable'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { PageSearchAppointmentContext } from 'app/context/PageSearchAppointmentContext'
import 'dayjs/locale/vi'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import ConfirmModal from '../AppointmentPage/SideBarRight/CalendarRight/ConfirmModal'
import CreateAndUpdate from '../AppointmentPage/SideBarRight/CalendarRight/CreateAndUpdate'
import { IListTotal } from '../AppointmentPage/type'
import AppointmentModal from './AppointmentModal'
import { initialPagination } from './common-model'
import HeaderPageAction from './HeaderPageAction'
import { useTableHook } from './hook'
import ListTotalPage from './ListTotalPage'
import {
  FilterRow,
  FilterRowBottom,
  FilterSearchAppointmentBox,
  ResultDateRange,
  ResultDateRangeNumber,
} from './styles'
import './styles.css'

const SearchAppointmentPage: React.FC = () => {
  const [filter, setFilter] = useState<{
    startDate
    endDate
  }>({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  })

  const {
    openAppointment,
    setOpenAppointment,
    openCreateUpdate,
    setOpenCreateUpdate,
    appointment,
    setAppointment,
    openConfirmModal,
    setOpenConfirmModal,
    cancelAppointmentItem,
    setCancelAppointmentItem,
  } = useContext(PageSearchAppointmentContext)
  const { appointmentTotal, sendSmsAppointment } =
    useContext(AppointmentContext)
  const { t } = useTranslation()

  const { columns, tableData, handleTableChange, onChangeFilter } =
    useTableHook({ filter, setFilter })

  const scheduleTotal: IListTotal | undefined = appointmentTotal?.find(
    (total: IListTotal) => total?._id === EScheduleStatus.TOTAL,
  )

  const handleSendSms = () => {
    const pageSize =
      (tableData.pagination['pageSize'] || 0) > 10
        ? tableData.pagination?.pageSize
        : initialPagination.pageSize
    sendSmsAppointment?.({
      ...filter,
      page: (tableData.pagination?.current || 0) - 1,
      pageSize,
      startDate: moment(filter?.startDate || moment().startOf('day'))?.format(
        DATE.YYYY_MM_DD_HH_MM,
      ),
      endDate: moment(filter?.endDate || moment().endOf('day')).format(
        DATE.YYYY_MM_DD_HH_MM,
      ),
      view: APPOINTMENT_VIEW_TYPE.LIST,
      scheduleStatus: scheduleStatusSearchAppointment,
    })
  }

  return (
    <S.BaseReportWrapper className="search-appointment">
      <AppointmentModal open={openAppointment} setOpen={setOpenAppointment} />
      <CreateAndUpdate
        open={openCreateUpdate}
        setOpen={setOpenCreateUpdate}
        appointment={appointment}
        setAppointment={setAppointment}
        view={APPOINTMENT_VIEW_TYPE.LIST}
      />
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        cancelAppointmentItem={cancelAppointmentItem}
        setCancelAppointmentItem={setCancelAppointmentItem}
      />

      <S.RootWrapper>
        <S.HeadWrapper>
          <FilterRow>
            <BaseCol xl={12}>
              <FilterSearchAppointmentBox>
                <FilterSearchAppointment
                  onChangeFilter={onChangeFilter}
                  isSearch
                />
              </FilterSearchAppointmentBox>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseRow justify={'end'} align={'middle'} gutter={8}>
                <BaseCol>
                  <HeaderPageAction
                    setOpenAppointment={setOpenAppointment}
                    pagination={tableData.pagination}
                  />
                </BaseCol>
                <BaseCol>
                  <Button onClick={handleSendSms}>
                    {t(R.strings.search_appointment_send_all)}
                  </Button>
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </FilterRow>

          <FilterRowBottom>
            <FilterSearchAppointment
              onChangeFilter={onChangeFilter}
              isSearch={false}
            />
          </FilterRowBottom>
          <ResultDateRange>
            {t(R.strings.total_schedule)}
            <ResultDateRangeNumber>
              {(scheduleTotal && scheduleTotal['total']) || 0}
            </ResultDateRangeNumber>
          </ResultDateRange>
        </S.HeadWrapper>
        <ListTotalPage />
        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName={record =>
            `row-overwrite-style row-status-${record?.scheduleStatus}`
          }
          pagination={tableData.pagination}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default SearchAppointmentPage
