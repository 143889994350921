import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseTabs } from 'app/components/common/BaseTabs'
import BaseText from 'app/components/common/BaseText'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 8px;
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`
export const CheckBox = styled(BaseCheckbox)``
export const ButtonContainer = styled.div`
  cursor: pointer;
  opacity: 0.5;
`
export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const BaseTabsExportBill = styled(BaseTabs)`
  background-color: var(--white);
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
`
export const MenuButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`
export const MenuText = styled(BaseText)`
  margin-left: 8px;
`
export const TableText = styled(BaseText)<{ $color?: string }>`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: ${props => props?.$color ?? 'rgba(0, 34, 68, 0.9)'} !important;
`
export const ListItemText = styled(BaseText)<{ $marginTop?: number }>`
  margin-top: ${props => props?.$marginTop ?? 0}px;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    text-align: center !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }

  .ant-table-cell {
    padding: 8px !important;
  }

  .sent-mail-column {
    min-width: 105px !important;
  }
  .payment-code-column {
    min-width: 113px !important;
  }
  .export-bill-date-column {
    min-width: 148px !important;
  }
  .patient-name-column {
    min-width: 246px !important;
  }
  .patient-code-column {
    min-width: 136px !important;
  }
  .branch-column {
    min-width: 126px !important;
  }
  .company-by-branch-column {
    min-width: 232px !important;
  }
  .service-used-column {
    min-width: 302px !important;
  }
  .address-column {
    min-width: 302px !important;
  }
  .date-column {
    min-width: 120px !important;
  }
  .money-column {
    min-width: 150px !important;
  }
  .tax-code-column {
    min-width: 142px !important;
  }
  .company-name-column {
    min-width: 160px !important;
  }
  .email-column {
    min-width: 220px !important;
  }
`
export const BaseTreeSelectWrapper = styled(BaseTreeSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`
export const ButtonAction = styled(BaseButton)<{
  backgroundColor?: string
  color?: string
}>`
  background-color: ${props =>
    props?.backgroundColor ?? 'var(--background-color)'};
  color: ${props => props?.color ?? 'var(--primary-color)'};
  padding: 8px;
  margin-left: 12px;
`
