import { ExportOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import 'dayjs/locale/vi'
import React, { useMemo } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useTableHook } from './hook'
import { useGetHeightTable } from 'app/hook'

const Report012Container: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    expandedRowKeys,
    handleExpand,
    handleChangeFilter,
    exportExcel,
    isLoadingExport,
  } = useTableHook()

  const { t } = useTranslation()

  const { isHavePermissionFunction, userData } = useVerifyPermission()

  const isCanExport = useMemo(() => {
    return isHavePermissionFunction(
      ModulePermissionEnum.REPORT,
      FunctionPermissionEnum.REPORT_EXPORT_REPORT_12,
    )
  }, [userData])

  return (
    <RootWrapper>
      <ContentWrapper size={4}>
        <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
          <BaseCol xl={isCanExport ? 21 : 24}>
            <FilterReport
              isHaveChooseWeek={false}
              isHaveChooseMonth={true}
              isSelectFilter
              isSelectQuarter
              handleChangeFilter={handleChangeFilter}
              selectFilterGroup="MONTH_QUARTER"
              initSelectEnum="MONTH"
              rootStyle={{ paddingBottom: 0 }}
            />
          </BaseCol>
          {isCanExport ? (
            <BaseCol xl={3}>
              <BaseRow justify={'end'} align={'middle'}>
                <BaseCol>
                  <BaseButton
                    children={
                      <BaseRow gutter={8}>
                        <BaseCol>
                          <ExportOutlined rev={undefined} />
                        </BaseCol>
                        <BaseCol>{t(R.strings.export_excel)}</BaseCol>
                      </BaseRow>
                    }
                    onClick={exportExcel}
                    loading={isLoadingExport}
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          ) : null}
        </BaseRow>
        <BaseTableReportWrapper
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record =>
              record?.children && record?.children?.length > 0,
          }}
          rowClassName="row-overwrite-style"
          pagination={false}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(280, 0) : undefined,
          }}
        />
      </ContentWrapper>
    </RootWrapper>
  )
}

export default Report012Container

const RootWrapper = styled.div`
  padding: 8px;
`

const ContentWrapper = styled(BaseSpace)`
  padding: 8px;
  background-color: white;
  border-radius: 8px;
`

const BaseTableReportWrapper = styled(BaseTableReport)`
  .ant-table-cell {
    padding: 8px !important;
  }

  .index-column {
    min-width: 60px !important;
  }

  .clinic-column {
    min-width: 200px !important;
  }

  .money-column {
    min-width: 100px !important;
    text-align: right !important;
  }
`
