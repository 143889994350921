import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 8px;
  padding-bottom: 4px;
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .all-column {
    padding: 8px !important;
  }
  .ant-table-thead > tr > th {
    text-align: center !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }

  .money-column {
    min-width: 90px !important;
  }

  .name-patient-column {
    min-width: 150px !important;
  }

  .clinic-patient-column {
    min-width: 150px !important;
  }

  .date-column {
    min-width: 90px !important;
  }

  .voucher-code-column {
    min-width: 100px !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 6px !important;
    padding-top: 8px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`
export const DayText = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: ${convertedVariables.primaryColor};
  opacity: 0.4;
`
export const TotalText = styled(BaseText)`
  margin: 0 3px;
`
export const DayContainer = styled(BaseSpace)``
export const VerticalDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: var(--neutral-black-2-color);
  margin: 0 12px;
`
