import { Divider } from 'antd'
import { SvgSelectBlockIcon, SvgSelectListIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  LAYOUT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: ${PADDING.md};
`

export const HeadPageWrapper = styled(BaseRow)`
  padding-bottom: ${LAYOUT.desktop.headerPadding};
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`

export const GroupWrapper = styled.div`
  padding: ${PADDING.xxxs} 0px;
`

export const GroupHeadTitle = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--primary-color);
  margin-left: ${PADDING.xxxs};
`
export const GroupHeadLine = styled.div`
  display: flex;
  flex: 1;
  height: 1px;
  background-color: #a5abb3; // need change
  margin-left: ${PADDING.md};
`

export const SelectionWrapper = styled(BaseRow)`
  background-color: var(--white);
  border-radius: ${BORDER_RADIUS};
`

export const ButtonSelection = styled.div`
  background-color: var(--white);
  border: none;
  cursor: pointer;
  padding: ${PADDING.xxxxs};
  transition: cursor 0.3s ease;
  &:hover {
    cursor: pointer;
  }
`

export const BlockSelectIcon = styled(SvgSelectBlockIcon)<{
  $isBlock?: boolean
}>`
  & > g > path {
    stroke: ${props => (props?.$isBlock ? '#216AE2' : 'var(--primary-color)')};
  }
`

export const ListSelectIcon = styled(SvgSelectListIcon)<{
  $isList?: boolean
}>`
  & > g > path {
    stroke: ${props => (props?.$isList ? '#216AE2' : 'var(--primary-color)')};
  }
`

export const CenterLineSelection = styled.div`
  width: 1px;
  height: 10px;
  background-color: #d9d9d9; //need change
`

// block item
export const BlockWrapper = styled(BaseRow)`
  margin-top: ${PADDING.md};
`

export const BlockItemWrapper = styled(BaseCol)`
  padding: ${PADDING.md} !important;
  border-radius: ${BORDER_RADIUS};
  background-color: var(--white);
  cursor: pointer;
  transition: all 0.5s ease;
  height: 100%;
  &:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  };
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const FirstLabelHeadBlock = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`

export const LabelHeadBlock = styled.span`
  color: var(--states-orange-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const DescriptionBlock = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  padding-top: ${PADDING.xs};
`

export const DateBlock = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xxxs};
  font-weight: ${FONT_WEIGHT.regular};
`
export const DashedLine = styled(Divider)`
  margin: ${PADDING.xxxs} 0;
`

// list

export const ListWrapper = styled.div`
  margin-top: ${PADDING.md};
`
export const ListItemWrapper = styled(BaseRow)`
  padding: ${PADDING.xs} 0px;
  background-color: var(--white);
  padding-right: ${PADDING.xs};
  margin-bottom: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  }
`

export const LeftListItemPoint = styled.div`
  width: 4px;
  height: 24px;
  background-color: #216ae2; //need change
  border-top-right-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
`

export const LabelListText = styled.div`
  color: #216ae2; //need change
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  padding: 0 ${PADDING.xxxs};
`

export const DescriptionList = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  margin-left: ${PADDING.xxxs};
`
