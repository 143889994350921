import { Tabs } from 'antd'
import { StatusApproveEnum } from 'app/api/dental-treatment/survey-dynamic/type'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { PADDING } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'
import { ApprovedTab, PendingTab } from './tabs'

const MedicalRecordAndInformationContainer = () => {
  const { t } = useTranslation()
  const [tabKey, setTabKey] = useState<StatusApproveEnum>(
    StatusApproveEnum.PENDING,
  )

  const { isLoading, dataTotal } = useHook()

  return (
    <S.BaseManagementWrapper>
      <S.RootWrapper>
        <Tabs
          defaultActiveKey={R.strings.medical_record_and_information}
          tabPosition={'top'}
          onChange={tab => setTabKey(tab as StatusApproveEnum)}
          items={[
            {
              label: (
                <BaseRow gutter={[8, 8]} align={'middle'}>
                  <BaseCol>
                    <BaseText
                      fontWeight="semibold"
                      fontSize="xs"
                      children={t(R.strings.pending_approval)}
                      colorText={
                        isEqual(tabKey, StatusApproveEnum.PENDING)
                          ? 'primaryColor'
                          : 'neutralBlackColor'
                      }
                    />
                  </BaseCol>
                  <BaseCol>
                    <NumberWrapper
                      $active={isEqual(tabKey, StatusApproveEnum.PENDING)}
                    >
                      <BaseText
                        children={
                          dataTotal?.[StatusApproveEnum.PENDING]?.total ?? 0
                        }
                        fontWeight="semibold"
                        style={{
                          color: isEqual(tabKey, StatusApproveEnum.PENDING)
                            ? convertedVariables.backgroundColor
                            : '#002244',
                        }}
                        opacity={
                          isEqual(tabKey, StatusApproveEnum.PENDING)
                            ? '1'
                            : '0.7'
                        }
                      />
                    </NumberWrapper>
                  </BaseCol>

                  {isLoading ? <Loading isAbsolute size="small" /> : null}
                </BaseRow>
              ),
              key: StatusApproveEnum.PENDING,
              children: <PendingTab />,
            },
            {
              label: (
                <BaseRow gutter={[8, 8]} align={'middle'}>
                  <BaseCol>
                    <BaseText
                      fontWeight="semibold"
                      fontSize="xs"
                      children={t(R.strings.purchase_order_confirmed)}
                      colorText={
                        isEqual(tabKey, StatusApproveEnum.APPROVED)
                          ? 'primaryColor'
                          : 'neutralBlackColor'
                      }
                    />
                  </BaseCol>

                  <BaseCol>
                    <NumberWrapper
                      $active={isEqual(tabKey, StatusApproveEnum.APPROVED)}
                    >
                      <BaseText
                        children={
                          dataTotal?.[StatusApproveEnum.APPROVED]?.total ?? 0
                        }
                        fontWeight="semibold"
                        style={{
                          color: isEqual(tabKey, StatusApproveEnum.APPROVED)
                            ? convertedVariables.backgroundColor
                            : '#002244',
                        }}
                        opacity={
                          isEqual(tabKey, StatusApproveEnum.APPROVED)
                            ? '1'
                            : '0.7'
                        }
                      />
                    </NumberWrapper>
                  </BaseCol>

                  {isLoading ? <Loading isAbsolute size="small" /> : null}
                </BaseRow>
              ),
              key: StatusApproveEnum.APPROVED,
              children: <ApprovedTab />,
            },
          ]}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default MedicalRecordAndInformationContainer

const NumberWrapper = styled.div<{ $active?: boolean }>`
  padding: 2px 8px;
  border-radius: ${PADDING.xxl};
  background-color: ${props =>
    props?.$active ? convertedVariables.collapseBackgroundColor : '#EBEEF2'};
`
