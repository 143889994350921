import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 8px;
  padding-bottom: 2px;
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .all-column {
    padding: 8px !important;
  }
  .ant-table-thead > tr > th {
    text-align: left !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }

  .ant-table-thead > tr {
    .facilities-column {
      text-align: right !important;
    }

    .star-column {
      text-align: center !important;
    }
  }

  .weekend-appointment {
    background: #f9fafb;
  }

  .date-time-column {
    min-width: 125px !important;
  }

  .star-column {
    min-width: 75px !important;
  }

  .professional-competence-column {
    min-width: 106px !important;
  }

  .service-experience-column {
    min-width: 106px !important;
  }

  .facilities-column {
    min-width: 86px !important;
  }

  .phone-column {
    min-width: 102px !important;
  }

  .clinic-column {
    min-width: 153px !important;
  }

  .patient-name-column {
    min-width: 150px !important;
  }

  .content-column {
    min-width: 200px !important;
  }

  .booking-code {
    min-width: 85px !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px; 
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`
export const DayText = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: ${convertedVariables.primaryColor};
  opacity: 0.4;
`
export const DayContainer = styled(BaseSpace)``
