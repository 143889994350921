import R from 'app/assets/R'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DifficultyPackage } from './components/DifficultyPackage'
import { IDataTablePatient } from './type'
import { getDetailPatientUrl } from 'app/common/helpers'

export const useCommonColumn = () => {
  const { t } = useTranslation()
  const columnsPatientsOfThreeTabFirst = useMemo(() => {
    return [
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.patient_name),
        key: 'patientName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.patientName ?? '-'}
            type="OtherLink"
            hrefOtherLink={getDetailPatientUrl(record.patientId)}
          />
        ),
        fixed: 'left',
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.patient_id),
        key: 'patientCode',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.patientCode ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.appointment_date),
        key: 'appointmentDate',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.appointmentDate ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.total_month),
        key: 'totalMonth',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.totalMonth ?? '0'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.package_level),
        key: 'packageLevel',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <DifficultyPackage level={record.packageLevel ?? 0} />
        ),
      }),
      convertColumnTable<IDataTablePatient>({
        title: t(R.strings.assigned_physician),
        key: 'assignedPhysician',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.assignedPhysician ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
    ]
  }, [t])

  return {
    columnsPatientsOfThreeTabFirst,
  }
}
