import R from 'app/assets/R'
import { SvgHumanResourcesFocusIcon } from 'app/assets/svg-assets'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  ACCOUNT_MANAGEMENT_PATH,
  APPROVE_DAY_OFF_PATH,
  DEGREE_MANAGEMENT_PATH,
  JOB_TITLE_LEVEL_PATH,
  JOB_TITLE_PATH,
  MONTHLY_ROSTER_PATH,
  PROFILE_MANAGEMENT_PATH,
  REPORT_DOCTOR_WORKING_HOUR_PATH,
  REPORT_LEAVE_PATH,
  ROLE_MANAGEMENT,
  SKILL_MANAGEMENT_PATH,
  STRATEGIC_DOCTOR_PATH,
  UNIT_PATH
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.hr,
    icon: <SvgHumanResourcesFocusIcon />,
    pages:[
      {
        id: 11,
        label: '001',
        labelI18nKey: R.strings.account_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ACCOUNT_MANAGEMENT_PATH,
        permission: FunctionPermissionEnum.ACCOUNT_READ,
        isHideLabel: true,
      },
      {
        id: 12,
        label: '002',
        labelI18nKey: R.strings.job_title_content_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: JOB_TITLE_PATH,
        // permission: FunctionPermissionEnum.JOB_TITLE_READ
        isHideLabel: true,
      },
      {
        id: 13,
        label: '003',
        labelI18nKey: R.strings.job_title_level_content_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: JOB_TITLE_LEVEL_PATH,
        permission: FunctionPermissionEnum.JOB_TITLE_LEVEL_READ,
        isHideLabel: true,
      },
      {
        id: 14,
        label: '004',
        labelI18nKey: R.strings.unit_content_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: UNIT_PATH,
        permission: FunctionPermissionEnum.UNIT_READ,
        isHideLabel: true,
      },
      {
        id: 15,
        label: '005',
        labelI18nKey: R.strings.profile_content_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: PROFILE_MANAGEMENT_PATH,
        permission: FunctionPermissionEnum.PROFILE_MANAGEMENT_READ,
        isHideLabel: true,
      },
      {
        id: 16,
        label: '006',
        labelI18nKey: R.strings.skill_content_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: SKILL_MANAGEMENT_PATH,
        permission: FunctionPermissionEnum.SKILL_READ,
        isHideLabel: true,
      },
      {
        id: 17,
        label: '007',
        labelI18nKey: R.strings.degree_content_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DEGREE_MANAGEMENT_PATH,
        permission: FunctionPermissionEnum.DEGREE_READ,
        isHideLabel: true,
      },
      {
        id: 18,
        label: '008',
        labelI18nKey: R.strings.role_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: ROLE_MANAGEMENT,
        permission: FunctionPermissionEnum.ROLES_READ,
        isHideLabel: true,
      },
      {
        id: 19,
        label: '009',
        labelI18nKey: R.strings.strategic_doctor,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: STRATEGIC_DOCTOR_PATH,
        isHideLabel: true,
      },
      {
        id: 111,
        label: '010',
        labelI18nKey: R.strings.approve_day_off,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: APPROVE_DAY_OFF_PATH,
        isHideLabel: true,
      },
    ],
  },
  {
    id: 2,
    titleI18nKey: R.strings.report,
    icon: <SvgHumanResourcesFocusIcon />,
    pages:[
      {
        id: 21,
        indexReport: '001',
        labelI18nKey: R.strings.monthly_roster,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MONTHLY_ROSTER_PATH,
      },
      {
        id: 22,
        indexReport: '002',
        labelI18nKey: R.strings.report_leave,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_LEAVE_PATH,
      },
      {
        id: 23,
        indexReport: '003',
        labelI18nKey: R.strings.report_doctor_work_time,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_DOCTOR_WORKING_HOUR_PATH,
      },
    ]
  }
]
