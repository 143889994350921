import R from 'app/assets/R'
import { SvgMarketingFocusIcon } from 'app/assets/svg-assets'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  MARKETING_CAMPAIGN_PATH,
  MARKETING_IMPORT_PLAN,
  MARKETING_LIST_REFERRAL_CODE_PATH,
  MARKETING_REFERER_REPORT_PATH,
  MARKETING_REFERRAL_VOUCHER_USER_STATISTICS,
  MARKETING_REPORT_001_FULL_PATH,
  MARKETING_REPORT_006_FULL_PATH,
  MARKETING_REPORT_007_FULL_PATH,
  MARKETING_REPORT_008_FULL_PATH,
  MARKETING_REPORT_009_FULL_PATH,
  MARKETING_REPORT_010_FULL_PATH,
  MARKETING_REPORT_011_FULL_PATH,
  LIMIT_DISCOUNT_MANAGEMENT_PATH,
  REPORT_021_FULL_PATH,
  MARKETING_FIND_CAMPAIGN_WITH_CODE
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.report_group_import_data,
    icon: <SvgMarketingFocusIcon />,
    pages:[
      {
        id: 11,
        label: '001',
        labelI18nKey: R.strings.marketing_plan,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_IMPORT_PLAN,
        permission: FunctionPermissionEnum.IMPORT_MARKETING_PLAN,
        isHideLabel: true
      },
    ],
  },
  {
    id: 2,
    titleI18nKey: R.strings.report_group_report,
    icon: <SvgMarketingFocusIcon />,
    pages:[
      {
        id: 21,
        indexReport: '001',
        labelI18nKey: R.strings.report_001_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_001_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_1,
      },
      {
        id: 22,
        indexReport: '006',
        labelI18nKey: R.strings.report_006_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_006_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_6,
      },
      {
        id: 7,
        indexReport: '007',
        labelI18nKey: R.strings.report_007_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_007_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_7,
      },
      {
        id: 8,
        indexReport: '008',
        labelI18nKey: R.strings.report_008_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_008_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_8,
      },
      {
        id: 9,
        indexReport: '009',
        labelI18nKey: R.strings.report_009_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_009_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_9,
      },
      {
        id: 10,
        indexReport: '010',
        labelI18nKey: R.strings.report_010_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_010_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_10,
      },
      {
        id: 11,
        indexReport: '011',
        labelI18nKey: R.strings.report_011_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_011_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_11,
      },
      {
        id: 12,
        indexReport: '017',
        labelI18nKey: R.strings.referer_report,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REFERER_REPORT_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_17,
      },
      {
        id: 13,
        indexReport: '018',
        labelI18nKey: R.strings.referral_voucher_code_list,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_LIST_REFERRAL_CODE_PATH,
        permission: FunctionPermissionEnum.READ_LIST_VOUCHER_CODE,
      },
      {
        id: 14,
        indexReport: '019',
        labelI18nKey: R.strings.referral_voucher_user_statistics,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REFERRAL_VOUCHER_USER_STATISTICS,
        permission: FunctionPermissionEnum.READ_LIST_VOUCHER_CODE,
      },
      {
        id: 45,
        indexReport: '021',
        labelI18nKey: R.strings.report_21,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_021_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_21,
      },
    ],
  },
  {
    id: 3,
    titleI18nKey: R.strings.promotion_management,
    icon: <SvgMarketingFocusIcon />,
    pages:[
      {
        id: 31,
        label: '001',
        labelI18nKey: R.strings.discount_campaign_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_CAMPAIGN_PATH,
        permission: FunctionPermissionEnum.READ_DISCOUNT_CAMPAIGN,
        isHideLabel: true
      },
      {
        id: 32,
        label: '002',
        labelI18nKey: R.strings.limit_discount_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: LIMIT_DISCOUNT_MANAGEMENT_PATH,
        isHideLabel: true
      },
      {
        id: 33,
        label: '003',
        labelI18nKey: R.strings.find_campaign_by_voucher_code,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_FIND_CAMPAIGN_WITH_CODE,
        isHideLabel: true,
      },
    ],
  },
]
