import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { BaseCol } from '../BaseCol'
import { BaseModal } from '../BaseModal'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`

export const ButtonCreateHyperlinkAction = styled(BaseButton)`
  transition: unset !important;
  border: unset;
  box-shadow: none;
  color: #1677ff;
  padding: 0 !important;

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    transition: all 0.5s ease;
  }

  &:where(.css-dev-only-do-not-override-xu9wm8).ant-btn:not(
      :disabled
    ):focus-visible {
    outline: none;
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):touch {
  }
`
export const TitleButton = styled(BaseCol)`
  text-decoration: underline;
`

export const ButtonUpdateAction = styled(BaseButton)`
  border-color: transparent;
  transition: all 0.5s ease;
  color: var(--primary-color) !important;
  box-shadow: none;
  width: 100%;
  justify-content: flex-start !important;
  &:hover {
    background-color: var(--other-blue-color);
    color: var(--background-color) !important;
    transition: all 0.5s ease;
    border-color: transparent !important;
    box-shadow: none;
  }
`

export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)`
margin: 12px 0px !important;
`

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-label {
    padding: 0px;
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`

export const FormInput = styled(BaseInput)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`

export const FormInputPassword = styled(InputPassword)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`

export const SelectBaseStyled = styled(BaseSelect)``
export const FooterModalWrapper = styled(BaseRow)``

export const DeleteModalWrapper = styled(BaseCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DeleteDescriptionModalWrapper = styled(BaseCol)`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  padding: ${PADDING.xs} 0px;
  text-align: center;
`

export const ButtonFooterModal = styled(BaseButton)`
  min-width: 120px;
`

export const ButtonConfirmModal = styled(ButtonFooterModal)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`

export const BaseModalCustom = styled(BaseModal)`
  .ant-modal-close {
    position: unset;
  }
`
