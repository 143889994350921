import { BaseButton } from 'app/components/common/BaseButton'
import styled from 'styled-components'

export const ButtonPrint = styled(BaseButton)`
  padding: 0px 20px 0px 10px;
  border-radius: 4px;
  gap: 8px;
`

export const ButtonAddNew = styled(BaseButton)`
  padding: 0px 20px 0px 10px;
  border-radius: 4px;
  gap: 8px;
  background: #ff7854;
  color: white;
  border: initial;
`
