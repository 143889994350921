import {
  requestCreateJobTitleManagement,
  requestGetAllJobTitleManagement,
} from 'app/api/jobtitle'
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import {
  IJobTitle,
  IPayloadCreateJobTitleManagement,
} from 'app/api/jobtitle/model/job-title-management'
import { requestGetAllJobTitleGroup } from 'app/api/jobtitlegroup'
import { IJobTitleGroup } from 'app/api/jobtitlegroup/model/job-title-management'
import { requestGetAllJobTitleLevelManagement } from 'app/api/jobtitlelevel'
import {
  IAllJobTitleLevel,
  IJobTitleLevel,
} from 'app/api/jobtitlelevel/model/job-title-level-management'
import { requestPostJobTitleRole } from 'app/api/roles'
import { RoleMessageCode } from 'app/api/roles/constant'
import { IRoleManagement } from 'app/api/roles/models/role'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsCreateJobTitle, StatusJobTitleType } from '.'
import { IFormData, IFormRule } from './type'

export const useModalHook = (props: IPropsCreateJobTitle) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<StatusJobTitleType | undefined>()
  const [jobTitleLevels, setJobTitleLevels] = useState<IJobTitleLevel[]>([])
  const [jobTitles, setJobTitles] = useState<IJobTitle[]>([])
  const [jobTitleGroups, setJobTitleGroups] = useState<IJobTitleGroup[]>([])
  const [roles, setRoles] = useState<IRoleManagement[]>([])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const payload: IPayloadCreateJobTitleManagement = {
        name: values?.name,
        description: values?.description,
        jobTitleLevelId: values?.jobTitleLevelId,
        jobTitleIdManagement: values?.jobTitleIdManagement,
        jobTitleGroupId: values?.jobTitleGroupId,
        code: values?.code,
        hasTimekeeping: !!values?.hasTimekeeping,
        dayOffType: values?.dayOffType,
        staticDayOffInWeek: values?.staticDayOffInWeek,
        totalDynamicDayOffInWeek: values?.totalDynamicDayOffInWeek ?? undefined,
        status: StatusEnum.ACTIVE,
      }
      const response: ResponseType<IJobTitle> =
        await requestCreateJobTitleManagement(payload)

      if (
        isEqual(
          response?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successPostData,
        )
      ) {
        const dataResponse = response?.data

        const responseCreateJobTitleRole: ResponseType<string> =
          await requestPostJobTitleRole({
            jobTitleId: dataResponse?._id,
            description: payload.name,
            roles: roles?.map(itm => itm?._id ?? '') ?? [],
          })

        if (
          isEqual(
            responseCreateJobTitleRole.msgcode,
            RoleMessageCode.RoleManagement.successGetData,
          )
        ) {
          setIsLoading(false)
          handleCancel()
          props?.fetchTable?.()
          setRoles([])
        }
      } else {
        notificationController.error({
          message: t(R.strings.job_title_content_add_job_title),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getAllJobTitleGroup = async () => {
    try {
      const res: ResponseType<IJobTitleGroup[]> =
        await requestGetAllJobTitleGroup({})

      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitleLevel[] = res?.data || []
        setJobTitleGroups(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllJobTitleLevel = async () => {
    try {
      const res: ResponseType<IAllJobTitleLevel> =
        await requestGetAllJobTitleLevelManagement()

      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitleLevel[] = res?.data?.rows || []
        setJobTitleLevels(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllJobTitle = async () => {
    try {
      const res: ResponseType<IJobTitle[]> =
        await requestGetAllJobTitleManagement()
      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitle[] = res?.data || []
        setJobTitles(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  useEffect(() => {
    visible && getAllJobTitleLevel()
    visible && getAllJobTitle()
    visible && getAllJobTitleGroup()
  }, [visible])

  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_field_job_title_string_id),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_field_name),
          }),
        },
      ],
      jobTitleIdManagement: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_job_title_management),
          }),
        },
      ],
      jobTitleGroupId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_content_group),
          }),
        },
      ],
      description: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_field_description),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
      role: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.job_title_field_role),
          }),
        },
      ],
    }
  }, [t])

  const onChangeRole = (newRole: IRoleManagement[]) => {
    setRoles(newRole)
  }

  const onRemoveRole = (index: number) => {
    setRoles(prev => prev?.filter((_, idx) => idx !== index))
  }

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    setStatus,
    status,
    jobTitleLevels,
    jobTitles,
    jobTitleGroups,
    roles,
    onChangeRole,
    onRemoveRole,
  }
}
