/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExportOutlined, SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { IClinic, IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import dayjs, { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IFormFilter } from '../../type'
import { BaseButton } from 'app/components/common/BaseButton'

interface IProps {
  onChange?: (value: IFormFilter) => void
  formData?: IFormFilter
  hideClinicFilter?: boolean
  handleExportExcel: () => Promise<void>
  isHavePermissionExport: boolean
}

export const FilterLayout: React.FC<IProps> = ({
  formData,
  onChange,
  hideClinicFilter,
  isHavePermissionExport,
  handleExportExcel,
}) => {
  const { flattenDataList } = usePagination()

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)

    let arr: IClinic[] = []
    res?.forEach(i => {
      arr = [...arr, ...(i?.childs || [])]
    })

    return arr?.map(e => ({ ...e, _id: e?._oldId ?? e?._id }))
  }, [areaApi])

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange({ ...formData, keyword: value })
  }

  const onChangeClinic = (locations: string[]) => {
    onChange && onChange({ ...formData, locations })
  }

  const onChangeStart = (startDate: Dayjs | null) => {
    onChange && onChange({ ...formData, startDate })
  }

  const onChangeEnd = (endDate: Dayjs | null) => {
    onChange && onChange({ ...formData, endDate })
  }

  const onChangeStar = (stars?: number[]) => {
    onChange && onChange({ ...formData, stars })
  }

  const disabledEndDate = (current: Dayjs) => {
    if (!formData?.startDate) {
      return false // Nếu chưa chọn startDate, không giới hạn ngày
    }
    return current && current.isBefore(formData?.startDate, 'day') // Không cho phép chọn ngày trước startDate
  }

  const disabledStartDate = (current: Dayjs) => {
    if (!formData?.endDate) {
      return false
    }
    return current && current.isAfter(formData?.endDate, 'day')
  }

  const disabledTimeStart: any = (current: Dayjs | null) => {
    if (!current || !formData?.endDate) {
      return {}
    }

    if (current?.isSame(formData?.endDate, 'day') && formData?.endDate) {
      const disabledHours =
        Array.from({ length: 24 }, (_, i) =>
          i > (formData?.endDate?.hour() ?? 0) ? i : undefined,
        )?.filter(i => i !== undefined) ?? []

      const disabledMinutes =
        Array.from({ length: 60 }, (_, i) =>
          i > (formData?.endDate?.minute() ?? 0) ? i : undefined,
        )?.filter(i => i !== undefined) ?? []

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
      }
    }
    return {}
  }

  const disabledTimeEnd: any = (current: Dayjs | null) => {
    if (!current || !formData?.startDate) {
      return {}
    }

    if (current?.isSame(formData?.startDate, 'day') && formData?.startDate) {
      const disabledHours =
        Array.from({ length: 24 }, (_, i) =>
          i < (formData?.startDate?.hour() ?? 0) ? i : undefined,
        )?.filter(i => i !== undefined) ?? []

      const disabledMinutes =
        Array.from({ length: 60 }, (_, i) =>
          i < (formData?.startDate?.minute() ?? 0) ? i : undefined,
        )?.filter(i => i !== undefined) ?? []

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
      }
    }
    return {}
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'bottom'}>
        <BaseCol style={{ display: 'flex', flex: 1 }}>
          <BaseFormItem label={t(R.strings.search)} style={{ width: '100%' }}>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_keyword_search)}
              onChange={onChangeKeyword}
              allowClear
              style={{ width: '100%' }}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={4}>
          <BaseFormItem name={'startDate'} label={t(R.strings.start_date)}>
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.start_date)}
              onChange={onChangeStart}
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
              disabledDate={disabledStartDate}
              disabledTime={disabledTimeStart}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={4}>
          <BaseFormItem name={'endDate'} label={t(R.strings.end_date)}>
            <BaseDatePickerStyled
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.end_date)}
              onChange={onChangeEnd}
              showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
              disabledDate={disabledEndDate}
              disabledTime={disabledTimeEnd}
            />
          </BaseFormItem>
        </BaseCol>
        {hideClinicFilter ? null : (
          <BaseCol xl={4}>
            <BaseFormItem label={t(R.strings.clinic)}>
              <BaseSelectWrapper
                showSearch
                placeholder={t(R.strings.choose_clinic)}
                allowClear
                onChange={locations => onChangeClinic?.(locations as string[])}
                options={areaList?.map(item => ({
                  value: item.oldid,
                  label: item.name,
                }))}
                loading={isLoadingArea}
                mode="multiple"
                maxTagCount={1}
              />
            </BaseFormItem>
          </BaseCol>
        )}

        <BaseCol xl={3}>
          <BaseFormItem label={t(R.strings.appointment_star)}>
            <BaseSelectWrapper
              showSearch
              placeholder={t(R.strings.all)}
              allowClear
              onChange={stars => onChangeStar?.(stars as number[])}
              options={[1, 2, 3, 4, 5]?.map(item => ({
                value: item,
                label: `${item} sao`,
              }))}
              loading={isLoadingArea}
              mode="multiple"
              maxTagCount={0}
            />
          </BaseFormItem>
        </BaseCol>
        {isHavePermissionExport ? (
          <BaseCol xl={3}>
            <BaseRow justify={"end"}>
              <BaseFormItem label={' '}>
                <BaseButton
                  children={
                    <BaseRow gutter={8}>
                      <BaseCol>
                        <ExportOutlined rev={undefined} />
                      </BaseCol>
                      <BaseCol>{t(R.strings.export_excel)}</BaseCol>
                    </BaseRow>
                  }
                  onClick={handleExportExcel}
                />
              </BaseFormItem>
            </BaseRow>
          </BaseCol>
        ) : null}
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: none !important;
  min-width: 100px !important;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important!;
  }
  .ant-select-selector::before {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`
const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
  border: 1px solid #f0f1f3 !important;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
