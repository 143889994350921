/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExportOutlined, SearchOutlined } from '@ant-design/icons'
import {
  IParamsGetReport18,
  RangeTimeEnum,
} from 'app/api/report/model/report-18'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React from 'react'
import styled from 'styled-components'

import { BaseButton } from 'app/components/common/BaseButton'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import dayjs, { Dayjs } from 'dayjs'
import { AdvanceFilterLayout } from './layouts'

interface IProps {
  onChange?: (value: IParamsGetReport18) => void
  formData?: IParamsGetReport18

  isLoadingExport?: boolean
  onPressExport?: () => void
  isHavePermissionExport?: boolean
}

export const FilterLayout: React.FC<IProps> = ({
  formData,
  onChange,
  isHavePermissionExport,
  isLoadingExport,
  onPressExport,
}) => {
  const onChangeRangeTime = (rangeTime: RangeTimeEnum) => {
    let startDate: Dayjs = dayjs()
    let endDate: Dayjs = dayjs()

    switch (rangeTime) {
      case RangeTimeEnum.TODAY:
        startDate = dayjs().startOf('day')
        endDate = dayjs().endOf('day')
        break

      case RangeTimeEnum.YESTERDAY:
        startDate = dayjs().subtract(1, 'day').startOf('day')
        endDate = dayjs().subtract(1, 'day').endOf('day')
        break

      case RangeTimeEnum.THIS_WEEK:
        startDate = dayjs().startOf('week')
        endDate = dayjs().endOf('week')
        break

      case RangeTimeEnum.LAST_WEEK:
        startDate = dayjs().subtract(1, 'week').startOf('week')
        endDate = dayjs().subtract(1, 'week').endOf('week')
        break

      case RangeTimeEnum.THIS_MONTH:
        startDate = dayjs().startOf('month')
        endDate = dayjs().endOf('month')
        break

      case RangeTimeEnum.LAST_MONTH:
        startDate = dayjs().subtract(1, 'month').startOf('month')
        endDate = dayjs().subtract(1, 'month').endOf('month')
        break

      case RangeTimeEnum.THIS_YEAR:
        startDate = dayjs().startOf('year')
        endDate = dayjs().endOf('year')
        break

      case RangeTimeEnum.CHOOSE_DAY:
        // Không xử lý sẵn, người dùng sẽ chọn ngày
        onChange?.({
          ...formData,
          rangeTime,
        })
        return
    }

    onChange?.({
      ...formData,
      startDateDayJs: startDate,
      endDateDayJs: endDate,
      rangeTime,
    })
  }

  const onChangeStart = (startDate: Dayjs | null) => {
    onChange &&
      onChange({
        ...formData,
        startDateDayJs: startDate,
        rangeTime: RangeTimeEnum.CHOOSE_DAY,
      })
  }

  const onChangeEnd = (endDate: Dayjs | null) => {
    onChange &&
      onChange({
        ...formData,
        endDateDayJs: endDate,
        rangeTime: RangeTimeEnum.CHOOSE_DAY,
      })
  }

  const disabledEndDate = (current: Dayjs) => {
    if (!formData?.startDateDayJs) {
      return false
    }
    return current && current.isBefore(formData?.startDateDayJs, 'day')
  }

  const disabledStartDate = (current: Dayjs) => {
    if (!formData?.endDateDayJs) {
      return false
    }
    return current && current.isAfter(formData?.endDateDayJs, 'day')
  }

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange?.({ ...formData, keyword: value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[12, 0]} align={'middle'} justify={'space-between'}>
        <BaseCol xl={18}>
          <BaseRow gutter={[12, 0]} align={'middle'}>
            <BaseCol xl={7}>
              <BaseFormItem hiddenLabel>
                <BaseSelectWrapper
                  $prefix={t(R.strings.range_time)}
                  $prefixWidth={125}
                  options={Object?.values(RangeTimeEnum).map(itm => ({
                    label: t(itm),
                    value: itm,
                  }))}
                  allowClear={false}
                  filterSort={() => 0}
                  defaultValue={RangeTimeEnum.CHOOSE_DAY}
                  value={formData?.rangeTime}
                  onChange={value => onChangeRangeTime(value as RangeTimeEnum)}
                />
              </BaseFormItem>
            </BaseCol>
            <BaseCol xl={6}>
              <BaseFormItem hiddenLabel>
                <Picker
                  $prefix={t(R.strings.from_date)}
                  $prefixWidth={65}
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.start_date)}
                  onChange={onChangeStart}
                  showTime={false}
                  disabledDate={disabledStartDate}
                  value={formData?.startDateDayJs}
                />
              </BaseFormItem>
            </BaseCol>
            <BaseCol xl={6}>
              <BaseFormItem hiddenLabel>
                <Picker
                  $prefix={t(R.strings.to_date)}
                  $prefixWidth={70}
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.end_date)}
                  onChange={onChangeEnd}
                  disabledDate={disabledEndDate}
                  value={formData?.endDateDayJs}
                />
              </BaseFormItem>
            </BaseCol>

            <BaseCol style={{ display: 'flex', flex: 1 }}>
              <BaseFormItem hiddenLabel>
                <BaseInput
                  prefix={<SearchOutlined rev={undefined} />}
                  value={formData?.keyword}
                  placeholder={t(R.strings.enter_keyword_search)}
                  onChange={onChangeKeyword}
                  allowClear
                  style={{ minWidth: 100 }}
                />
              </BaseFormItem>
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseRow justify={'end'} gutter={12}>
            <BaseCol>
              <AdvanceFilterLayout
                filter={formData}
                onChangeFilter={onChange}
              />
            </BaseCol>
            {isHavePermissionExport ? (
              <BaseCol>
                <ButtonOpen
                  children={
                    <BaseRow gutter={8}>
                      <BaseCol>
                        <ExportOutlined rev={undefined} />
                      </BaseCol>
                      <BaseCol>{t(R.strings.export_excel)}</BaseCol>
                    </BaseRow>
                  }
                  onClick={onPressExport}
                  loading={isLoadingExport}
                />
              </BaseCol>
            ) : null}
          </BaseRow>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``

const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  padding-left: 4px !important;
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    min-width: ${props => props?.$prefixWidth ?? 105}px;
  }
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector {
    padding-left: 4px !important;
  }
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const ButtonOpen = styled(BaseButton)`
  border-color: #f0f1f3;

  &:hover {
    .anticon {
      color: ${convertedVariables.primaryColor};
      opacity: 0.7;
    }
  }
`
