/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  handleFileDownload,
  requestExportPatientDebtContract,
  requestPatientDebtContract,
} from 'app/api/accounting'
import { IParamsAccounting } from 'app/api/accounting/model'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { NOTIFICATION_TYPE } from 'app/common/constants'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { PaymentMethodTranslateEnum } from 'app/components/tables/BaseTableManagement/FilterDeptTable/type'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { notificationController } from 'app/controllers/notification-controller'
import { formatMoneyWithoutSpace, useDebounce } from 'app/hook'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { changeMessage } from 'app/redux/slices/profileSlice'
import { isEqual } from 'lodash'
import { Pagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handlePermission } from '../common'
import { IGeneralDebtContractTable, initialPagination } from './common-model'
import { ALink } from './styles'
import BaseText from 'app/components/common/BaseText'

interface IMetadata {
  totalPaid?: number
  totalNetAmount?: number
  totalDeposit?: number
}
export const useTableHook = () => {
  const { t } = useTranslation()

  const [filterResponse, setFilterResponse] = useState<
    IParamsAccounting & { paidType?: any }
  >()
  const [exportFile, setExportFile] = useState<string[]>([])

  const [metadata, setMetadata] = useState<IMetadata>({})
  const [pagination, setPagination] = useState(initialPagination)
  const [data, setData] = useState<IGeneralDebtContractTable[]>([])
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()
  const profile = useAppSelector(state => state.profileSlice)
  const notificationMessage = profile?.message
  const dispatch = useAppDispatch()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.ACCOUNTING,
      FunctionPermissionEnum.READ_CUSTOMER_DEBT_MANAGEMENT,
      R.strings.role,
    )
  }, [])

  useEffect(() => {
    if (
      notificationMessage?.type !==
      NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT
    )
      return

    const payload = JSON.parse(notificationMessage?.payload || '')
    const successData = payload?.successData || []
    const errorData = payload?.errorData || []

    if (errorData?.length) {
      downloadNotificationController.downloadErrored({
        duration: 2,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT,
        message: t(R.strings.manage_customer_debt_contract),
        description: t(R.strings.download_errored),
        cancel: t(R.strings.cancel),
      })
    } else if (!isEqual(exportFile, successData)) {
      setExportFile(successData)
      downloadNotificationController.close(
        NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT,
      )
    }
  }, [notificationMessage?.currentTime, notificationMessage?.type])

  const downloadFileForExport = () => {
    if (!exportFile?.length) return

    exportFile.forEach(file =>
      handleFileDownload(
        file,
        t,
        t(R.strings.manage_customer_debt_contract),
        NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT,
      ),
    )

    setExportFile([])
    dispatch(changeMessage({}))
  }

  useEffect(() => {
    downloadFileForExport()

    return downloadFileForExport
  }, [exportFile])

  const handleChangeFilter = (filter: any) => {
    setFilterResponse(filter)
  }

  const paramsDebounce = useDebounce(filterResponse, 500)

  const getData = async () => {
    setIsLoading(true)
    try {
      if (paramsDebounce?.startDate && paramsDebounce?.endDate) {
        const response = await requestPatientDebtContract({
          treatments: paramsDebounce?.treatments ?? '',
          params: {
            page: (pagination?.current || 0) - 1,
            pageSize: pagination?.pageSize,
            keyword: paramsDebounce?.search,
            startDate: paramsDebounce?.startDate,
            endDate: paramsDebounce?.endDate,
            paidTypes: paramsDebounce?.paidType?.join(','),
            location: paramsDebounce?.location,
            paymentMethod: paramsDebounce?.paymentMethod,
          },
        })

        setMetadata(response?.metadata)
        setTotal(response?.metadata?.total)
        setData(
          response?.data?.map((patient, index) => ({
            ...patient,
            key: index,
          })),
        )
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [paramsDebounce, pagination])

  const onClickExport = async () => {
    try {
      if (!paramsDebounce?.startDate && !paramsDebounce?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        await requestExportPatientDebtContract({
          treatments: paramsDebounce?.treatments ?? '',
          params: {
            keyword: paramsDebounce?.search,
            startDate: paramsDebounce?.startDate,
            endDate: paramsDebounce?.endDate,
            paidTypes: paramsDebounce?.paidType?.join(','),
            location: paramsDebounce?.location,
            paymentMethod: paramsDebounce?.paymentMethod,
          },
        })
        await handleNotification()
      }
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }

  const handleNotification = async () => {
    const permissionNotification = await handlePermission()
    if (
      permissionNotification?.state === 'granted' &&
      permissionNotification?.status === true
    ) {
      return downloadNotificationController.waiting({
        duration: 0,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT,
        message: t(R.strings.manage_customer_debt_contract),
        description: t(R.strings.download_waiting),
        cancel: t(R.strings.cancel),
      })
    }

    if (!permissionNotification?.status)
      notificationController.warning({
        message: t(R.strings.download_warning),
        description: t(R.strings.download_warning_description),
      })

    notificationController.success({
      message: t(R.strings.success),
      description: t(R.strings.export_excel_sendmail_message),
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_treatment_date),
        key: 'treatments',
        className: 'date-column',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable
            value={record?.treatments?.treatmentDate}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patientCode',
        className: 'patient-code-column',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable value={record.patientCode} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patientName',
        className: 'patient-name-column',
        fixed: 'left',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patientId,
              )
            }}
            style={{ fontSize: 12 }}
          >
            {record.patientName}
          </ALink>
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_clinic_name),
        key: 'location',
        className: 'clinic-column',
        render: (_, record) => (
          <RenderValueTable value={record.location} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_treatment),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <RenderValueTable value={record?.treatments?.name} type="Base" />
          )
        },
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_treatment_total),
        key: 'treatments',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(
              record?.treatments?.treatmentPrice ?? 0,
            )}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_payment_total),
        key: 'totalNetAmount',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.totalNetAmount ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_deposit_total),
        key: 'deposit',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.deposit ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_paid_total),
        key: 'totalPaid',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.totalPaid ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_remain),
        key: 'manage_customer_debt_refund_total',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(
              record?.allTotal?.totalNetAmount
                ? Number(record?.allTotal?.totalNetAmount) -
                    Number(record?.allTotal?.totalPaid) -
                    Number(record?.allTotal?.totalRefund)
                : 0,
            )}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_refund_total),
        key: 'totalRefund',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.totalRefund ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_payment_type),
        key: 'paidType',
        className: 'payment-type',
        render: (_, record) => (
          <RenderValueTable value={t(String(record?.paidType))} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.payment_method),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable
            value={t(
              PaymentMethodTranslateEnum[
                String(record?.treatments?.paymentMethod)
              ],
            )}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_tip_start_date),
        key: 'dateStartProcedure',
        className: 'date-column',
        render: (_, record) => (
          <RenderValueTable value={record?.dateStartProcedure} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_tip_end_name),
        key: 'endProcedureTreatmentName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.endProcedureTreatmentName}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtContractTable>({
        title: t(R.strings.manage_customer_debt_tip_end_date),
        key: 'dateEndProcedure',
        className: 'date-column',
        render: (_, record) => {
          return (
            record?.endProcedureTreatmentName &&
            record.dateEndProcedure && (
              <RenderValueTable value={record.dateEndProcedure} type="Base" />
            )
          )
        },
      }),
    ]
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    columns,
    tableData: {
      pagination: {
        ...pagination,
        total,
      },
      data,
      loading: isLoading,
    },
    metadata,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
  }
}
