import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'

import { requestExportExcelReport18 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  DefineDiscountTypeEnum,
  IParamsGetReport18,
  IReport018,
  RangeTimeEnum,
} from 'app/api/report/model/report-18'
import { getDetailPatientUrl } from 'app/common/helpers'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { formatPhoneNumber, useDebounce, usePagination } from 'app/hook'
import { useGetFilterReport18 } from 'app/react-query/hook/report'
import { isEqual } from 'lodash'
import { DateUtil, Pagination, formatMoney } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'
import dayjs from 'dayjs'

const formatMoneyWithoutSpace = (value: string | number, symbol = 'đ') => {
  return formatMoney(value, symbol)?.replaceAll(/\s+/g, '')
}

export const useTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const [filter, setFilter] = useState<IParamsGetReport18>({
    rangeTime: RangeTimeEnum.TODAY,
    startDateDayJs: dayjs().startOf('day'),
    endDateDayJs: dayjs().endOf('day'),
  })

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IParamsGetReport18>(filter, 500)

  const { data: dataInfinity, isLoading } = useGetFilterReport18({
    keyword: filterDebounce?.keyword ?? '',
    clinicIds: filterDebounce?.clinicIds,
    campaignIds: filterDebounce?.campaignIds,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    startDate: filterDebounce?.startDateDayJs?.toISOString(),
    endDate: filterDebounce?.endDateDayJs?.toISOString(),
  })

  const handleExportExcel = async () => {
    setIsLoadingExport(true)
    try {
      const response = await requestExportExcelReport18({
        keyword: filterDebounce?.keyword ?? '',
        clinicIds: filterDebounce?.clinicIds,
        campaignIds: filterDebounce?.campaignIds,
        startDate: filterDebounce?.startDateDayJs?.toISOString(),
        endDate: filterDebounce?.endDateDayJs?.toISOString(),
      })
      if (
        isEqual(
          response?.msgcode,
          ReportMessageCode.Report18.successExportExcel,
        )
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      setIsLoadingExport(false)
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_date),
        key: 'apply_date',
        className: 'time-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.date ? DateUtil.formatDDMMYYYHhss(record?.date) : '-'
              }
              fontWeight="medium"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.clinic),
        key: 'clinic',
        className: 'cline-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.clinic?.name}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'manage_customer_patient_code',
        className: 'patient-code-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.patient?.id}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.customer_name),
        key: 'customer_name',
        className: 'patient-name-column',
        render: (_, record: IReport018) => {
          return (
            <RenderValueTable
              value={record?.patient?.name ?? '-'}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record?.patient?._id)}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.phone),
        key: 'phone',
        className: 'phone-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={formatPhoneNumber(record?.patient?.contact ?? '0')}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_service),
        key: 'apply_service',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.treatment?.name ?? '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <BaseText
            children={t(R.strings.discount_amount)}
            textAlign="center"
            fontWeight="semibold"
            opacity="0.7"
          />
        ),
        key: 'discount_amount',
        className: 'discount-amount',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.used ?? '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <BaseText
            children={t(R.strings.discount_value)}
            textAlign="center"
            fontWeight="semibold"
            opacity="0.7"
          />
        ),
        key: 'discount_value',
        className: 'discount-value',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={formatMoneyWithoutSpace(
                record?.voucher?.amount ?? '0',
                '',
              )}
              fontWeight="medium"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <BaseText
            children={t(R.strings.discount_type)}
            textAlign="left"
            fontWeight="semibold"
            opacity="0.7"
          />
        ),
        key: 'discount_type',
        className: 'discount-type',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.voucher?.discountType
                  ? t(
                      DefineDiscountTypeEnum?.[record?.voucher?.discountType]
                        ?.keyI18n,
                    )
                  : '-'
              }
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <BaseText
            children={t(R.strings.retail_price)}
            textAlign="right"
            fontWeight="semibold"
            opacity="0.7"
          />
        ),
        key: 'retail_price',
        className: 'retail-price',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.retailPrice === undefined
                  ? '-'
                  : formatMoneyWithoutSpace(record?.retailPrice)
              }
              fontWeight="medium"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <BaseText
            children={t(R.strings.discounted_total)}
            textAlign="right"
            fontWeight="semibold"
            opacity="0.7"
          />
        ),
        key: 'discountedTotal',
        className: 'discounted-total',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={
                record?.discountedTotal === undefined
                  ? '-'
                  : formatMoneyWithoutSpace(record?.discountedTotal)
              }
              fontWeight="medium"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_code),
        key: 'voucher_code',
        className: 'voucher-code',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.voucher?.code ?? '-'}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.campaign_name),
        key: 'campaign_name',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.campaign?.name}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <BaseText
            children={t(R.strings.priority)}
            textAlign="right"
            fontWeight="semibold"
            opacity="0.7"
          />
        ),
        key: 'priority',
        className: 'priority-column',
        render: (_, record: IReport018) => {
          return (
            <BaseText
              children={record?.campaign?.priority}
              fontWeight="medium"
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IParamsGetReport18) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  const dataFlatten = useMemo(() => {
    return flattenDataList(dataInfinity)
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlatten])

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    handleExportExcel,
    isLoadingExport
  }
}
