import { ModulePermissionEnum, FunctionPermissionEnum } from "app/common/permission-module"
import { ReactNode } from "react"

export enum ShowListType {
  LIST = 1,
  BLOCK,
}

export interface IChildReport {
  id: number
  label?: string
  labelI18nKey?: string
  updateAt: string
  path?: string
  isHideLabel?: boolean
  permission?: FunctionPermissionEnum
  permissions?: FunctionPermissionEnum[]

  indexReport?: string
  indexReportI18nKey?: string
}

export interface IGroupReport {
  id: number
  title?: string
  titleI18nKey?: string
  pages: IChildReport[]
  icon?: ReactNode
}

export interface IProps {
  data: IGroupReport[]
  titleI18nKey: string
  modulePermission?: ModulePermissionEnum
  type?: ShowListType
}
