import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseManagementWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'
import { ImportLayout } from './components'
import { useHookTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTable } from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { useGetHeightTable } from 'app/hook'

export const LimitDiscountManagementContainer = () => {
  const {
    data,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    isLoading,
    pagination,
    onClickDownloadFileExample,
  } = useHookTable()

  return (
    <BaseManagementWrapper>
      <RootWrapper size={8}>
        <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
          <BaseCol xl={16}>
            <FilterLayout formData={filter} onChange={handleChangeFilter} />
          </BaseCol>
          <BaseCol>
            <ImportLayout
              onClickDownloadFileExample={onClickDownloadFileExample}
            />
          </BaseCol>
        </BaseRow>
        <BaseTable
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          scroll={{
            x: true,
            y: data?.length ? useGetHeightTable(200, 15) : undefined,
          }}
        />
      </RootWrapper>
    </BaseManagementWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`
