import { requestGetJobTitleLevelManagement } from 'app/api/jobtitlelevel'
import { JobTitleLevelMessageCode } from 'app/api/jobtitlelevel/constant'
import R from 'app/assets/R'
import { convertArrayStatusFilterToArrayStatusNumber, convertStatusToFilterStatus } from 'app/common/helpers'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { initPagination } from 'app/constant'
import { isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IParamsGetJobTitleLevelManagement,
  IResponseGetJobTitleLevelManagement,
} from '../../../api/jobtitlelevel/model/job-title-level-management'
import { Delete } from './components/Delete'
import { Update } from './components/Update'
import { IGeneralJobTitleLevelManagementTable } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.JOB_TITLE_LEVEL_READ,
      R.strings.job_title_level,
    )
  }, [t])

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initPagination,
      data: [],
    } as IDataBaseTable<IGeneralJobTitleLevelManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetJobTitleLevelManagement = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      if (status !== undefined) {
        params = { ...params, status }
      }

      const response: IResponseGetJobTitleLevelManagement =
        await requestGetJobTitleLevelManagement(params)

      if (
        isEqual(
          response?.msgcode,
          JobTitleLevelMessageCode.JobTitleLevelManagement.successGetData,
        )
      ) {
        const data = response?.data?.rows
        const metadata = response?.data?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = data?.map((item, index) => {
          return {
            key: index + 1,
            ordinalNumber: startIndex + index + 1,
            name: item?.name ?? '-',
            description: item?.description ?? '-',
            code: item?.code ?? '-',
            _id: item?._id ?? '-',
            level: item?.level ?? '-',
            status: convertStatusToFilterStatus(item?.status),
          }
        })

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralJobTitleLevelManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralJobTitleLevelManagementTable>({
        title: t(R.strings.job_title_level_content_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record.name} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleLevelManagementTable>({
        title: t(R.strings.id),
        key: 'code',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record.code} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleLevelManagementTable>({
        title: t(R.strings.job_title_level_content_description),
        key: 'description',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.description} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleLevelManagementTable>({
        title: t(R.strings.value_level),
        key: 'level',
        render: (_, record) => (
          <RenderValueTable value={record?.level ?? '-'} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralJobTitleLevelManagementTable>({
        title: '',
        fixed: 'right',
        key: 'status',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <ActionsDropdown
            actionComponent={
              <>
                <Update id={record?._id} fetchTable={fetchNewData} />
                {!isEqual(record?.status, StatusTableEnum.deleted) && (
                  <Delete
                    id={record?._id}
                    fetchTable={fetchNewData}
                    name={record?.name}
                  />
                )}
              </>
            }
          />
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
  }
}
