import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { ExportExcelButton } from 'app/components/header/components/HeaderPage'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, RootWrapper } from './styles'
import { useGetHeightTable } from 'app/hook'

export const PatientSearchContainer = () => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    isLoadingReport,
    handleExportExcel,
  } = useTable()
  const { isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  return (
    <RootWrapper>
      <ContentWrapper size={4}>
        <FilterLayout
          formData={filter}
          onChange={handleChangeFilter}
          actionComponent={
            isHavePermissionFunction(
              ModulePermissionEnum.DENTAL_TREATMENT_MANAGEMENT,
              FunctionPermissionEnum.EXPORT_PATIENT_SEARCH,
            ) ? (
              <BaseRow justify={'end'} align={'middle'}>
                <BaseCol>
                  <ExportExcelButton onPressExport={handleExportExcel} />
                </BaseCol>
              </BaseRow>
            ) : null
          }
        />
        <BaseTableReport
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={isLoadingReport || isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          scroll={{
            x: true,
            y: data?.length ? useGetHeightTable(245, 15) : undefined,
          }}
        />
      </ContentWrapper>
    </RootWrapper>
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  padding: 8px;
  border-radius: 8px;
`
