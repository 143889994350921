import { SearchOutlined } from '@ant-design/icons'
import { DefineCampaignReferralEnum } from 'app/api/marketing/discount-campaign/constant'
import { CampaignReferralEnum } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_ONLY_MONTH
} from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFilterDataTableReport } from '../../type'

interface IProps {
  filter: IFilterDataTableReport
  onChangeFilter?: (newFilter: IFilterDataTableReport) => void
}

export const FilterLayout = ({ filter, onChangeFilter }: IProps) => {
  const { t } = useTranslation()

  const onChangeKeyword = (keyword?: string) => {
    onChangeFilter?.({
      ...filter,
      keyword,
    })
  }

  const onChangeYear = (year?: string) => {
    onChangeFilter?.({
      ...filter,
      year,
    })
  }

  const onChangeMonth = (month?: string) => {
    onChangeFilter?.({
      ...filter,
      month,
    })
  }

  const onChangeReferralType = (referralTypes?: CampaignReferralEnum[]) => {
    onChangeFilter?.({
      ...filter,
      referralTypes,
    })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 16]} align={'middle'}>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={filter?.keyword}
              placeholder={t(R.strings.enter_name_voucher)}
              onChange={e => onChangeKeyword(e?.target?.value)}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.year)}
              $prefixWidth={40}
              placeholder={t(R.strings.all)}
              picker="year"
              allowClear
              onChange={(_, value) => onChangeYear(value)}
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.month)}
              $prefixWidth={50}
              placeholder={t(R.strings.all)}
              picker="month"
              allowClear
              onChange={(_, value) => onChangeMonth(value)}
              format={FORMAT_ONLY_MONTH}
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              placeholder={t(R.strings.all)}
              filterSort={() => 0}
              options={[
                {
                  label: t(
                    DefineCampaignReferralEnum[CampaignReferralEnum.Normal]
                      .keyI18n,
                  ),
                  value: CampaignReferralEnum.Normal,
                },
                {
                  label: t(
                    DefineCampaignReferralEnum[CampaignReferralEnum.OnlyUser]
                      .keyI18n,
                  ),
                  value: CampaignReferralEnum.OnlyUser,
                },
                {
                  label: t(
                    DefineCampaignReferralEnum[CampaignReferralEnum.Patient]
                      .keyI18n,
                  ),
                  value: CampaignReferralEnum.Patient,
                },
                {
                  label: t(
                    DefineCampaignReferralEnum[CampaignReferralEnum.Staff]
                      .keyI18n,
                  ),
                  value: CampaignReferralEnum.Staff,
                },
              ]}
              $prefix={t(R.strings.referral_target)}
              $prefixWidth={140}
              allowClear
              onChange={value =>
                onChangeReferralType(value as CampaignReferralEnum[])
              }
              mode="multiple"
              maxTagCount={0}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)`
  width: 100%;
  padding: 8px;
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  .width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 80}px;
    align-items: center;
    display: flex;
  }

  .ant-select-selection-placeholder {
    margin-left: ${props => props?.$prefixWidth ?? 80}px;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    min-width: ${props => props?.$prefixWidth ?? 105}px;
  }
`
