import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData } from './type'

export const initValues: IFormData = {
  name: '',
}

enum StatusSkillOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusSkillType = keyof typeof StatusSkillOptionsEnum

export interface IPropsCreateSkill {
  fetchTable: () => void
}

export const Create = (props: IPropsCreateSkill) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    industries,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.skill_content_add}
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            name="name"
            label={t(R.strings.skill_content_name_create)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.skill_content_name_create),
              })}
            />
          </S.FormItem>
          <S.FormItem
            label={t(R.strings.skill_content_industry)}
            name="industry"
            rules={rules.industry}
            required
          >
            <S.SelectBaseStyled
              placeholder={t(R.strings.skill_content_select_industry)}
              suffixIcon={<SvgTableCloseIcon />}
            >
              {industries?.map((industry, index) => {
                return (
                  <Option value={industry._id} key={index}>
                    {industry.name}
                  </Option>
                )
              })}
            </S.SelectBaseStyled>
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.skill_content_add}
      confirmKeyI18n={R.strings.skill_content_add}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
