import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 8px;
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    text-align: center !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: ${convertedVariables.primaryColor};
  }
  .ant-table-thead > tr > th {
    text-align: left !important;
  }
  .ant-table-tbody .ant-table-cell {
    align-content: start;
  }

  .patient-code-column {
    min-width: 80px !important;
  }
`
export const ServiceText = styled(BaseText)`
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: ${convertedVariables.primaryColor};
  opacity: 0.5;
  padding-left: 8px;
`
export const SeeMoreButton = styled.div`
  font-size: ${FONT_SIZE.xxxs} !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: ${convertedVariables.collapseBackgroundColor};
  padding-left: 8px;
  cursor: pointer;
`
export const DayContainer = styled(BaseSpace)``
