import { requestGetAllJobTitleManagement } from 'app/api/jobtitle'
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import { requestCreateUnitManagement } from 'app/api/unit'
import { requestGetUnitGroupAllManagement } from 'app/api/unit-group'
import { UnitGroupMessageCode } from 'app/api/unit-group/constant'
import { IUnitGroup } from 'app/api/unit-group/model/management'
import { UnitMessageCode } from 'app/api/unit/constant'
import { IPayloadCreateUnitManagement } from 'app/api/unit/model/unit-management'
import { requestGetUnitTypeAllManagement } from 'app/api/unittype'
import { UnitTypeMessageCode } from 'app/api/unittype/constant'
import { IUnitType } from 'app/api/unittype/model/unit-type-management'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsCreateUnit, StatusUnitType } from '.'
import { IFormData, IFormRule } from './type'

export const useModalHook = (props: IPropsCreateUnit) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormData>()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<StatusUnitType | undefined>()
  const [unitGroups, setUnitGroups] = useState<IUnitGroup[]>([])
  const [unitTypes, setUnitTypes] = useState<IUnitType[]>([])
  const [jobTitles, setJobTitles] = useState<IJobTitle[]>([])

  const [typeId, setTypeId] = useState<string | undefined>(undefined)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const payload: IPayloadCreateUnitManagement = {
        name: values?.name,
        description: values?.description,
        unitGroupId: values?.unitGroupId,
        unitTypeId: values?.unitTypeId,
        jobTitleIdManagement: values?.jobTitleIdManagement,
        code: values?.code,
        jobTitleIdVice: values?.jobTitleIdVice,
        clinicType: values?.clinicType,
        status: StatusEnum.ACTIVE,
      }

      const response: ResponseType<string> = await requestCreateUnitManagement(
        payload,
      )

      if (
        isEqual(
          response?.msgcode,
          UnitMessageCode.UnitManagement.successPostData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.unit_content_add),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getUnitGroup = async () => {
    try {
      const res: ResponseType<IUnitGroup[]> =
        await requestGetUnitGroupAllManagement()
      if (
        isEqual(
          res?.msgcode,
          UnitGroupMessageCode.UnitGroupManagement.successGetData,
        )
      ) {
        const data: IUnitGroup[] = res?.data || []
        setUnitGroups(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getUnitTypeAll = async () => {
    try {
      const res: ResponseType<IUnitType[]> =
        await requestGetUnitTypeAllManagement()
      if (
        isEqual(
          res?.msgcode,
          UnitTypeMessageCode.UnitTypeManagement.successGetData,
        )
      ) {
        const data: IUnitType[] = res?.data || []
        setUnitTypes(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getAllJobTitle = async () => {
    try {
      const res: ResponseType<IJobTitle[]> =
        await requestGetAllJobTitleManagement()
      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitle[] = res?.data || []
        setJobTitles(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  useEffect(() => {
    visible && getUnitGroup()
    visible && getUnitTypeAll()
    visible && getAllJobTitle()
  }, [visible])

  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_unit_string_id),
          }),
        },
      ],
      unitGroupId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_unit_string_id),
          }),
        },
      ],
      unitTypeId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_unitTypeId),
          }),
        },
      ],
      jobTitleIdManagement: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_job_title_management),
          }),
        },
      ],
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_name),
          }),
        },
      ],
      description: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.unit_field_description),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
      clinicType: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.clinic_type),
          }),
        },
      ],
    }
  }, [t])

  const onChangeClinicType = (typeId?: string) => {
    setTypeId(typeId)
  }

  const type = useMemo(() => {
    return unitTypes?.find(unitType => unitType?._id === typeId)?.name
  }, [typeId, unitTypes])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    setStatus,
    status,
    unitGroups,
    unitTypes,
    jobTitles,
    type,
    onChangeClinicType,
  }
}
