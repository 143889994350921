import R from 'app/assets/R'
import { SvgExcelIcon } from 'app/assets/svg-assets'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ButtonCreate } from 'app/components/common/ButtonCreate'
import { PROFILE_MANAGEMENT_CREATE_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { t } from 'i18next'
import FilterLayout from './components/Filter'
import { useHookTable } from './hook'
import { useGetHeightTable } from 'app/hook'
const ProfileManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    handleExport,
    isLoadingExport,
  } = useHookTable()

  const { isHavePermissionFunction } = useVerifyPermission()

  return (
    <S.BaseManagementWrapper>
      <S.RootWrapper size={8}>
        <BaseRow justify={'space-between'} align={'middle'} gutter={[16, 8]}>
          <BaseCol xl={20}>
            <FilterLayout filter={filter} onChangeFilter={handleChangeFilter} />
          </BaseCol>
          <BaseCol xl={4}>
            <BaseRow justify={'end'} align={'middle'} gutter={8}>
              {isHavePermissionFunction(
                ModulePermissionEnum.HR,
                FunctionPermissionEnum.PROFILE_MANAGEMENT_READ,
              ) ? (
                <BaseCol>
                  <BaseButton
                    loading={isLoadingExport}
                    icon={<SvgExcelIcon />}
                    onClick={handleExport}
                  />
                </BaseCol>
              ) : null}

              <ButtonCreate
                title={t(R.strings.profile_content_add)}
                path={PROFILE_MANAGEMENT_CREATE_PATH}
              />
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.isLoading}
          onChange={handleTableChange}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(195, 30) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default ProfileManagementContainer
