import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { FilterManagement } from './components/FilterManagementTable'
import { useHookTable } from './hook'
import styled from 'styled-components'
import { useGetHeightTable } from 'app/hook'
const PatientOudatedContainer = () => {
  const { tableData, columns, handleTableChange, filter, handleChangeFilter } =
    useHookTable()

  return (
    <S.BaseManagementWrapper>
      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
          />
        </BaseRow>
        <BaseTableManagementWrapper
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          expandable={{
            expandIcon: () => null,
          }}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(215, 0) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default PatientOudatedContainer

const BaseTableManagementWrapper = styled(BaseTableManagement)`
  .date-column {
    min-width: 80px !important;
  }
`
