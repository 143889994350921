import { BaseCol } from 'app/components/common/BaseCol'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  PADDING,
  colors
} from 'parkway-web-common'
import styled from 'styled-components'

export const TotalCountWrapper = styled.div`
  padding: 3px 6px;
  border-radius: ${PADDING.md};
  background-color: ${convertedVariables.statesBlueLightColor};
`

export const RootWrapper = styled(BaseCol)`
  background-color: ${colors.white};
  border-radius: ${BORDER_RADIUS};
  padding: 8px;
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding-left: ${PADDING.md};
  }
`
