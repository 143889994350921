import { IJobTitleManagement } from 'app/api/jobtitle/model/job-title-management'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { useGetJobTitleManagementList } from 'app/react-query/hook/job-title'
import { IResponseQueryList } from 'app/react-query/model/common'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { Dates, FONT_SIZE, FONT_WEIGHT, FORMAT_ONLY_MONTH } from 'parkway-web-common'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IFilterMonthlyRosterReport } from '../../type'

interface IProps {
  onChange?: (value: IFilterMonthlyRosterReport) => void
  formData?: IFilterMonthlyRosterReport
}

export const FilterLayout: React.FC<IProps> = ({ formData, onChange }) => {
  const { data: dataJobTitle, isLoading: isLoadingJobTitle } =
    useGetJobTitleManagementList()
  const { flattenDataList } = usePagination()

  const jobTitleList: IJobTitleManagement[] = useMemo(() => {
    const res: IResponseQueryList<IJobTitleManagement[]> =
      flattenDataList(dataJobTitle)
    return res?.data
  }, [dataJobTitle])

  const onChangeYear = (value: string) => {
    onChange && onChange({ ...formData, year: value })
  }

  const onChangeMonth = (value: string) => {
    onChange && onChange({ ...formData, month: value })
  }

  const onChangeJobTitle = (value: string) => {
    onChange && onChange({ ...formData, jobTitleId: value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.year)}
              picker="year"
              value={
                formData?.year ? Dates.getDate(`${formData?.year}-01-01`) : null
              }
              onChange={(_, value) => onChangeYear(value)}
              allowClear={false}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.month)}
              picker="month"
              value={
                formData?.year && formData?.month
                  ? Dates.getDate(`${formData?.year}-${formData?.month}-01`)
                  : null
              }
              onChange={(_, value) => onChangeMonth(value)}
              allowClear={false}
              $prefixWidth={70}
              format={FORMAT_ONLY_MONTH}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              showSearch
              placeholder={t(R.strings.all_job_title)}
              allowClear
              onChange={value => onChangeJobTitle(value as string)}
              options={jobTitleList?.map(item => ({
                value: item._id,
                label: item.name,
              }))}
              loading={isLoadingJobTitle}
              $prefix={t(R.strings.job_title_roles)}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)`
  padding: 8px;
`
const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 50}px;
  }
`

const BaseSelectWrapper = styled(BaseSelect)<{
    $prefix: string
    $prefixWidth?: number
  }>`
  .width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 90}px;
    align-items: center;
    display: flex;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
