/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import {
  IParamsGetReport07,
  IRefererReport07,
} from 'app/api/report/model/report-07'
import R from 'app/assets/R'
import { RefererEnum, ResponseType, SelectFilterType } from 'parkway-web-common'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { PERCENT_PREFIX } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport07Weekly } from './Month/data'
import { IDataReport07Monthly } from './Year/data'
import { IGeneralReport07Table } from './common-model'
import { IResponseExportExcel } from 'app/api/report/model/common'
import { requestExportExcelRefererByArea } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { notificationController } from 'app/controllers/notification-controller'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  handleExpand,
  fetchLoading,
  currentFilter,
}: {
  expandedRowKeys: number[]
  typeHook: SelectFilterType
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsHookReport
  fetchLoading: (isLoading: boolean) => void
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) return

      let params: IParamsGetReport07 = {
        year: currentFilter?.year,
      }

      switch (typeHook) {
        case 'MONTH':
          if (!currentFilter?.month) return
          params = {
            ...params,
            month: currentFilter?.month,
          }
          break
        case 'YEAR':
          params = {
            ...params,
          }
          break
      }

      fetchLoading(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelRefererByArea(params)

      if (
        res?.msgcode === ReportMessageCode.Report07.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport07Weekly | IDataReport07Monthly

    const onCellArea = (record: TypeColumn): IOnCell => {
      const expandedLength = expandedRowKeys.length
      if (record?.isTotalTable && expandedLength) return { colSpan: 2 }
      if (record?.isTotalTable && !expandedLength) return { colSpan: 1 }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children && expandedRowKeys?.length) {
        return { rowSpan: 1, colSpan: 2 }
      }
      if (!isExpanded && record?.children) return { rowSpan: 1 }

      const childLength = record.children?.length
      return {
        rowSpan: childLength ? childLength + 1 : 0,
      }
    }

    const onCellClinic = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable) return { colSpan: 0 }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children && !!expandedRowKeys?.length) {
        return { colSpan: 0 }
      }

      return {}
    }

    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable({
        title: t(R.strings.area),
        key: 'area',
        onCell: onCellArea,
        fixed: 'left',
        render: (_, record: TypeColumn) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          if (record?.isTotalTable) {
            return <ExpandedTableValue value={record?.area} isTotalTable />
          }

          if (!record?.children) return null

          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.area}
            />
          )
        },
      }),
    ]

    if (expandedRowKeys?.length) {
      options = options?.concat([
        convertColumnTable({
          title: t(R.strings.clinic),
          key: 'clinic',
          fixed: 'left',
          onCell: onCellClinic,
          className: 'clinic-column',
          render: (_, record: TypeColumn) => {
            return (
              <RenderValueTableReport
                record={record}
                valueString={record?.clinic}
                expandedRowKeys={expandedRowKeys}
                justify="start"
              />
            )
          },
        }),
      ])
    }

    return [
      ...options,
      {
        title: t(R.strings.group_customer),
        children: [
          {
            title: t(R.strings.digital_ad),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.revenue),
                key: 'digitalRevenue',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.digitalRevenue ?? 0,
                    secondLevelValue: record?.digitalRevenueTotal ?? 0,
                    expandedRowKeys,
                    isFillBlueInTotal: true,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.percentage_of_total_revenue),
                key: 'digitalPercentRevenuePerAll',
                className: 'money-column',
                render: (_, record) =>
                  RenderValueTableReport({
                    record,
                    firstLevelValue: record?.digitalPercentRevenuePerAll ?? 0,
                    secondLevelValue: record?.digitalPercentRevenuePerAllTotal ?? 0,
                    prefix: PERCENT_PREFIX,
                    expandedRowKeys,
                    isFillBlueInTotal: true,
                  }),
              }),
            ],
          },
          {
            title: t(R.strings.telesales_cared),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.revenue),
                key: 'telesalesCaredRevenue',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.telesalesCaredRevenue ?? 0,
                    secondLevelValue: record?.telesalesCaredRevenueTotal ?? 0,
                    isFillBlueInTotal: true,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.percentage_of_total_revenue),
                key: 'telesalesCaredPercentRevenuePerAll',
                className: 'money-column',
                render: (_, record) =>
                  RenderValueTableReport({
                    record,
                    firstLevelValue: record?.telesalesCaredPercentRevenuePerAll ?? 0,
                    secondLevelValue:
                      record?.telesalesCaredPercentRevenuePerAllTotal ?? 0,
                    prefix: PERCENT_PREFIX,
                    isFillBlueInTotal: true,
                    expandedRowKeys,
                  }),
              }),
            ],
          },
          {
            title: t(R.strings.old_customer),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.revenue),
                key: 'oldCustomerRevenue',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.oldCustomerRevenue ?? 0,
                    secondLevelValue: record?.oldCustomerRevenueTotal ?? 0,
                    isFillBlueInTotal: true,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.percentage_of_total_revenue),
                key: 'oldCustomerPercentRevenuePerAll',
                className: 'money-column',
                render: (_, record) =>
                  RenderValueTableReport({
                    record,
                    firstLevelValue: record?.oldCustomerPercentRevenuePerAll ?? 0,
                    secondLevelValue:
                      record?.oldCustomerPercentRevenuePerAllTotal ?? 0,
                    prefix: PERCENT_PREFIX,
                    isFillBlueInTotal: true,
                    expandedRowKeys,
                  }),
              }),
            ],
          },
          {
            title: t(R.strings.other_customer),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.revenue),
                key: 'otherCustomerRevenue',
                className: 'money-column',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.otherCustomerRevenue ?? 0,
                    secondLevelValue: record?.otherCustomerRevenueTotal ?? 0,
                    isFillBlueInTotal: true,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.percentage_of_total_revenue),
                key: 'otherCustomerPercentRevenuePerAll',
                className: 'money-column',
                render: (_, record) =>
                  RenderValueTableReport({
                    record,
                    firstLevelValue: record?.otherCustomerPercentRevenuePerAll ?? 0,
                    secondLevelValue:
                      record?.otherCustomerPercentRevenuePerAllTotal ?? 0,
                    prefix: PERCENT_PREFIX,
                    isFillBlueInTotal: true,
                    expandedRowKeys,
                  }),
              }),
            ],
          },
        ],
      },
      convertColumnTable<TypeColumn>({
        title: t(R.strings.total_revenue_projected),
        key: 'totalRevenueActual',
        className: 'money-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.totalRevenueActual ?? 0,
            secondLevelValue: record?.totalRevenueActualTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.total_revenue_planned),
        key: 'totalRevenuePlan',
        className: 'money-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.totalRevenuePlan ?? 0,
            secondLevelValue: record?.totalRevenuePlanTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.percent_of_target_complete),
        key: 'percentTargetComplete',
        className: 'money-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.percentTargetComplete ?? 0,
            secondLevelValue: record?.percentTargetCompleteTotal ?? 0,
            prefix: PERCENT_PREFIX,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
    ]
  }, [t, expandedRowKeys, typeHook])

  return {
    columns,
    exportExcel,
  }
}

export const convertPercent = (value?: number, total?: number) => {
  if (value && total) {
    const num = (value / total) * 100
    const res = Math.round(num * 100) / 100
    return res
  }
  return 0
}

export const findInReferer = ({
  referers,
  total,
  type,
}: {
  referers?: IRefererReport07[]
  total?: number
  type?: 'DIGITAL' | 'OTHER' | 'OLD_PATIENT_RETURN' | 'TELESALES'
}) => {
  switch (type) {
    case 'DIGITAL':
      const servicesDigital = referers?.filter(
        item =>
          isEqual(item.referer, RefererEnum.FB) ||
          isEqual(item.referer, RefererEnum.GROUP_FB),
      )
      const actualDigital = servicesDigital
        ?.map(item => item?.projected ?? 0)
        ?.reduce((acc, currentValue) => {
          return acc + currentValue
        }, 0)
      return {
        percent: convertPercent(actualDigital, total),
        actual: actualDigital ?? 0,
      }
    case 'TELESALES':
      const servicesSales = referers?.filter(item =>
        isEqual(item.referer, RefererEnum.CSKH),
      )
      const actualSales = servicesSales
        ?.map(item => item?.projected ?? 0)
        ?.reduce((acc, currentValue) => {
          return acc + currentValue
        }, 0)
      return {
        percent: convertPercent(actualSales, total),
        actual: actualSales ?? 0,
      }
    case 'OLD_PATIENT_RETURN':
      const servicesPatientReturn = referers?.filter(item =>
        isEqual(item.referer, RefererEnum.OLD_CUSTOMER),
      )
      const actualPatientReturn = servicesPatientReturn
        ?.map(item => item?.projected ?? 0)
        ?.reduce((acc, currentValue) => {
          return acc + currentValue
        }, 0)
      return {
        percent: convertPercent(actualPatientReturn, total),
        actual: actualPatientReturn ?? 0,
      }
    case 'OTHER':
      const others = referers?.filter(
        item =>
          isEqual(item.referer, RefererEnum.KOL) ||
          isEqual(item.referer, RefererEnum.OTHER) ||
          isEqual(item.referer, RefererEnum.UNKOWN) ||
          isEqual(item.referer, RefererEnum.CLINIC_STAFF) ||
          isEqual(item.referer, RefererEnum.WALK_IN) ||
          isEqual(item.referer, RefererEnum.BOM),
      )
      const actualOthers = others
        ?.map(item => item?.projected ?? 0)
        ?.reduce((acc, currentValue) => {
          return acc + currentValue
        }, 0)

      return {
        percent: convertPercent(actualOthers, total),
        actual: actualOthers ?? 0,
      }
    default:
      return {
        percent: 0,
        actual: 0,
      }
  }
}

export const convertApiDataToTableData = ({
  referers,
  total,
  type = 'normal',
}: {
  referers?: IRefererReport07[]
  total?: number
  type?: 'total' | 'normal'
}): IGeneralReport07Table<IDataReport07Monthly> => {
  const digital = findInReferer({ referers, total, type: 'DIGITAL' })
  const cskh = findInReferer({ referers, total, type: 'TELESALES' })
  const other = findInReferer({ referers, total, type: 'OTHER' })
  const old = findInReferer({ referers, total, type: 'OLD_PATIENT_RETURN' })

  switch (type) {
    case 'normal':
      return {
        digitalRevenue: digital?.actual,
        digitalPercentRevenuePerAll: digital.percent,

        otherCustomerRevenue: other.actual,
        otherCustomerPercentRevenuePerAll: other.percent,

        telesalesCaredRevenue: cskh.actual,
        telesalesCaredPercentRevenuePerAll: cskh.percent,

        oldCustomerRevenue: old.actual,
        oldCustomerPercentRevenuePerAll: old.percent,
      }
    case 'total':
      return {
        digitalRevenueTotal: digital?.actual,
        digitalPercentRevenuePerAllTotal: digital.percent,

        otherCustomerRevenueTotal: other.actual,
        otherCustomerPercentRevenuePerAllTotal: other.percent,

        telesalesCaredRevenueTotal: cskh.actual,
        telesalesCaredPercentRevenuePerAllTotal: cskh.percent,

        oldCustomerRevenueTotal: old.actual,
        oldCustomerPercentRevenuePerAllTotal: old.percent,
      }
  }
}

export const sumTableData = ({
  total,
  newData,
}: {
  total: IGeneralReport07Table<IDataReport07Monthly>
  newData: IGeneralReport07Table<IDataReport07Monthly>
}): IGeneralReport07Table<IDataReport07Monthly> => {
  const digitalRevenue =
    (total?.digitalRevenue ?? 0) + (newData.digitalRevenueTotal ?? 0)

  const telesalesCaredRevenue =
    (total?.telesalesCaredRevenue ?? 0) +
    (newData.telesalesCaredRevenueTotal ?? 0)

  const otherCustomerRevenue =
    (total?.otherCustomerRevenue ?? 0) +
    (newData.otherCustomerRevenueTotal ?? 0)
  const oldCustomerRevenue =
    (total?.oldCustomerRevenue ?? 0) + (newData.oldCustomerRevenueTotal ?? 0)

  const totalRevenueActual =
    (total?.totalRevenueActual ?? 0) + (newData.totalRevenueActualTotal ?? 0)
  const totalRevenuePlan =
    (total?.totalRevenuePlan ?? 0) + (newData.totalRevenuePlanTotal ?? 0)
  const percentTargetComplete = convertPercent(
    totalRevenueActual,
    totalRevenuePlan,
  )

  const digitalPercentRevenuePerAll = convertPercent(
    digitalRevenue,
    totalRevenueActual,
  )

  const telesalesCaredPercentRevenuePerAll = convertPercent(
    telesalesCaredRevenue,
    totalRevenueActual,
  )

  const otherCustomerPercentRevenuePerAll = convertPercent(
    otherCustomerRevenue,
    totalRevenueActual,
  )

  const oldCustomerPercentRevenuePerAll = convertPercent(
    oldCustomerRevenue,
    totalRevenueActual,
  )

  return {
    ...total,
    digitalRevenue,
    digitalPercentRevenuePerAll,
    telesalesCaredRevenue,
    telesalesCaredPercentRevenuePerAll,
    otherCustomerRevenue,
    otherCustomerPercentRevenuePerAll,
    oldCustomerRevenue,
    oldCustomerPercentRevenuePerAll,
    totalRevenueActual,
    totalRevenuePlan,
    percentTargetComplete,
  }
}
