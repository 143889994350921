/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collapse } from 'antd'
import {
  requestDailyInvoice,
  requestExportDailyInvoice,
  requestSyncDailyInvoice,
} from 'app/api/accounting'
import { ApiDailyInvoiceResponse, DailyInvoice } from 'app/api/accounting/model'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { NOTIFICATION_TYPE } from 'app/common/constants'
import { PaymentMethodTranslateEnum } from 'app/components/tables/BaseTableManagement/FilterDeptTable/type'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { notificationController } from 'app/controllers/notification-controller'
import { get } from 'lodash'
import { moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handlePermission } from '../../common'
import { IGeneralInvoiceTable } from '../common-model'
import { ALink } from '../styles'
import BaseText from 'app/components/common/BaseText'
import { formatMoneyWithoutSpace } from 'app/hook'
interface IMetadata {
  totalNetAmount?: number
  totalPaid?: number
  totalCash?: number
  totalCard?: number
  totalTransfer?: number
  totalApp?: number
  totalRemain?: number
  totalRefund?: number
  totalPayooLink?: number
  totalFundiin?: number
}

export const useTablePaymentTypeHook = ({ params }: { params: any }) => {
  const [metadata, setMetadata] = useState<IMetadata>({})
  const [data, setData] = useState<DailyInvoice[]>([])
  const [pagination, setPagination] = useState(initPagination)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const getData = async () => {
    try {
      setIsLoading(true)
      if (params?.startDate && params?.endDate) {
        const response: ApiDailyInvoiceResponse = await requestDailyInvoice({
          treatments: params?.treatments,
          params: {
            page: (pagination?.current || 0) - 1,
            pageSize: pagination?.pageSize,
            keyword: params?.search,
            startDate: params?.startDate,
            endDate: params?.endDate,
            paidTypes: params?.paidType?.join(','),
            location: params?.location,
            paymentMethod: params?.paymentMethod,
          },
        })

        setMetadata(response?.metadata)
        setTotal(response?.metadata?.total)
        setData(
          response?.data?.map((patient, index) => ({
            ...patient,
            key: index,
          })),
        )
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(timer)
  }, [params])

  const { t } = useTranslation()

  const onClickExport = async params => {
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        await requestExportDailyInvoice({
          treatments: params?.treatments,
          params: {
            keyword: params?.search,
            startDate: params?.startDate,
            endDate: params?.endDate,
            paidTypes: params?.paidType?.join(','),
            location: params?.location,
            paymentMethod: params?.paymentMethod,
          },
        })

        await handleNotification()
      }
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }

  const handleNotification = async () => {
    const permissionNotification = await handlePermission()
    if (
      permissionNotification?.state === 'granted' &&
      permissionNotification?.status === true
    ) {
      return downloadNotificationController.waiting({
        duration: 0,
        key: NOTIFICATION_TYPE.EXPORT_DAILY_INVOICE,
        message: t(R.strings.daily_invoice),
        description: t(R.strings.download_waiting),
        cancel: t(R.strings.cancel),
      })
    }

    if (!permissionNotification?.status)
      notificationController.warning({
        message: t(R.strings.download_warning),
        description: t(R.strings.download_warning_description),
      })

    notificationController.success({
      message: t(R.strings.success),
      description: t(R.strings.export_excel_sendmail_message),
    })
  }

  const onClickSync = async params => {
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else if (
        moment(params?.endDate).diff(moment(params?.startDate), 'days') > 0
      ) {
        notificationController.error({
          message: t(R.strings.sync_for_up_to_one_day),
        })
      } else {
        await requestSyncDailyInvoice({
          startDate: params?.startDate,
          endDate: params?.endDate,
          syncType: 'date',
        })

        setPagination(initPagination), await getData()

        // await getData(initialPagination, params)
        notificationController.success({
          message: t(R.strings.success),
          description: t(R.strings.sync_successfully),
        })
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_debt_treatment_date),
        key: 'date',
        className: 'date-time-column',
        fixed: 'left',
        render: (_, record) => (
          <RenderValueTable value={record?.date} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patient',
        className: 'patient-name-column',
        fixed: 'left',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patient?._id,
              )
            }}
            style={{ fontSize: 12 }}
          >
            {record?.patient?.name}
          </ALink>
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patient',
        className: 'patient-code-column',
        render: (_, record) => (
          <RenderValueTable value={record?.patient?.id} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.manage_customer_clinic_name),
        key: 'location',
        className: 'clinic-column',
        render: (_, record) => (
          <RenderValueTable value={record?.location?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.search_appointment_treatment),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          const treatments = record?.treatments?.sort(
            (a, b) => Number(b?.unitPrice) - Number(a?.unitPrice),
          )

          const treatmentNames = treatments?.map(treatment => treatment?.name)

          const items = [
            {
              key: '1',
              label: (
                <BaseText
                  children={get(treatmentNames, '[0]', '')}
                  fontWeight="medium"
                />
              ),
              children: treatmentNames
                ?.filter((_, index) => index !== 0)
                ?.map(treatment => (
                  <>
                    <RenderValueTable
                      value={treatment}
                      type="Base"
                      styleText={{ fontSize: 12 }}
                    />
                    <hr />
                  </>
                )),
            },
          ]

          return <Collapse items={items} className="Collapse-treatment" />
        },
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.treatment_fee),
        key: 'netAmount',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.netAmount ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.paid),
        key: 'payment',

        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.paid ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.refund),
        key: 'refund',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.refund?.amount ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.debt),
        key: 'patient',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.patient?.remain ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_treatment),
        key: 'paymentInfo',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.paymentInfo?.paymentFor?.name}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_treatment_amount),
        key: 'paymentInfo',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(
              record?.paymentInfo?.paymentFor?.amount ?? 0,
            )}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_receivable),
        key: 'paymentInfo',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.paymentInfo?.receivable?.name}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.daily_invoice_payment_for_receivable_amount),
        key: 'paymentInfo',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(
              record?.paymentInfo?.receivable?.amount ?? 0,
            )}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),

      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.cash),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.cash ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.card),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.card ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.card_type),
        key: 'payment',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.payment?.card ? record?.payment?.cardType : ''}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.transfer),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.transfer ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.transfer_type),
        key: 'payment',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => (
          <RenderValueTable
            value={
              record?.payment?.transfer ? record?.payment?.transferType : ''
            }
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.app_momo),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.app ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.payoo_link),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.payooLink ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.fundiin),
        key: 'payment',
        className: 'money-column',
        render: (_, record) => (
          <BaseText
            children={formatMoneyWithoutSpace(record?.payment?.fundiin ?? 0)}
            fontWeight="medium"
            opacity="0.7"
            textAlign="right"
          />
        ),
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.installment_direct_payment),
        key: 'payment',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          const paidType =
            record?.payment?.isInstallment === undefined
              ? ''
              : record?.payment?.isInstallment
              ? t(R.strings.installment)
              : t(R.strings.direct_payment)

          return <RenderValueTable value={paidType} type="Main" />
        },
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.payment_method),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          const treatmentMethod = record?.treatments?.find(
            treatment => treatment?.paymentMethod,
          )
          const paymentMethod = get(treatmentMethod, 'paymentMethod', '')

          return (
            <RenderValueTable
              value={t(PaymentMethodTranslateEnum[String(paymentMethod)])}
              type="Base"
            />
          )
        },
      }),
      convertColumnTable<IGeneralInvoiceTable>({
        title: t(R.strings.doctor),
        key: 'doctor',
        className: 'patient-name-column',
        render: (_, record) => (
          <RenderValueTable value={record?.doctor?.name} type="Base" />
        ),
      }),
    ]
  }, [t])

  const _onClickExport = async params => {
    await onClickExport(params)
  }

  return {
    columns,
    tableData: {
      data,
      pagination: {
        ...pagination,
        total,
      },
      loading: isLoading,
    },
    metadata,
    onClickExport: _onClickExport,
    onClickSync,
    setPagination,
  }
}
