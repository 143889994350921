/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestCreateAccountManagement } from 'app/api/human'
import { HumanMessageCode } from 'app/api/human/constant'
import { IPayloadCreateAccountManagement } from 'app/api/human/model/account-management'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormData } from '../type'
import { IPropsCreateAccount } from './type'

export const useModalHook = (props: IPropsCreateAccount) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const payload: IPayloadCreateAccountManagement = {
        code: values?.code,
        firstname: values?.firstName,
        lastname: values?.lastName,
        mobile: values?.phone,
        email: values?.email,
        password: values?.password,
        status: StatusEnum.ACTIVE,
      }

      const response: ResponseType<string> =
        await requestCreateAccountManagement(payload)

      if (
        isEqual(
          response?.msgcode,
          HumanMessageCode.AccountManagement.successPostData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.add_account),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
  }
}
