/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import { IParamsGetReport18 } from 'app/api/report/model/report-18'
import R from 'app/assets/R'
import { SvgDownArrowIcon, SvgUpArrowIcon } from 'app/assets/svg-assets'
import { StatusEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import { usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { SearchCampaign } from '../components/SearchCampaign'

interface IProps {
  onChangeFilter?: (value: IParamsGetReport18) => void
  filter?: IParamsGetReport18
}

export const AdvanceFilterLayout: React.FC<IProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [open, setOpen] = useState(false)
  const [filterMore, setFilterMore] = useState<IParamsGetReport18 | undefined>(
    filter,
  )

  const [keywordSearchArea, setKeywordSearchArea] = useState('')
  const { flattenDataList } = usePagination()

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)
    return res?.map(item => ({
      ...item,
      childs: item?.childs
        ?.filter(child => !!child?._oldId)
        ?.map(item => ({
          ...item,
          _id: item?._oldId ?? item?._id,
        })),
    }))
  }, [areaApi])

  const filterTreeNode = (value?: string, treeNode?: any) => {
    return removeVietnameseTones(treeNode?.title)
      .toLowerCase()
      .includes(removeVietnameseTones(value).toLowerCase())
  }

  const onChangeClinic = (ids: string[]) => {
    setFilterMore(prev => ({ ...prev, clinicIds: ids as any }))
  }

  const onChangeCampaign = (ids: string[]) => {
    setFilterMore(prev => ({ ...prev, campaignIds: ids as any }))
  }

  const onHide = () => {
    setOpen(false)
  }

  const onPressApply = () => {
    onHide()
    onChangeFilter?.({
      ...filter,
      campaignIds: filterMore?.campaignIds,
      clinicIds: filterMore?.clinicIds,
      keyword: filterMore?.keyword,
    })
  }

  const onPressReset = () => {
    setFilterMore({})
    onChangeFilter?.({
      ...filter,
      campaignIds: [],
      clinicIds: [],
      keyword: '',
    })
  }

  return (
    <BasePopoverWrapper
      trigger={'click'}
      placement="bottomRight"
      content={
        <BaseSpace
          split={<BaseDivider style={{ marginBottom: 0, marginTop: 0 }} />}
          size={12}
        >
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontSize="xs"
                fontWeight="semibold"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                icon={<CloseOutlined rev={undefined} />}
                onClick={onHide}
                type="text"
              />
            </BaseCol>
          </BaseRow>

          <BaseForm>
            <BaseSpace style={{ width: 450 }} size={4}>
              {/* // begin orthodontic date */}
              <BaseSpace size={0}>
                <BaseText
                  children={t(R.strings.clinic)}
                  fontWeight="semibold"
                  colorText="main2Color"
                />
                <BaseFormItem hiddenLabel>
                  <BaseTreeSelectWrapper
                    placeholder={t(R.strings.all)}
                    loading={isLoadingArea}
                    width={'100%'}
                    treeData={transformDataSelectTree(areaList ?? [], {
                      title: 'name',
                      value: '_id',
                      children: 'childs',
                    })}
                    value={filterMore?.clinicIds}
                    multiple
                    treeCheckable={true}
                    onSearch={keyword => setKeywordSearchArea?.(keyword)}
                    filterTreeNode={filterTreeNode}
                    searchValue={keywordSearchArea}
                    maxTagCount={0}
                    onChange={ids => onChangeClinic?.(ids as string[])}
                    allowClear
                    style={{
                      minWidth: 220,
                    }}
                  />
                </BaseFormItem>
              </BaseSpace>
              <BaseSpace size={0}>
                <BaseText
                  children={t(R.strings.campaign)}
                  fontWeight="semibold"
                  colorText="main2Color"
                />
                <BaseFormItem hiddenLabel>
                  <SearchCampaign
                    filterMore={filterMore}
                    onChangeCampaign={onChangeCampaign}
                  />
                </BaseFormItem>
              </BaseSpace>
            </BaseSpace>
          </BaseForm>

          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseButton
                onClick={onPressReset}
                children={t(R.strings.report_24_content_reset)}
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                onClick={onPressApply}
                type="primary"
                children={t(R.strings.report_24_content_apply)}
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
      open={open}
      onOpenChange={setOpen}
    >
      <ButtonOpen
        children={
          <BaseRow align={'middle'} justify={'center'} gutter={[8, 4]}>
            <BaseCol>
              <FilterOutlined rev={undefined} />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
            </BaseCol>
            {!open ? (
              <SvgDownArrowIcon style={{ width: 20, height: 20 }} />
            ) : (
              <SvgUpArrowIcon style={{ width: 20, height: 20 }} />
            )}
          </BaseRow>
        }
      />
    </BasePopoverWrapper>
  )
}

const ButtonOpen = styled(BaseButton)`
  border-color: #f0f1f3;

  &:hover {
    .anticon {
      color: ${convertedVariables.primaryColor};
      opacity: 0.7;
    }
  }
`

const BasePopoverWrapper = styled(BasePopover)``

const BaseTreeSelectWrapper = styled(BaseTreeSelect)`
  width: 100%;
  .ant-select-selector::before {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
