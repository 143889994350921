/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportExcelReport11 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseExportExcel } from 'app/api/report/model/common'
import {
  IParamsGetReport11,
  ITreatmentReport11,
} from 'app/api/report/model/report-11'
import R from 'app/assets/R'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { TreatmentGroupEnum } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { convertToTitleCase } from 'app/hook'
import { isEqual } from 'lodash'
import { ResponseType, SelectFilterType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport11Area } from './Area/data'
import { IDataReport11FromThenUtilNow } from './FromThenUtilNow/data'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  handleExpand,
  currentFilter,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  typeHook: SelectFilterType
  handleExpand: (isExpanded: boolean, key?: number) => void
  fetchLoading?: (isLoading: boolean) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      let params: IParamsGetReport11 = {}

      switch (typeHook) {
        case 'FROM_THEN_UTIL_NOW': {
          params = {
            type: 'full',
          } as IParamsGetReport11
          break
        }
        case 'AREA': {
          params = {
            year: currentFilter?.year,
            type: 'area',
          } as IParamsGetReport11
          break
        }
      }

      fetchLoading?.(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelReport11(params)

      if (
        res?.msgcode === ReportMessageCode.Report11.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading?.(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport11Area | IDataReport11FromThenUtilNow
    const onCellArea = (record: TypeColumn): IOnCell => {
      if (record?.isTotalTable && expandedRowKeys.length) return { colSpan: 2 }

      if (record?.isTotalTable && !expandedRowKeys.length) return { colSpan: 1 }

      const isExpanded = expandedRowKeys.includes(record?.key ?? 0)
      if (!isExpanded && record?.children && expandedRowKeys?.length) {
        return { rowSpan: 1, colSpan: 2 }
      }

      if (!isExpanded && record?.children) {
        return { rowSpan: 1 }
      }

      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }

      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    const onCellClinic = (record): IOnCell => {
      if (record?.isTotalTable) {
        return { colSpan: 0 }
      }
      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children && !!expandedRowKeys?.length) {
        return { colSpan: 0 }
      }
      return {}
    }

    let options: IBaseColumnReport<TypeColumn> = []
    switch (typeHook) {
      case 'AREA':
        options = options?.concat([
          convertColumnTable<TypeColumn>({
            title: t(R.strings.area),
            key: 'area',
            onCell: onCellArea,
            fixed: 'left',
            render: (_, record) => {
              const isExpanded = expandedRowKeys.includes(record.key ?? 0)
              if (record?.isTotalTable) {
                return <ExpandedTableValue value={record?.area} isTotalTable />
              }

              if (!record?.children) return null

              const _handleExpand = () => {
                return handleExpand(!isExpanded, record?.key)
              }

              return (
                <ExpandedTableValue
                  isExpanded={isExpanded}
                  handleExpand={_handleExpand}
                  value={record?.area}
                />
              )
            },
          }),
        ])
        if (expandedRowKeys?.length) {
          options = options?.concat([
            convertColumnTable<TypeColumn>({
              title: t(R.strings.full_name),
              key: 'fullName',
              fixed: 'left',
              className: 'patient-name-column',
              onCell: onCellClinic,
              render: (_, record) => {
                return (
                  <RenderValueTableReport
                    record={record}
                    valueString={record?.fullName}
                    expandedRowKeys={expandedRowKeys}
                    justify="start"
                  />
                )
              },
            }),
          ])
        }
        break
      case 'FROM_THEN_UTIL_NOW':
        options = options?.concat([
          convertColumnTable<TypeColumn>({
            title: t(R.strings.full_name),
            key: 'fullName',
            className: 'patient-name-column',
            render: (_, record) => {
              return (
                <RenderValueTableReport
                  record={record}
                  valueString={record?.fullName}
                  justify="start"
                />
              )
            },
          }),
        ])
        break
    }

    const treatmentGroupNamesList: string[] = Object.values(TreatmentGroupEnum)
    const products = treatmentGroupNamesList?.map((product, index) => {
      return convertColumnTable<TypeColumn>({
        title: convertToTitleCase(product ?? ''),
        key: `a${index + 1}Implant` as any,
        className: 'money-column',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.[`a${index + 1}Implant`] ?? 0,
            secondLevelValue: record?.[`a${index + 1}ImplantTotal`] ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          })
        },
      })
    })

    return [
      ...options,
      convertColumnTable<TypeColumn>({
        title: t(R.strings.annual_expenditure_amount),
        key: 'annualExpenditureAmount',
        className: 'money-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(
            record?.parentKey ?? record?.key ?? 0,
          )
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.annualExpenditureAmount ?? 0,
            secondLevelValue: record?.annualExpenditureAmountTotal ?? 0,
            colorOverwrite: isExpanded ? 'var(--other-blue-color)' : undefined,
            expandedRowKeys,
          })
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.number_of_purchases),
        key: 'numberOfPurchase',
        className: 'money-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.numberOfPurchase ?? 0,
            secondLevelValue: record?.numberOfPurchaseTotal ?? 0,
            expandedRowKeys,
            prefix: '',
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.average_order_value),
        key: 'averageOrderValue',
        className: 'money-column',
        render: (_, record) => {
          return RenderValueTableReport({
            record,
            firstLevelValue: record?.averageOrderValue ?? 0,
            secondLevelValue: record?.averageOrderValueTotal ?? 0,
            expandedRowKeys,
          })
        },
      }),
      {
        title: t(R.strings.number_of_service_uses),
        children: products,
      },
    ]
  }, [t, expandedRowKeys, typeHook])

  return {
    columns,
    exportExcel,
  }
}

export function getTreatmentTotalPricesByGroupNames(
  data: ITreatmentReport11[],
  groupNames: TreatmentGroupEnum[],
): Record<TreatmentGroupEnum, number> {
  const result: Record<TreatmentGroupEnum, number> = {} as Record<
    TreatmentGroupEnum,
    number
  >

  groupNames.forEach(groupName => {
    const item = data.find(entry => {
      return isEqual(entry._id, groupName)
    })

    result[groupName] = item?.payments ?? 0
  })

  return result
}

export const getValueByTreatment = ({
  treatments,
  type,
}: {
  treatments?: ITreatmentReport11[]
  type?: 'normal' | 'total'
}) => {
  const allGroupNames: TreatmentGroupEnum[] = Object.values(TreatmentGroupEnum)
  const totalPrices = getTreatmentTotalPricesByGroupNames(
    treatments ?? [],
    allGroupNames,
  )
  // console.log({ totalPrices, treatments, allGroupNames})

  if (type === 'normal') {
    let result: IDataReport11Area = {}
    allGroupNames?.map((product, index) => {
      result = {
        ...result,
        [`a${index + 1}Implant`]: totalPrices?.[product],
      }
    })
    return result
  }

  let result: IDataReport11Area = {}
  allGroupNames?.map((product, index) => {
    result = {
      ...result,
      [`a${index + 1}ImplantTotal`]: totalPrices?.[product],
    }
  })
  return result
}
