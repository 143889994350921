import { SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { t } from 'i18next'
import React from 'react'
import styled from 'styled-components'
import { IFilterDataTableReport } from '../../type'

interface IProps {
  onChange?: (value: IFilterDataTableReport) => void
  formData?: IFilterDataTableReport
}

export const FilterLayout: React.FC<IProps> = ({ formData, onChange }) => {
  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ ...formData, keyword: e.target.value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={24}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_keyword_search)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)`
  padding: 8px;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
