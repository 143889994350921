import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const HeadWrapper = styled(BaseRow)`
  .ant-form-item {
    margin-bottom: 0px !important;
  }
`
export const TitleTable = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`
export const Picker = styled(BaseDatePicker)`
  min-width: 160px;
`

export const TextFilter = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--primary-color);
`

export const SelectBaseStyled = styled(BaseSelect)`
  min-width: 160px;
  .ant-select-arrow {
    top: 40%;
  }
`

export const BaseColWrapper = styled(BaseCol)<{$isDisable?: boolean}>`
opacity: ${({ $isDisable }) => ($isDisable ? 0 : 1)};
`
