import { useGetHeightTable } from 'app/hook'
import { TablePatients } from './TablePatients'
import { IDataTable } from './common-hook'
import { useTableHook } from './hook'
import { TableWrapper } from './styles'

export const DoctorWithOrthodonticAppointment = () => {
  const {
    columns,
    expandedRowKeys,
    handleExpand,
    tableData,
    handleTableChange,
  } = useTableHook()
  return (
    <TableWrapper
      columns={columns}
      dataSource={tableData.data}
      loading={tableData.loading}
      pagination={tableData.pagination}
      expandable={{
        expandedRowKeys,
        onExpand: handleExpand,
        expandIcon: () => null,
        showExpandColumn: false,
        expandedRowRender: (record: IDataTable) => (
          <TablePatients className="table-patient" id={`${record.id}`} />
        ),
      }}
      onChange={handleTableChange}
      scroll={{
        x: true,
        y: tableData.data?.length ? useGetHeightTable(283, 0) : undefined,
      }}
    />
  )
}
