import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import Export from './components/Export'
import { Filter } from './components/Filter'
import TotalBox from './components/TotalBox'
import { useTableHook } from './hook'
import './styles.css'
import { useGetHeightTable } from 'app/hook'
import styled from 'styled-components'

const DebtContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    metadata,
  } = useTableHook()

  const { t } = useTranslation()
  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={0}>
        <Filter
          handleChangeFilter={handleChangeFilter}
          filterData={filterResponse}
          actionComponent={
            <BaseRow justify={'end'}>
              <Export
                onPressExport={() => onClickExport({ ...filterResponse })}
              />
            </BaseRow>
          }
        />
        <BaseRow>
          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.treatment_fee)}
              total={Number(metadata?.totalNetAmount)}
            />
          </BaseCol>

          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.paid)}
              total={Number(metadata?.totalPaid)}
            />
          </BaseCol>

          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.refund)}
              total={Number(metadata?.totalRefund)}
            />
          </BaseCol>

          <BaseCol span={6}>
            <TotalBox
              title={t(R.strings.debt)}
              total={Number(metadata?.totalRemain)}
            />
          </BaseCol>
        </BaseRow>
        <BaseTableReportWrapper
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={tableData.pagination}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(320, 0) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default DebtContainer

const BaseTableReportWrapper = styled(BaseTableReport)`
  .date-time-column {
    min-width: 123px !important;
  }
  .patient-code-column {
    min-width: 100px !important;
  }
`
