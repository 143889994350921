import R from 'app/assets/R'
import 'dayjs/locale/vi'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LoadingOutlined,
  PlusOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { ButtonAddNew, ButtonPrint } from './styles'
import { PageSearchAppointmentContext } from 'app/context/PageSearchAppointmentContext'
import { AppointmentContext } from 'app/context/AppointmentContext'
import { initialPagination } from '../common-model'
import moment from 'moment'
import { scheduleStatusSearchAppointment } from 'app/api/appointment/constant'
import { IUser } from 'app/model/user.model'
import { useAppSelector } from 'app/redux/hooks'
import { DATE } from 'app/common/constants'
import { APPOINTMENT_VIEW_TYPE } from 'app/common/enum'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'

const HeaderPageAction: React.FC<{
  setOpenAppointment: React.FC
  pagination
}> = ({ setOpenAppointment, pagination }) => {
  const [printLoading, setPrintLoading] = useState(false)
  const { t } = useTranslation()
  const { filter } = useContext(PageSearchAppointmentContext)
  const { PrintAppointment } = useContext(AppointmentContext)
  const user: IUser | null = useAppSelector(state => state.user.user)

  const userId = useMemo(() => {
    return user?._id || user?.id
  }, [user])

  const handlePrint = async () => {
    setPrintLoading(true)
    const pageSize =
      (pagination['pageSize'] || 0) > 10
        ? pagination?.pageSize
        : initialPagination.pageSize
    const print = await PrintAppointment?.({
      ...filter,
      page: (pagination?.current || 0) - 1,
      pageSize,
      startDate: moment(filter?.startDate || moment().startOf('day'))?.format(
        DATE.YYYY_MM_DD_HH_MM,
      ),
      endDate: moment(filter?.endDate || moment().endOf('day')).format(
        DATE.YYYY_MM_DD_HH_MM,
      ),
      view: APPOINTMENT_VIEW_TYPE.LIST,
      scheduleStatus: scheduleStatusSearchAppointment,
      printById: userId,
    })
    setPrintLoading(false)
    if (print?.data?.s3Url)
      window.open(print?.data?.s3Url, '_blank', 'noopener,noreferrer')
  }

  return (
    <BaseRow gutter={8} align={'middle'}>
      <BaseCol>
        <ButtonPrint onClick={handlePrint}>
          <PrinterOutlined rev={undefined} />{' '}
          {t(R.strings.search_appointment_in)}
          {printLoading && <LoadingOutlined rev={undefined} />}
        </ButtonPrint>
      </BaseCol>
      <BaseCol>
        <ButtonAddNew onClick={() => setOpenAppointment(true)}>
          <PlusOutlined rev={undefined} /> {t(R.strings.add_schedule)}
        </ButtonAddNew>
      </BaseCol>
    </BaseRow>
  )
}

export default HeaderPageAction
