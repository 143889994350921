/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportExcelReport01 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseExportExcel } from 'app/api/report/model/common'
import {
  IGeneralResponseReport01,
  IParamsGetReport01,
} from 'app/api/report/model/report-01'
import R from 'app/assets/R'
import {
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  NoWrapper,
  RevenueTableWrapper,
  TitleTable,
} from 'app/components/tables/BaseTableReport/styles'
import {
  IBaseColumnReport,
  IDataBaseTable,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import {
  PERCENT_PREFIX,
  PeriodType,
  ResponseType,
  convertPercent,
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataReport01Monthly } from './Monthly/data'
import { IDataReport01Weekly } from './Weekly/data'

export const useCommonHook = ({
  expandedRowKeys,
  typeHook,
  tableData,
  currentFilter,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  typeHook: PeriodType
  tableData: IDataBaseTable<IDataReport01Monthly>
  handleExpand: (isExpanded: boolean, key?: number) => void
  fetchLoading?: (value: boolean) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const exportExcel = async () => {
    try {
      if (!currentFilter?.year) return

      let params: IParamsGetReport01 = {
        year: currentFilter?.year,
        month: currentFilter?.month,
      }

      switch (typeHook) {
        case 'week':
          if (!currentFilter?.month) return

          if (!currentFilter?.startDate || !currentFilter?.endDate) return
          params = {
            ...params,
            startdate: currentFilter?.startDate,
            enddate: currentFilter?.endDate,
          }
          break
        case 'month':
          // do nothing
          break
      }

      fetchLoading?.(true)
      const res: ResponseType<IResponseExportExcel> =
        await requestExportExcelReport01(params)

      if (
        res?.msgcode === ReportMessageCode.Report01.successGetData &&
        res?.data?.success
      ) {
        window.open(res?.data?.data ?? '')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      }
    } catch (error: any) {
      // console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading?.(false)
    }
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataReport01Weekly | IDataReport01Monthly

    const onCellService = (record: TypeColumn): IOnCell => {
      if (record?.isTotalGroupTable) {
        let colSpan = 2
        if (expandedRowKeys?.length > tableData.data.length) {
          colSpan += 1
        }
        return { colSpan, rowSpan: 1 }
      }

      if (record.isNotExpanded && record?.children) {
        let rowSpan = (record?.children?.length ?? 0) + 1
        record?.children?.forEach(item => {
          const isExpanded = expandedRowKeys.includes(item?.key ?? 0)
          if (isExpanded && item?.children) {
            rowSpan += item?.children?.length
          }
        })
        return { rowSpan }
      }
      return { rowSpan: 0 }
    }

    const renderService = (_, record: TypeColumn) => {
      if (record?.isTotalGroupTable) {
        return (
          <RevenueTableWrapper
            justify={record?.isTotalTable ? 'start' : 'center'}
            $isBold
            className={`${
              record?.isTotalGroupTable ? 'is-total-group-table' : ''
            } ${record?.isTotalTable ? 'is-total-table' : ''}`}
          >
            <span>{record?.service}</span>
          </RevenueTableWrapper>
        )
      }
      return (
        <NoWrapper>
          <TitleTable>{record?.service}</TitleTable>
        </NoWrapper>
      )
    }

    const onCellArea = (record: TypeColumn) => {
      if (record?.isTotalGroupTable) {
        return { colSpan: 0 }
      }

      if (record?.isNotExpanded) {
        const isExpanded = expandedRowKeys.includes(record?.childrenKey ?? 0)
        if (!isExpanded && record?.children) {
          if (expandedRowKeys?.length > tableData.data.length) {
            return { rowSpan: 1, colSpan: 2 }
          }
          return { rowSpan: 1 }
        }

        if (isExpanded && !record?.childOfFirstChild?.length) {
          return { rowSpan: 1 }
        }

        return {
          rowSpan: record.childOfFirstChild?.length
            ? record.childOfFirstChild?.length + 1
            : 0,
        }
      }

      const isExpanded = expandedRowKeys.includes(record.key ?? 0)
      if (!isExpanded && record?.children) {
        if (expandedRowKeys?.length > tableData.data.length) {
          return { rowSpan: 1, colSpan: 2 }
        }
        return { rowSpan: 1 }
      }
      if (isExpanded && !record?.children?.length) {
        return { rowSpan: 1 }
      }
      return {
        rowSpan: record.children?.length ? record.children?.length + 1 : 0,
      }
    }

    // const onCellClinic = (record: TypeColumn) => {
    //   if (record?.isTotalGroupTable) {
    //     return { colSpan: 0 }
    //   }

    //   if (record?.isNotExpanded) {
    //     const isExpanded = expandedRowKeys.includes(record?.childrenKey ?? 0)

    //     if (
    //       !isExpanded &&
    //       record?.children &&
    //       expandedRowKeys?.length > tableData.data.length
    //     ) {
    //       return { colSpan: 0 }
    //     }
    //   }

    //   const isExpanded = expandedRowKeys.includes(record?.key ?? 0)

    //   if (
    //     !isExpanded &&
    //     record?.children &&
    //     expandedRowKeys?.length > tableData.data.length
    //   ) {
    //     return { colSpan: 0 }
    //   }

    //   return {}
    // }

    const options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable({
        title: t(R.strings.service),
        key: 'service',
        fixed: 'left',
        onCell: onCellService,
        render: renderService,
      }),
      convertColumnTable({
        title: t(R.strings.area),
        key: 'area',
        onCell: onCellArea,
        fixed: 'left',
        render: (_, record) => {
          return (
            <RenderValueTableReport
              record={record}
              valueString={record?.area ?? ''}
            />
          )
        },
      }),
    ]

    return [
      ...options,
      convertColumnTable<TypeColumn>({
        title: t(R.strings.budget),
        key: 'budget',
        className: 'money-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.budget ?? 0,
            secondLevelValue: record?.budgetTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.spent_actual),
        key: 'spentActual',
        className: 'money-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.spentActual ?? 0,
            secondLevelValue: record?.spentActualTotal ?? 0,
            isFillBlueInTotal: true,
            expandedRowKeys,
          }),
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.percent_budget_using),
        key: 'percentBudgetUsing',
        className: 'money-column',
        render: (_, record) =>
          RenderValueTableReport({
            record,
            firstLevelValue: record?.percentBudgetUsing ?? 0,
            secondLevelValue: record?.percentBudgetUsingTotal ?? 0,
            expandedRowKeys,
            prefix: PERCENT_PREFIX,
          }),
      }),
      {
        title: t(R.strings.number_q_lead),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.planing),
            key: 'qTechPlaning',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.qTechPlaning ?? 0,
                secondLevelValue: record?.qTechPlaningTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.actuals),
            key: 'qTechActual',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.qTechActual ?? 0,
                secondLevelValue: record?.qTechActualTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.percent_achievement),
            key: 'qTechAchievement',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.qTechAchievement ?? 0,
                secondLevelValue: record?.qTechAchievementTotal ?? 0,
                expandedRowKeys,
                prefix: PERCENT_PREFIX,
              }),
          }),
        ],
      },
      {
        title: t(R.strings.cpql),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.planing),
            key: 'cpqlPlaning',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.cpqlPlaning ?? 0,
                secondLevelValue: record?.cpqlPlaningTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.actuals),
            key: 'cpqlActual',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.cpqlActual ?? 0,
                secondLevelValue: record?.cpqlActualTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
        ],
      },
      {
        title: t(R.strings.number_checkin),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.planing),
            key: 'numberCheckingPlaning',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.numberCheckingPlaning ?? 0,
                secondLevelValue: record?.numberCheckingPlaningTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.actuals),
            key: 'numberCheckingActual',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.numberCheckingActual ?? 0,
                secondLevelValue: record?.numberCheckingActualTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.percent_achievement),
            key: 'numberCheckingAchievement',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.numberCheckingAchievement ?? 0,
                secondLevelValue: record?.numberCheckingAchievementTotal ?? 0,
                prefix: PERCENT_PREFIX,
                expandedRowKeys,
              }),
          }),
        ],
      },
      {
        title: t(R.strings.num_kpi),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.planing),
            key: 'kpiPlan',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.kpiPlan ?? 0,
                secondLevelValue: record?.kpiPlanTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.actuals),
            key: 'kpiActual',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.kpiActual ?? 0,
                secondLevelValue: record?.kpiActualTotal ?? 0,
                expandedRowKeys,
                prefix: '',
              }),
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.percent_achievement),
            key: 'kpiAchievement',
            className: 'money-column',
            render: (_, record) =>
              RenderValueTableReport({
                record,
                firstLevelValue: record?.kpiAchievement ?? 0,
                secondLevelValue: record?.kpiAchievementTotal ?? 0,
                prefix: PERCENT_PREFIX,
                expandedRowKeys,
              }),
          }),
        ],
      },
    ]
  }, [t, expandedRowKeys, typeHook])

  const data = (() => {
    return tableData.data.map(item => {
      const isExpanded = expandedRowKeys.includes(item?.childrenKey ?? 0)
      const oldChild = item?.children?.filter(itm => !itm?.parentKey) ?? []
      if (isExpanded) {
        const newChild = item?.childOfFirstChild ?? []
        return {
          ...item,
          children: [...newChild, ...oldChild],
        }
      }
      return {
        ...item,
        children: oldChild,
      }
    })
  })()

  return {
    columns,
    data,
    exportExcel,
  }
}

export const convertGeneralReport01Value = (
  item: IGeneralResponseReport01,
  type: 'total' | 'normal',
): IDataReport01Monthly => {
  switch (type) {
    case 'normal':
      return {
        budget: item?.budgetPlanTotal ?? 0,
        spentActual: item?.budgetActualTotal ?? 0,
        percentBudgetUsing: convertPercent(
          item?.budgetActualTotal,
          item?.budgetPlanTotal,
        ),

        qTechPlaning: item?.qLeadPlanTotal ?? 0,
        qTechActual: item?.qLeadActualTotal ?? 0,
        qTechAchievement: convertPercent(
          item?.qLeadActualTotal,
          item?.qLeadPlanTotal,
        ),

        cpqlActual: item?.cpqlActualTotal ?? 0,
        cpqlPlaning: item?.cpqlPlanTotal ?? 0,

        numberCheckingActual: item?.checkInActualTotal ?? 0,
        numberCheckingPlaning: item?.checkInPlanTotal ?? 0,
        numberCheckingAchievement: convertPercent(
          item?.checkInActualTotal,
          item?.checkInPlanTotal,
        ),

        numberCheckingPrice: item?.priceTotal,

        kpiActual: item?.kpiActualTotal ?? 0,
        kpiPlan: item?.kpiPlanTotal ?? 0,
        kpiAchievement: convertPercent(
          item?.kpiActualTotal,
          item?.kpiPlanTotal,
        ),
      }
    case 'total':
      return {
        budgetTotal: item?.budgetPlanTotal ?? 0,
        spentActualTotal: item?.budgetActualTotal ?? 0,
        percentBudgetUsingTotal: convertPercent(
          item?.budgetActualTotal,
          item?.budgetPlanTotal,
        ),

        qTechPlaningTotal: item?.qLeadPlanTotal ?? 0,
        qTechActualTotal: item?.qLeadActualTotal ?? 0,
        qTechAchievementTotal: convertPercent(
          item?.qLeadActualTotal,
          item?.qLeadPlanTotal,
        ),

        cpqlActualTotal: item?.cpqlActualTotal ?? 0,
        cpqlPlaningTotal: item?.cpqlPlanTotal ?? 0,

        numberCheckingActualTotal: item?.checkInActualTotal ?? 0,
        numberCheckingPlaningTotal: item?.checkInPlanTotal ?? 0,
        numberCheckingAchievementTotal: convertPercent(
          item?.checkInActualTotal,
          item?.checkInPlanTotal,
        ),

        numberCheckingPriceTotal: item?.priceTotal,

        kpiActualTotal: item?.kpiActualTotal ?? 0,
        kpiPlanTotal: item?.kpiPlanTotal ?? 0,
        kpiAchievementTotal: convertPercent(
          item?.kpiActualTotal,
          item?.kpiPlanTotal,
        ),
      }
  }
}

export const sumDataReport01 = (
  total: IDataReport01Monthly,
  newData: IDataReport01Monthly,
) => {
  const budget = (total?.budget ?? 0) + (newData?.budget ?? 0)
  const spentActual = (total?.spentActual ?? 0) + (newData?.spentActual ?? 0)
  const percentBudgetUsing = convertPercent(spentActual, budget)

  const qTechPlaning = (total?.qTechPlaning ?? 0) + (newData?.qTechPlaning ?? 0)
  const qTechActual = (total?.qTechActual ?? 0) + (newData?.qTechActual ?? 0)
  const qTechAchievement = convertPercent(qTechActual, qTechPlaning)

  const cpqlPlaning = (total?.cpqlPlaning ?? 0) + (newData?.cpqlPlaning ?? 0)
  const cpqlActual = (total?.cpqlActual ?? 0) + (newData?.cpqlActual ?? 0)

  const numberCheckingActual =
    (total?.numberCheckingActual ?? 0) + (newData?.numberCheckingActual ?? 0)
  const numberCheckingPlaning =
    (total?.numberCheckingPlaning ?? 0) + (newData?.numberCheckingPlaning ?? 0)
  const numberCheckingAchievement = convertPercent(
    numberCheckingActual,
    numberCheckingPlaning,
  )

  const numberCheckingPrice =
    (total?.numberCheckingPrice ?? 0) + (newData?.numberCheckingPrice ?? 0)

  const kpiActual = (total?.kpiActual ?? 0) + (newData?.kpiActual ?? 0)
  const kpiPlan = (total?.kpiPlan ?? 0) + (newData?.kpiPlan ?? 0)
  const kpiAchievement = convertPercent(kpiActual, kpiPlan)

  return {
    budget,
    spentActual,
    percentBudgetUsing,

    qTechPlaning,
    qTechActual,
    qTechAchievement,

    cpqlActual,
    cpqlPlaning,

    numberCheckingActual,
    numberCheckingPlaning,
    numberCheckingAchievement,

    numberCheckingPrice,

    kpiActual,
    kpiPlan,
    kpiAchievement,
  }
}
