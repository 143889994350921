import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTableReport } from '../../styles'
import { useTable } from './hook'
import { FilterLayout } from './Filter'
import { IFilterGetExportBill } from 'app/api/accounting/model/export-bill'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { useGetHeightTable } from 'app/hook'
interface IProps {
  reloadTable: number
  handleExportExcel: (
    body: IFilterGetExportBill,
    setLoading: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>
  exportPermission: boolean
}
export const Cancel = (props: IProps) => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    setIsLoading,
    isLoadingData,
    handleTableChange,
    pagination,
  } = useTable({ reloadTable: props?.reloadTable })

  return (
    <BaseSpace size={4}>
      <FilterLayout
        formData={filter}
        onChange={handleChangeFilter}
        setIsLoading={setIsLoading}
        exportPermission={props?.exportPermission}
        handleExportExcel={props?.handleExportExcel}
      />

      <BaseTableReportWrapper
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={isLoading || isLoadingData}
        onChange={handleTableChange}
        rowClassName="row-overwrite-style"
        scroll={{
          x: true,
          y: data?.length ? useGetHeightTable(335, 0) : undefined,
        }}
      />
    </BaseSpace>
  )
}

const BaseTableReportWrapper = styled(BaseTableReport)`
  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`
