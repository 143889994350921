import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { FilterLayout } from 'app/components/tables/BaseTableManagement/FilterManagementTableV2'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import styled from 'styled-components'
import { Create } from './components/Create'
import { useHookTable } from './hook'
import { useGetHeightTable } from 'app/hook'
const UnitManagementContainer = () => {
  const {
    tableData,
    columns,
    fetchNewData,
    filter,
    handleChangeFilter,
    handleExpand,
    expandedRowKeys,
    handleTableChange,
  } = useHookTable()

  return (
    <S.BaseManagementWrapper>
      <S.RootWrapper size={8}>
        <BaseRow align={'middle'} gutter={16} justify={'space-between'}>
          <BaseCol>
            <FilterLayout
              handleChangeFilter={handleChangeFilter}
              filterData={filter}
            />
          </BaseCol>
          <BaseCol>
            <BaseRow gutter={16}>
              {/* <BaseCol>

              </BaseCol> */}
              <BaseCol>
                <Create fetchTable={fetchNewData} />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <TableWrapper
          columns={columns}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record => {
              return !!record?.children?.length
            },
          }}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          onChange={handleTableChange}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(195, 30) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default UnitManagementContainer

const TableWrapper = styled(BaseTableManagement)`
  .code-column {
    min-width: 100px !important;
  }

  .type-column {
    min-width: 100px !important;
  }

  .group-column {
    min-width: 100px !important;
  }

  .action-column {
    min-width: 25px !important;
  }
`
