import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, RootWrapper } from './styles'
import { useGetHeightTable } from 'app/hook'
import { BaseRow } from 'app/components/common/BaseRow'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import BaseText from 'app/components/common/BaseText'
import { BaseCol } from 'app/components/common/BaseCol'

export const Report021Container = () => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    handleExportExcel,
    totalItem,
    isLoadingExport,
  } = useTable()
  const { t } = useTranslation()
  const { isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  return (
    <RootWrapper>
      <SectionBase style={{ padding: 8, paddingBottom: 0 }}>
        <BaseSpace>
          <FilterLayout
            totalItem={totalItem}
            formData={filter}
            onChange={handleChangeFilter}
            isExport={isHavePermissionFunction(
              ModulePermissionEnum.REPORT,
              FunctionPermissionEnum.REPORT_EXPORT_REPORT_21,
            )}
            onPressExport={handleExportExcel}
            isLoadingExport={isLoadingExport}
          />

          <BaseTableReport
            columns={columns}
            dataSource={data}
            pagination={{
              ...pagination,
              showTotal: () => (
                <BaseRow gutter={6} align={'middle'}>
                  <BaseCol>
                    <BaseText
                      children={t(R.strings.found)}
                      fontWeight="regular"
                    />
                  </BaseCol>
                  <BaseCol>
                    <BaseText children={`${totalItem}`} fontWeight="semibold" />
                  </BaseCol>
                  <BaseCol>
                    <BaseText
                      children={t(R.strings._result)}
                      fontWeight="regular"
                    />
                  </BaseCol>
                </BaseRow>
              ),
            }}
            loading={isLoading}
            onChange={handleTableChange}
            rowClassName="row-overwrite-style"
            scroll={{
              x: true,
              y: data?.length ? useGetHeightTable(265, 30) : undefined,
            }}
          />
        </BaseSpace>
      </SectionBase>
    </RootWrapper>
  )
}
