import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { FilterDept } from 'app/components/tables/BaseTableManagement/FilterDeptTable'
import { InvoicePaidTypeTableEnum } from 'app/components/tables/BaseTableManagement/FilterDeptTable/type'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import ExportAndSync from './components/ExportAndSync'
import TotalBox from './components/TotalBox'
import { useTableHook } from './hook'
import './styles.css'
import styled from 'styled-components'

const DailyInvoiceContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    metadata,
    onClickSync,
  } = useTableHook()

  const { t } = useTranslation()
  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={4}>
        <FilterDept
          handleChangeFilter={handleChangeFilter}
          filterData={filterResponse}
          listPaidType={Object.values(InvoicePaidTypeTableEnum)}
          actionComponent={
            <BaseCol>
              <BaseRow gutter={8}>
                <ExportAndSync
                  onPressExport={() => onClickExport({ ...filterResponse })}
                  onPressSync={() => onClickSync({ ...filterResponse })}
                />
              </BaseRow>
            </BaseCol>
          }
        />
        <BaseRow>
          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.treatment_fee)}
              total={Number(metadata?.totalNetAmount)}
            />
          </BaseCol>

          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.paid)}
              total={Number(metadata?.totalPaid)}
            />
          </BaseCol>

          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.refund)}
              total={Number(metadata?.totalRefund)}
            />
          </BaseCol>

          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.debt)}
              total={Number(metadata?.totalRemain)}
            />
          </BaseCol>
          <BaseCol span={4}>
            <TotalBox
              title={t(R.strings.cash)}
              total={Number(metadata?.totalCash)}
            />
          </BaseCol>
        </BaseRow>
        <BaseRow justify={'space-between'}>
          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.card)}
              total={Number(metadata?.totalCard)}
            />
          </BaseCol>

          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.transfer)}
              total={Number(metadata?.totalTransfer)}
            />
          </BaseCol>

          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.app_momo)}
              total={Number(metadata?.totalApp)}
            />
          </BaseCol>

          <BaseCol span={5}>
            <TotalBox
              title={t(R.strings.payoo_link)}
              total={Number(metadata?.totalPayooLink)}
            />
          </BaseCol>

          <BaseCol span={4}>
            <TotalBox
              title={t(R.strings.fundiin)}
              total={Number(metadata?.totalFundiin)}
            />
          </BaseCol>
        </BaseRow>
        <BaseTableReportWrapper
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={tableData.pagination}
          scroll={{ x: true, y: tableData?.data?.length ? 480 : undefined }}
          // rowSelection={rowSelection}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default DailyInvoiceContainer

const BaseTableReportWrapper = styled(BaseTableReport)`
  .date-time-column {
    min-width: 125px !important;
  }
  .patient-code-column {
    min-width: 100px !important;
  }
`
