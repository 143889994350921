import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { FilterLayout } from 'app/components/tables/BaseTableManagement/FilterManagementTableV2'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { useGetHeightTable } from 'app/hook'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateAccount } from './actions/CreateAccount'
import { useHookTable } from './hook'
const AccountManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    handleChangeFilter,
    fetchNewData,
    filter,
    profile,
  } = useHookTable()

  const { t } = useTranslation()

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.ACCOUNT_READ,
      R.strings.account_management,
    )
  }, [t])

  return (
    <S.BaseManagementWrapper>
      <S.RootWrapper size={8}>
        <BaseRow justify={'space-between'} align={'middle'} gutter={[16, 8]}>
          <BaseCol>
            <FilterLayout
              filterData={filter}
              handleChangeFilter={handleChangeFilter}
            />
          </BaseCol>
          <BaseCol>
            <BaseRow justify={'end'} align={'middle'} gutter={8}>
              <CreateAccount fetchTable={fetchNewData} profile={profile} />
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(195, 30) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default AccountManagementContainer
