import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import { requestCreateSkillManagement } from 'app/api/skill'
import { SkillMessageCode } from 'app/api/skill/constant'
import {
  IIndustry,
  IPayloadCreateSkillManagement,
} from 'app/api/skill/model/management'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPropsCreateSkill, StatusSkillType } from '.'
import { IFormData, IFormRule } from './type'

export const useModalHook = (props: IPropsCreateSkill) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<StatusSkillType | undefined>()
  const [industries, setIndustry] = useState<IIndustry[]>()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const payload: IPayloadCreateSkillManagement = {
        name: values?.name,
        industry: values?.industry,
        status: StatusEnum.ACTIVE,
      }

      const response: ResponseType<string> = await requestCreateSkillManagement(
        payload,
      )

      if (
        isEqual(
          response?.msgcode,
          SkillMessageCode.SkillManagement.successPostData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        props?.fetchTable?.()
      } else {
        notificationController.error({
          message: t(R.strings.skill_content_add),
          description: response?.message,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getAllIndustry = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()

      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustry(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    visible && getAllIndustry()
  }, [visible])

  const rules: IFormRule = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_name),
          }),
        },
      ],
      industry: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.skill_field_industry),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])

  return {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    setStatus,
    status,
    industries,
  }
}
