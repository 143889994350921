/* eslint-disable @typescript-eslint/no-explicit-any */
import { DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import {
  SvgArrowTrendingDownIcon,
  SvgArrowTrendingUpIcon,
  SvgTableCloseIcon,
  SvgTableOpenIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { formatMoneyWithoutSpace, useMounted } from 'app/hook'
import { isNaN, isNumber } from 'lodash'
import { Pagination } from 'parkway-web-common'
import React, { useCallback, useEffect, useState } from 'react'
import { initialPagination } from '../common-table/constant'
import * as S from './styles'
import { IColumnTable, IConvertColumnTable, IDataBaseTable } from './type'
interface Props {
  getTreeTableData: (
    pagination: Pagination,
    params?: any,
  ) => Promise<IDataBaseTable<any>>
  isExpandedWhenStart?: boolean
}

export function useBaseTableReportHook({
  getTreeTableData,
  isExpandedWhenStart,
}: Props) {
  const { isMounted } = useMounted()
  const [tableData, setTableData] = useState<{
    data: any[]
    pagination: Pagination
    loading: boolean
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  })

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
  const [expandedAreaKeys, setExpandedAreaKeys] = useState<number[]>([])

  const handleResetExpand = () => {
    setExpandedAreaKeys([])
    setExpandedRowKeys([])
  }

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const handleExpandRowList = (expanded: boolean, newListKey?: number[]) => {
    if (!newListKey?.length) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, ...newListKey])
    } else {
      setExpandedRowKeys(
        expandedRowKeys.filter(key => !newListKey.find(itm => itm === key)),
      )
    }
  }

  const handleExpandArea = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedAreaKeys([...expandedAreaKeys, newKey])
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedAreaKeys(expandedAreaKeys.filter(key => key !== newKey))
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const handleExpandAreaList = (expanded: boolean, newListKey?: number[]) => {
    if (!newListKey?.length) return
    if (expanded) {
      setExpandedAreaKeys([...expandedAreaKeys, ...newListKey])
      setExpandedRowKeys([...expandedRowKeys, ...newListKey])
    } else {
      setExpandedAreaKeys(
        expandedAreaKeys.filter(key => !newListKey.find(itm => itm === key)),
      )
      setExpandedRowKeys(
        expandedRowKeys.filter(key => !newListKey.find(itm => itm === key)),
      )
    }
  }

  const fetchLoading = (isLoading: boolean) => {
    setTableData(prev => ({
      ...prev,
      loading: isLoading,
    }))
  }

  const fetch = useCallback(
    (pagination?: Pagination, params?: any) => {
      setTableData(tableData => {
        return { ...tableData, loading: true }
      })
      getTreeTableData(pagination ?? initialPagination, params).then(res => {
        if (isMounted.current) {
          setTableData({
            data: res?.data,
            pagination: res?.pagination,
            loading: false,
          })

          if (isExpandedWhenStart) {
            setExpandedRowKeys(res?.data?.map(item => item?.key ?? 0))
          }
        }
      })
    },
    [isMounted],
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      fetch(initialPagination)
    }, 500)

    return () => clearTimeout(timer)
  }, [fetch])

  const handleTableChange = (pagination?: Pagination, params?: any) => {
    fetch(pagination, params)
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTableData(previous => {
        const activeIndex = previous?.data.findIndex(i => i.key === active.id)
        const overIndex = previous?.data.findIndex(i => i.key === over?.id)
        return {
          ...previous,
          data: arrayMove(previous?.data, activeIndex, overIndex),
        }
      })
    }
  }

  return {
    handleExpand,
    tableData,
    handleTableChange,
    expandedRowKeys,
    expandedAreaKeys,
    handleExpandArea,
    handleResetExpand,
    fetchLoading,
    fetch,
    onDragEnd,
    handleExpandAreaList,
    handleExpandRowList,
  }
}

export function RenderValueTableReport({
  prefix,
  record,
  secondLevelValue = 0,
  firstLevelValue = 0,
  thirdLevelValue = 0,
  expandedRowKeys,
  isFillBlueInTotal,
  valueString,
  isBold = true,
  colorOverwrite,
  decimal = 2,
  justify,
  isShowPercent,
  percent,
  percentTotal,
  isShowValueUpAndDown = true,
  classNameWhenExpanded,
  percentTotalOfTotal,
  expandedAreaKeys,
  isShowDevelopment = false,
}: {
  prefix?: string
  record: any
  secondLevelValue?: number
  firstLevelValue?: number
  expandedRowKeys?: (number | undefined)[]
  expandedAreaKeys?: (number | undefined)[]
  isFillBlueInTotal?: boolean
  valueString?: string | number
  isBold?: boolean
  colorOverwrite?: string
  decimal?: number
  isShowPercent?: boolean
  percent?: number
  percentTotal?: number
  isShowValueUpAndDown?: boolean
  isShowDevelopment?: boolean
  justify?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
  classNameWhenExpanded?: string
  thirdLevelValue?: number
  percentTotalOfTotal?: number
}) {
  let key = record?.key
  if (record?.isNotExpanded || record?.isArea) {
    key = record?.childrenKey
  }
  const isExpanded = expandedRowKeys?.includes(key)

  // value
  let valueAfterChecked =
    record?.children && !isExpanded ? secondLevelValue : firstLevelValue

  let percentAfterChecked =
    record?.children && !isExpanded ? percentTotal : percent

  if ((record?.isNotExpanded || record?.isArea) && !secondLevelValue) {
    valueAfterChecked = firstLevelValue
    percentAfterChecked = percent
  }

  if (expandedAreaKeys) {
    const isExpandedTotal = expandedAreaKeys?.includes(record?.key)
    if (record?.isArea && !isExpandedTotal) {
      valueAfterChecked = thirdLevelValue
      percentAfterChecked = percentTotalOfTotal
    }
  }

  if (record?.isTotalTable) {
    valueAfterChecked = firstLevelValue
    percentAfterChecked = percent
  }

  // style class
  let classNameExpanded = ''

  if (record?.index && record?.index % 2 === 0) {
    classNameExpanded = 'is-expanded'
  }

  if (isExpanded) {
    classNameExpanded = 'is-expanded'
    if (classNameWhenExpanded) {
      classNameExpanded += ` ${classNameWhenExpanded}`
    }
  }

  return (
    <S.RevenueTableWrapper
      justify={justify ? justify : valueString ? 'center' : 'end'}
      $isBold={(record?.isTotalTable || record?.isTotalGroupTable) && isBold}
      $isBlueText={isFillBlueInTotal && record?.isTotalTable}
      colorOverwrite={colorOverwrite}
      className={`${classNameExpanded} ${
        record?.isTotalGroupTable ? 'is-total-group-table' : ''
      } ${record?.isTotalTable ? 'is-total-table' : ''}`}
    >
      {(() => {
        if (isShowDevelopment) {
          if (
            isNumber(percentAfterChecked) &&
            percentAfterChecked !== 0 &&
            !isNaN(percentAfterChecked)
          ) {
            return (
              <BaseCol>
                <S.GrowthWrapper
                  $upValue={percentAfterChecked > 0}
                  justify={'space-between'}
                  wrap={false}
                >
                  {isShowValueUpAndDown && percentAfterChecked > 0 ? (
                    <SvgArrowTrendingUpIcon />
                  ) : (
                    <SvgArrowTrendingDownIcon />
                  )}
                  {isShowPercent && (
                    <span className="value">
                      {percentAfterChecked &&
                      Number(percentAfterChecked) !== 0 ? (
                        <>{percentAfterChecked > 0 && '+'}</>
                      ) : (
                        <></>
                      )}
                      {formatMoneyWithoutSpace(
                        percentAfterChecked ?? '0',
                        '%',
                        undefined,
                        decimal,
                      )}
                    </span>
                  )}
                </S.GrowthWrapper>
              </BaseCol>
            )
          } else {
            return <BaseCol />
          }
        }
        return null
      })()}

      {valueString
        ? valueString
        : formatMoneyWithoutSpace(
            valueAfterChecked ?? '',
            prefix,
            undefined,
            decimal,
          )}
    </S.RevenueTableWrapper>
  )
}

export function convertColumnTable<T>({
  key,
  className,
  classNameWidthColumnOverwrite = 'normal-column',
  onCell,
  render,
  title,
  dataIndex,
  fixed,
  width,
}: IConvertColumnTable<T>): IColumnTable<T> {
  return {
    fixed,
    width,
    title: title ?? '',
    dataIndex: dataIndex ?? key,
    key,
    className: `all-column ${classNameWidthColumnOverwrite ?? ''} ${
      className ?? ''
    }`,
    onCell,
    render,
  }
}

export const ExpandedTableValue = ({
  isExpanded,
  handleExpand,
  value = '',
  isTotalTable,
  isNotHaveActionExpanded = false,
  className,
  classNameText = '',
  style,
  isHaveAreaWhenNotHaveActionExpanded = false,
  styleText,
  valueCustom,
  alignContainer,
}: {
  isExpanded?: boolean
  handleExpand?: () => void
  value?: string
  isTotalTable?: boolean
  isNotHaveActionExpanded?: boolean
  className?: string
  classNameText?: string
  style?: React.CSSProperties
  isHaveAreaWhenNotHaveActionExpanded?: boolean
  styleText?: React.CSSProperties
  valueCustom?: React.ReactNode
  alignContainer?: 'top' | 'middle' | 'bottom' | 'stretch'
}) => {
  if (isTotalTable) {
    return (
      <S.AreaRowWrapper
        justify={'center'}
        align={'middle'}
        className={className ? className : `is-total-table`}
        style={style}
      >
        <S.AreaText>{value}</S.AreaText>
      </S.AreaRowWrapper>
    )
  }

  return (
    <S.AreaRowWrapper
      justify={'start'}
      style={style}
      $isExpanded={isExpanded}
      className={
        className ? className : `${isExpanded ? 'is-expanded-child' : ''}`
      }
      wrap={false}
      gutter={[16, 8]}
      align={alignContainer}
    >
      {!isNotHaveActionExpanded && (
        <BaseCol>
          {!isExpanded ? (
            <SvgTableCloseIcon onClick={handleExpand} />
          ) : (
            <SvgTableOpenIcon onClick={handleExpand} />
          )}
        </BaseCol>
      )}
      {isNotHaveActionExpanded && isHaveAreaWhenNotHaveActionExpanded && (
        <BaseCol>
          <S.AreaNotExpandedReplaceIcon />
        </BaseCol>
      )}

      <BaseCol style={{ width: '100%' }}>
        {valueCustom ? (
          valueCustom
        ) : (
          <S.AreaText className={classNameText} style={styleText}>
            {value}
          </S.AreaText>
        )}
      </BaseCol>
    </S.AreaRowWrapper>
  )
}
