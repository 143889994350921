import { IParamsGetReport18 } from 'app/api/report/model/report-18'
import R from 'app/assets/R'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { useDebounce, usePagination } from 'app/hook'
import { useGetCampaignList } from 'app/react-query/hook/campaign'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  filterMore?: IParamsGetReport18
  onChangeCampaign?: (ids: string[]) => void
}

export const SearchCampaign = ({ filterMore, onChangeCampaign }: IProps) => {
  const { t } = useTranslation()

  const { flattenData } = usePagination()

  const [loadMoreValue, setLoadMoreValue] = useState(0)

  const loadMoreValueDebounce = useDebounce(loadMoreValue, 200)

  const {
    data: dataApi,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetCampaignList({
    page: initialPagination?.current,
    pageSize: 50,
    pagesize: 50,
  })

  const dataFlatten = useMemo(() => {
    return flattenData(dataApi)
  }, [dataApi])

  const dataFilter = useMemo(() => {
    return dataFlatten?.map(i => ({
      label: i?.name,
      value: i?._id,
    }))
  }, [dataApi])

  const onPopupCampaignScroll = e => {
    const heightChange =
      (e?.target?.scrollTop ?? 0) + (e?.target?.clientHeight ?? 0)
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPage) {
      setLoadMoreValue(randomMoney())
    }
  }

  useEffect(() => {
    if (loadMoreValueDebounce) {
      fetchNextPage()
    }
  }, [loadMoreValueDebounce])
  return (
    <BaseSelectWrapper
      allowClear
      value={filterMore?.campaignIds}
      placeholder={t(R.strings.all)}
      onChange={ids => onChangeCampaign?.(ids as string[])}
      mode="multiple"
      maxTagCount={0}
      options={dataFilter}
      loading={isLoading || isFetchingNextPage}
      onPopupScroll={onPopupCampaignScroll}
      filterSort={() => 0}
      listHeight={300}
    />
  )
}

const BaseSelectWrapper = styled(BaseSelect)`
  width: 100%;
  .ant-select-selector {
    padding-left: 4px !important;
  }
  .ant-select-selector::before {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    align-items: center;
    display: flex;
  }
`
