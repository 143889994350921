import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { FilterManagement } from '../DegreeManagement/components/FilterManagementTable'
import { Create } from './components/Create'
import { useHookTable } from './hook'
import { useGetHeightTable } from 'app/hook'
const DegreeManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    fetchNewData,
    filter,
    handleChangeFilter,
    industries,
  } = useHookTable()
  return (
    <S.BaseManagementWrapper>
      <S.RootWrapper size={8}>
        <BaseRow justify={'space-between'} gutter={6} align={'middle'}>
          <BaseCol>
            <FilterManagement
              handleChangeFilter={handleChangeFilter}
              filterData={filter}
              industries={industries}
            />
          </BaseCol>
          <BaseCol>
            <Create fetchTable={fetchNewData} />
          </BaseCol>
        </BaseRow>
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(195, 30) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default DegreeManagementContainer
