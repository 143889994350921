import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 8px;

  .ant-pagination {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .pagination-container {
    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-form-item-control-input {
      min-height: 1.5rem !important;
    }
  }
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .all-column {
    padding: 8px !important;
  }
  .ant-table-thead > tr > th {
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: ${convertedVariables.primaryColor};
  }
  .money-column {
    min-width: 140px !important;
  }
  .money-column-1 {
    min-width: 145px !important;
  }
  .percent-column {
    min-width: 80px !important;
  }
  .percent-column-1 {
    min-width: 109px !important;
  }
  .percent-column-2 {
    min-width: 90px !important;
  }

  .month-column {
    min-width: 70px !important;
  }

  .name-column {
    min-width: 150px !important;
  }

  .treatment-column {
    min-width: 250px !important;
  }

  .date-column {
    min-width: 88px !important;
  }

  .branch-column {
    min-width: 120px !important;
  }

  .payment-method {
    min-width: 115px !important;
  }
`
