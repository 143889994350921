import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'
import { useHookTable } from './hook'
import { FilterLayout } from './layouts'
import { useGetHeightTable } from 'app/hook'

const RefererReportContainer = () => {
  const {
    data,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    isLoading,
    pagination,
  } = useHookTable()

  return (
    <RootWrapper>
      <ContentWrapper size={0}>
        <FilterLayout formData={filter} onChange={handleChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          scroll={{
            x: true,
            y: data?.length ? useGetHeightTable(217, 15) : undefined,
          }}
        />
      </ContentWrapper>
    </RootWrapper>
  )
}
export default RefererReportContainer

const RootWrapper = styled(BaseSpace)`
  padding: 8px;
  padding-bottom: 4px;
`
const ContentWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  .all-column {
    padding: 8px !important;
  }

  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-thead .ant-table-cell {
    border-start-start-radius: 0px !important;
    border-start-end-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }

  .first-column {
    min-width: 8px !important;
  }

  .patient-name-column {
    min-width: 400px !important;
  }
  .patient-code-column {
    min-width: 60px !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
    padding: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`
