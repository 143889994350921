import { useHook } from './hook'
import { TableWrapper } from './styles'

interface IProps {
  id: string
  className?: string
}

export const TablePatients = (props: IProps) => {
  const { columns, tableData, handleTableChange } = useHook(props)
  
  return (
      <TableWrapper
        columns={columns}
        dataSource={tableData.data}
        loading={tableData.loading}
        pagination={tableData.pagination}
        onChange={handleTableChange}
        className={props?.className}
        scroll={{
          x: true,
        }}
      />
  )
}
