import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { useGetHeightTable } from 'app/hook'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, RootWrapper } from './styles'

export const Report018Container = () => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    handleExportExcel,
    isLoadingExport,
  } = useTable()

  const { isHavePermissionFunction } = useVerifyPermission()

  return (
    <RootWrapper>
      <SectionBase style={{ padding: 8, paddingBottom: 0 }}>
        <BaseSpace size={8}>
          <FilterLayout
            formData={filter}
            onChange={handleChangeFilter}
            isLoadingExport={isLoadingExport}
            isHavePermissionExport={isHavePermissionFunction(
              ModulePermissionEnum.REPORT,
              FunctionPermissionEnum.REPORT_EXPORT_REPORT_18,
            )}
            onPressExport={handleExportExcel}
          />

          <BaseTableReport
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={isLoading}
            onChange={handleTableChange}
            rowClassName="row-overwrite-style"
            scroll={{
              x: true,
              y: data?.length ? useGetHeightTable(165, 75) : undefined,
            }}
          />
        </BaseSpace>
      </SectionBase>
    </RootWrapper>
  )
}
