import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { FilterDept } from 'app/components/tables/BaseTableManagement/FilterDeptTable'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { useGetHeightTable } from 'app/hook'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PaidTypeTableEnum } from '../Debt/components/Filter/type'
import Export from './components/Export'
import TotalBox from './components/TotalBox'
import { useTableHook } from './hook'
import './styles.css'
import { BaseSpace } from 'app/components/common/BaseSpace'

const DebtContractContainer: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    filterResponse,
    onClickExport,
    metadata,
  } = useTableHook()

  const { t } = useTranslation()
  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={0}>
        <FilterDept
          handleChangeFilter={handleChangeFilter}
          filterData={filterResponse}
          listPaidType={Object.values(PaidTypeTableEnum)}
          actionComponent={<Export onPressExport={onClickExport} />}
        />
        <BaseSpace size={4}>
          <BaseRow>
            <BaseCol span={8}>
              <TotalBox
                title={t(R.strings.manage_customer_debt_payment_total)}
                total={Number(metadata?.totalNetAmount)}
              />
            </BaseCol>
            <BaseCol span={8}>
              <TotalBox
                title={t(R.strings.manage_customer_debt_deposit_total)}
                total={Number(metadata?.totalDeposit)}
              />
            </BaseCol>

            <BaseCol span={8}>
              <TotalBox
                title={t(R.strings.manage_customer_debt_paid_total)}
                total={Number(metadata?.totalPaid)}
              />
            </BaseCol>
          </BaseRow>
          <BaseTableReportWrapper
            columns={columns}
            dataSource={tableData.data}
            loading={tableData.loading}
            onChange={handleTableChange}
            rowClassName="row-overwrite-style"
            pagination={tableData.pagination}
            scroll={{
              x: true,
              y: tableData?.data?.length
                ? useGetHeightTable(365, 0)
                : undefined,
            }}
          />
        </BaseSpace>
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default DebtContractContainer

const BaseTableReportWrapper = styled(BaseTableReport)`
  .date-time-column {
    min-width: 123px !important;
  }
  .patient-code-column {
    min-width: 100px !important;
  }
  .payment-type {
    min-width: 115px !important;
  }
`
