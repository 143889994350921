import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  ImportButtonArea
} from 'app/components/header/components/HeaderPage'
import { BaseTableReport } from 'app/components/tables/BaseTableReport'
import { FilterReport } from 'app/components/tables/BaseTableReport/FilterReport'
import * as S from 'app/components/tables/BaseTableReport/styles'
import { URL_DOWNLOAD_EXAMPLE } from 'app/constant'
import 'dayjs/locale/vi'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTableHook } from './hook'

const CustomerCarePlan: React.FC = () => {
  const {
    columns,
    tableData,
    handleTableChange,
    handleChangeFilter,
    onClickImport,
  } = useTableHook()

  return (
    <S.BaseReportWrapper>
      <S.RootWrapper size={4}>
        <BaseRow align={'middle'} justify={'space-between'} gutter={16}>
          <BaseCol xl={18}>
            <FilterReport
              isSelectFilter
              handleChangeFilter={handleChangeFilter}
              isUsingWeekMonthCorrect
              selectFilterGroup="WEEK_MONTH"
            />
          </BaseCol>
          <BaseCol>
            <ImportButtonArea
              urlExample={URL_DOWNLOAD_EXAMPLE.CustomerCarePlan}
              onPressImport={onClickImport}
            />
          </BaseCol>
        </BaseRow>

        <BaseTableReport
          columns={columns}
          dataSource={tableData.data}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          pagination={tableData.pagination}
        />
      </S.RootWrapper>
    </S.BaseReportWrapper>
  )
}

export default CustomerCarePlan
