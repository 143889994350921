import { IRangeExperience } from 'app/api/degree/model/management'
import { ILevelSkill } from 'app/api/level-skill/model/management'
import { IIndustry } from 'app/api/skill/model/management'
import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import {
  StatusTableEnum,
  StatusTableType,
} from 'app/common/enum'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { SearchDropdown } from './search-dropdown'
import * as S from './styles'
import {
  IFilterProps,
  IResponseFilter,
  initResponseFilterTableManagement,
} from './type'
import styled from 'styled-components'

interface IFilter extends IFilterProps {
  industries?: IIndustry[]
  levelSkills?: ILevelSkill[]
  rangeExperiences?: IRangeExperience[]
}

export const FilterManagement = ({
  handleChangeFilter,
  filterData,
  industries,
}: IFilter) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const [isOverlayOpen, setOverlayOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.(filter)
    }, 500)
    return () => clearTimeout(timer)
  }, [filter])

  useEffect(() => {
    if (filterData && !isEqual(filterData, filter)) {
      setFilter(filterData)
    }
  }, [filterData])

  useEffect(() => {
    setOverlayOpen(false)
  }, [pathname])

  const onChangeFilterStatus = (status: StatusTableType[]) => {
    if (!status?.length) {
      status = initResponseFilterTableManagement?.status
    }
    setFilter(prev => {
      return {
        ...prev,
        status,
      }
    })
  }

  const onChangeFilter = (field, value) => {
    setFilter(prev => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  const onChangeSearch = (search: string) => {
    setFilter(prev => {
      return {
        ...prev,
        search,
      }
    })
  }

  return (
    <S.HeadWrapper gutter={16} align={'middle'}>
      <BaseCol>
        <SearchDropdown
          query={filter?.search ?? ''}
          setQuery={onChangeSearch}
          data={[]}
          isOverlayOpen={isOverlayOpen}
          setOverlayOpen={setOverlayOpen}
        />
      </BaseCol>
      <BaseCol>
        <BaseFormItem name="industry" required={false}>
          <S.SelectBaseStyled
            defaultValue={filter.status}
            value={filter.status}
            allowClear
            placeholder={t(R.strings.skill_content_select_industry)}
            suffixIcon={!filter.status?.length && <SvgTableCloseIcon />}
            onChange={industry =>
              onChangeFilter('industry', industry as string)
            }
          >
            {industries?.map((item, index) => {
              return (
                <Option value={item._id} key={index}>
                  {item.name}
                </Option>
              )
            })}
          </S.SelectBaseStyled>
        </BaseFormItem>
      </BaseCol>
      <BaseCol>
        <BaseFormItem name="select" required={false}>
          <S.SelectBaseStyled
            defaultValue={filter.status}
            value={filter.status}
            mode="multiple"
            allowClear
            placeholder={t(R.strings.all)}
            suffixIcon={!filter.status?.length && <SvgTableCloseIcon />}
            onChange={type => onChangeFilterStatus(type as StatusTableType[])}
          >
            {Object.values(StatusTableEnum)?.map((item, index) => {
              return (
                <Option value={item} key={index}>
                  {t(item)}
                </Option>
              )
            })}
          </S.SelectBaseStyled>
        </BaseFormItem>
      </BaseCol>
    </S.HeadWrapper>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
margin-bottom: 0px !important;
`