/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import { ResponseOrganizationalChartType } from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import { SvgQuestionMarkCircleIcon } from 'app/assets/svg-assets'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseTooltip } from 'app/components/common/BaseTooltip'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { getArray } from 'app/components/tables/common-table/constant'
import { initPagination } from 'app/constant'
import { Pagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFilterMonthlyRosterReport, IReportDoctorWorkTime } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IReportDoctorWorkTime[]>([])

  const [filter, setFilter] = useState<IFilterMonthlyRosterReport>({
    year: moment().year().toString(),
    month:
      moment().month() + 1 > 9
        ? `${moment().month() + 1}`
        : `0${moment().month() + 1}`,
  })

  const getData = async () => {
    try {
      setLoading(true)

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({})

      res?.data &&
        setData(
          res.data?.map((item, index) => ({
            ...item,
            key: index + 1,
            children: undefined,
            stt: index + 1,
          })),
        )
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = useMemo(() => {
    const numberDateOfMonth = new Date(
      filter?.year ? +filter?.year : new Date().getFullYear(),
      filter?.month ? +filter?.month : new Date().getMonth() + 1,
      0,
    ).getDate()

    const dates = getArray(numberDateOfMonth)?.map(index => {
      return {
        title: `${index > 9 ? index : `0${index}`}/${filter?.month}/${
          filter?.year
        }`,
        key: `date-${index}-value`,
        children: [
          convertColumnTable<any>({
            title: t(R.strings.working_hour_day),
            key: `working-hour-day-${index}`,
            className: 'working-hour-day-col',
            render: (_, record) => {
              return (
                <BaseText
                  children={`${record?.[`working-hour-day-${index}`] ?? '0'}`}
                  fontWeight="medium"
                  textAlign='right'
                />
              )
            },
          }),
          convertColumnTable<any>({
            title: t(R.strings.appointment_hour),
            key: `appointment-hour-${index}`,
            className: 'appointment-hour-col',
            render: (_, record) => {
              return (
                <BaseText
                  children={`${record?.[`appointment-hour-${index}`] ?? '0'}`}
                  fontWeight="medium"
                  textAlign='right'
                />
              )
            },
          }),
          convertColumnTable<any>({
            title: (
              <BaseRow gutter={[8, 8]} justify={'center'} align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={t(R.strings.rate)}
                    fontWeight="semibold"
                  />
                </BaseCol>
                <BaseTooltip
                  title={
                    <BaseText
                      children={`= ${t(
                        R.strings.working_hour_date_appointment_hour,
                      )}`}
                      colorText="backgroundColor"
                      fontWeight="medium"
                    />
                  }
                >
                  <SvgQuestionMarkCircleIcon />
                </BaseTooltip>
              </BaseRow>
            ),

            key: `rate-${index}`,
            className: 'rate-col',
            render: (_, record) => {
              return (
                <BaseText
                  children={`${record?.[`rate-${index}`] ?? '0'} %`}
                  fontWeight="medium"
                  textAlign='right'
                />
              )
            },
          }),
        ],
      }
    })

    const option = [
      convertColumnTable<IReportDoctorWorkTime>({
        title: t(R.strings.name_doctor),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <BaseAvatarProfile name={record?.name ?? '-'} jobTitle="-" />
        },
      }),
      convertColumnTable<IReportDoctorWorkTime>({
        title: t(R.strings.working_hour_month),
        key: 'workTime',
        className: 'hour-month-col',
        render: (_, record) => {
          return (
            <BaseText
              children={`${record?.workTime ?? '0'}`}
              fontWeight="medium"
              textAlign='right'
            />
          )
        },
      }),
      convertColumnTable<IReportDoctorWorkTime>({
        title: t(R.strings.appointment_hour),
        key: 'appointmentTime',
        className: 'hour-month-col',
        render: (_, record) => {
          return (
            <BaseText
              children={`${record?.appointmentTime ?? '0'}`}
              fontWeight="medium"
              textAlign='right'
            />
          )
        },
      }),
      convertColumnTable<IReportDoctorWorkTime>({
        title: (
          <BaseRow gutter={[8, 8]} justify={'center'} align={'middle'}>
            <BaseCol>
              <BaseText children={t(R.strings.rate)} fontWeight="semibold" />
            </BaseCol>
            <BaseTooltip
              title={
                <BaseText
                  children={`= ${t(
                    R.strings.working_hour_month_appointment_hour,
                  )}`}
                  colorText="backgroundColor"
                  fontWeight="medium"
                />
              }
            >
              <SvgQuestionMarkCircleIcon />
            </BaseTooltip>
          </BaseRow>
        ),
        key: 'ratio',
        className: 'working-hour-month-appointment-hour',
        render: (_, record) => {
          return (
            <BaseText
              children={`${record?.ratio ?? '0'}%`}
              fontWeight="medium"
              colorText="statesGreenColor"
              textAlign='right'
            />
          )
        },
      }),
      ...dates,
    ]
    return option
  }, [t, filter])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterMonthlyRosterReport) => {
    setFilter(newFilter)
  }

  return {
    tableData: {
      data,
    },
    columns,
    loading,
    filter,
    handleTableChange,
    pagination,
    handleChangeFilter,
  }
}
