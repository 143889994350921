import { BaseCol } from 'app/components/common/BaseCol'
import { BaseTable } from 'app/components/common/BaseTable'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const TotalCountWrapper = styled.div`
  padding: 3px 6px;
  border-radius: ${PADDING.md};
  background-color: ${convertedVariables.statesBlueLightColor};
`
export const TableWrapper = styled(BaseTable)`
  table {
    width: 100% !important;
  }
  table > thead > tr:first-child > *:first-child {
    border-radius: 0px !important;
  }
  table > thead > tr:last-child > *:last-child {
    border-radius: 0px !important;
  }
  .ant-table-thead > tr > th {
    border-right: 0px !important;
    border-left: 0px !important;
    background: #ebeef2;
    padding: 8px 16px;
    color: ${convertedVariables.primaryColor};
    opacity: 0.7;
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.xxs};
  }
  .all-column:has(.is-expanded-child) {
    background-color: ${convertedVariables.backgroundColor};
  }
  .ant-table-row.ant-table-row-level-1 .ant-table-cell {
    background-color: ${convertedVariables.backgroundColor} !important;
  }
  .ant-table-cell-row-hover:has(.is-expanded-child) {
    background-color: ${convertedVariables.backgroundColor} !important;
  }
  .ant-table-cell-row-hover {
    background: ${convertedVariables.backgroundColor} !important;
  }
  .ant-pagination {
    .ant-pagination-item-active {
      border-radius: ${BORDER_RADIUS};
    }
    .ant-pagination-item:not(.ant-pagination-item-active):hover {
      border-radius: ${BORDER_RADIUS};
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 0px !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
    padding: 4px !important;

    .ant-pagination-item {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-pagination-jump-next {
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.4rem;
      font-size: 12px;
    }

    .ant-select-single {
      height: 1.55rem;
    }

    .ant-select-single .ant-select-selector {
      font-size: 12px;
      font-weight: 500;
    }

    .ant-pagination-prev {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-pagination-next {
      min-width: 1.5rem !important;
      height: 1.5rem !important;
      border-radius: 4px !important;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`


export const RootWrapper = styled(BaseCol)`
  background-color: ${colors.white};
  border-radius: ${BORDER_RADIUS};
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding-left: ${PADDING.md};
  }
`
