import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'
import { useHookTable } from './hook'
import { ChildTableLayout, FilterLayout } from './layouts'
import { useGetHeightTable } from 'app/hook'
const StrategicDoctorContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    loading,
    pagination,
    expandedRowKeys,
    handleExpand,
  } = useHookTable()

  return (
    <S.BaseManagementWrapper>
      <RootWrapper size={8}>
        <FilterLayout formData={filter} onChange={handleChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={tableData.data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            expandIcon: () => null,
            showExpandColumn: false,
            expandedRowRender: record => (
              <ChildTableLayout filter={filter} record={record} />
            ),
          }}
          rowClassName="row-overwrite-style"
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(187, 30) : undefined,
          }}
        />
      </RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default StrategicDoctorContainer

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .child-column {
    color: rgba(0, 34, 68, 0.4) !important;
  }

  .important-column {
    color: ${convertedVariables.statesRedColor} !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }

  .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
    margin-inline: 0px !important;
  }

  .ant-table-expanded-row-fixed {
    padding: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }
`
