/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetOrganizationalChart } from 'app/api/company/organizational-chart'
import {
  IOrganizationalChart,
  IProfileOrganization,
  ResponseOrganizationalChartType,
} from 'app/api/company/organizational-chart/model'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IColumnTable } from 'app/components/tables/BaseTableReport/type'
import {
  getArray
} from 'app/components/tables/common-table/constant'
import {
  DateUtil,
  Pagination,
  getRandomUuid,
  initialPagination,
  moment,
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProfileMonthlyRoster } from '../../type'
import { IDataTable, IProps } from './type'

export const useHookTable = ({ record, filter }: IProps) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initialPagination)
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState<IOrganizationalChart | undefined>(
    undefined,
  )

  const getDetail = async () => {
    try {
      setLoading(true)

      // check if record is undefined
      if (!record?._id) return

      const res: ResponseOrganizationalChartType =
        await requestGetOrganizationalChart({
          unitId: record?._id,
        })

      res?.data && setDetail(res.data?.[0])
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [])

  const profiles: IProfileMonthlyRoster[] = useMemo(() => {
    let res: IProfileOrganization[] = []
    res = res.concat(detail?.managers ?? [])
    res = res.concat(detail?.vices ?? [])
    res = res.concat(detail?.profiles ?? [])
    return res?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        employeeCode: getRandomUuid(),
        startDate: DateUtil.formatYYYYMMDD(new Date()),
        numberOfDaysInMonth: 0,
        children: undefined
      } as IProfileMonthlyRoster
    })
  }, [detail, filter])

  const columnProfile = useMemo(() => {
    const year = filter?.year ?? new Date().getFullYear().toString()
    const months = filter?.month
      ? [filter?.month]
      : getArray(new Date().getMonth() + 1)?.map(itm => itm.toString())

    const options: IColumnTable<IDataTable>[] = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.doctors_and_nurses_perform),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.name}
              fontWeight="medium"
              fontSize="xs"
            />
          )
        },
      }),
      {
        title: year,
        key: 'year',
        className: 'date-column',
        children: months?.map(month => {
          const dates = new Date(parseInt(year), parseInt(month), 0).getDate()
          const days = getArray(dates)?.map(itm => itm.toString())
          const monthFormat = +month > 9 ? month : `0${month}`

          return {
            title: `${t(R.strings.month_no, { month })}`,
            key: month,
            className: 'date-column',
            children: days?.map(day => {
              const dateString = +day > 9 ? day : `0${day}`
              const date = `${year}-${monthFormat}-${dateString}`
              const dateFormat = moment(date)
                .locale('vi')
                .format('ddd DD/MM')

              return convertColumnTable<any>({
                title: dateFormat,
                key: day,
                classNameWidthColumnOverwrite: 'medium-column',
                className: 'date-column',
                render: () => {
                  return (
                    <BaseText
                      children={0}
                      fontWeight="medium"
                      fontSize="xs"
                      textAlign="center"
                      opacity='0.5'
                    />
                  )
                },
              })
            }),
          }
        }),
      },
    ]

    return options
  }, [t, filter])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    loading,
    handleTableChange,
    pagination,
    profiles,
    columnProfile,
  }
}
