/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import { IOldDoctor } from 'app/api/old-db-user/model'
import { requestGetDoctors } from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import { IParamsGetPatientSearch } from 'app/api/treatment/model'

import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import { useDebounce, usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { useGetTreatmentGroupList } from 'app/react-query/hook/old-db'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { flatMap } from 'lodash'
import {
  Dates,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_ONLY_MONTH,
  Pagination,
  initialPagination,
  moment,
} from 'parkway-web-common'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  onChange?: (value: IParamsGetPatientSearch) => void
  formData?: IParamsGetPatientSearch
  actionComponent?: ReactNode
}
export const FilterLayout: React.FC<IProps> = ({
  formData,
  onChange,
  actionComponent,
}) => {
  const { flattenDataList, flattenData } = usePagination()

  const [listDoctor, setListDoctor] = useState<IOldDoctor[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 30,
  })
  const [keywordSearchArea, setKeywordSearchArea] = useState('')
  const [keywordSearchTreatment, setKeywordSearchTreatment] = useState<
    string | undefined
  >(undefined)

  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [clinicIds, setClinicIds] = useState<string[]>([])

  const [loadMoreTreatment, setLoadMoreTreatment] = useState(0)
  const loadMoreTreatmentDebounce = useDebounce(loadMoreTreatment, 200)
  const keywordSearchTreatmentDebounce = useDebounce(
    keywordSearchTreatment,
    200,
  )

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })
  const {
    data: treatmentGroupApi,
    isLoading,
    hasNextPage: hasNextPageTreatment,
    fetchNextPage: fetchNextPageTreatment,
    isFetchingNextPage,
  } = useGetTreatmentGroupList({
    keyword: keywordSearchTreatmentDebounce,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)
    return res?.map(item => ({
      ...item,
      childs: item?.childs
        ?.filter(child => !!child?._oldId)
        ?.map(item => ({
          ...item,
          _id: item?._oldId ?? item?._id,
        })),
    }))
  }, [areaApi])

  const dataTreatmentFlatten = useMemo(() => {
    return flattenData(treatmentGroupApi)
  }, [treatmentGroupApi])

  const dataTreatmentGroup = useMemo(() => {
    return dataTreatmentFlatten?.map(i => ({
      label: i?.name,
      value: i?._id,
    }))
  }, [treatmentGroupApi])

  const onPopupTreatmentScroll = e => {
    const heightChange = e?.target?.scrollTop + (e?.target?.clientHeight ?? 0)
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPageTreatment) {
      setLoadMoreTreatment(randomMoney())
    }
  }

  const onChangeTreatmentSearch = (keyword: string) => {
    setKeywordSearchTreatment(keyword)
    setPagination({ ...pagination, current: 1 })
  }
  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange &&
      onChange({ ...formData, keyword: value } as IParamsGetPatientSearch)
  }

  const filterTreeNode = (value?: string, treeNode?: any) => {
    return removeVietnameseTones(treeNode?.title)
      .toLowerCase()
      .includes(removeVietnameseTones(value).toLowerCase())
  }

  const onChangeClinic = (ids: string[]) => {
    setClinicIds(ids)
  }
  const onChangeTreatment = (ids: string[]) => {
    onChange &&
      onChange({
        ...formData,
        treatmentIds: ids as string[],
      } as IParamsGetPatientSearch)
  }
  const onChangeDoctor = (ids: string[]) => {
    onChange &&
      onChange({
        ...formData,
        doctorIds: ids as string[],
      } as IParamsGetPatientSearch)
  }

  const onChangeYear = (value: string) => {
    const startDate = moment(`${value}-${month}`, 'YYYY-MM')
      .startOf('month')
      .format('YYYY-MM-DD')

    const endDate = moment(`${value}-${month}`, 'YYYY-MM')
      .endOf('month')
      .format('YYYY-MM-DD')
    setYear(value)
    onChange && onChange({ ...formData, startDate, endDate })
  }

  const onChangeMonth = (_, value: string) => {
    const startDate = moment(`${year}-${value}`, 'YYYY-MM')
      .startOf('month')
      .format('YYYY-MM-DD')

    const endDate = moment(`${year}-${value}`, 'YYYY-MM')
      .endOf('month')
      .format('YYYY-MM-DD')

    setMonth(value)
    onChange && onChange({ ...formData, startDate, endDate })
  }

  useEffect(() => {
    if (loadMoreTreatmentDebounce) {
      fetchNextPageTreatment()
    }
  }, [loadMoreTreatmentDebounce])

  useEffect(() => {
    const clinicList = flatMap(areaList, 'childs')
    const newClinicOldIds = clinicIds?.map(
      item => clinicList?.find(i => i?._id === item)?.oldid,
    )
    onChange &&
      onChange({
        ...formData,
        clinicOldIds: newClinicOldIds?.length > 0 ? newClinicOldIds : undefined,
      } as IParamsGetPatientSearch)
  }, [clinicIds?.length])

  useEffect(() => {
    const getDoctors = async () => {
      try {
        const response = await requestGetDoctors()
        if (response?.msgcode === ProfileMessageCode.Doctor.successGetData) {
          setListDoctor(response?.data)
        }
      } catch (error) {
        notificationController.error({
          message: 'get Doctor fail',
        })
      }
    }
    getDoctors()
  }, [])

  return (
    <BaseForm>
      <RootWrapper gutter={[12, 0]} align={'middle'}>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_keyword_search)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              allowClear
              mode="multiple"
              $prefix={t(R.strings.service_used)}
              value={formData?.treatmentIds}
              $prefixWidth={105}
              placeholder={t(R.strings.all)}
              onSearch={keyword => onChangeTreatmentSearch(keyword)}
              options={dataTreatmentGroup}
              loading={isLoading || isFetchingNextPage}
              onChange={ids => onChangeTreatment(ids as string[])}
              onPopupScroll={onPopupTreatmentScroll}
              maxTagCount={0}
              filterSort={() => 0}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseTreeSelectWrapper
              placeholder={t(R.strings.all)}
              $prefix={t(R.strings.clinic)}
              $prefixWidth={100}
              loading={isLoadingArea}
              width={'100%'}
              treeData={transformDataSelectTree(areaList ?? [], {
                title: 'name',
                value: '_id',
                children: 'childs',
              })}
              value={clinicIds}
              multiple
              treeCheckable={true}
              onSearch={keyword => setKeywordSearchArea?.(keyword)}
              filterTreeNode={filterTreeNode}
              searchValue={keywordSearchArea}
              maxTagCount={0}
              onChange={ids => onChangeClinic?.(ids as string[])}
              allowClear
              style={{
                minWidth: 220,
              }}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>{actionComponent}</BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              allowClear
              mode="multiple"
              $prefix={t(R.strings.doctor)}
              value={formData?.doctorIds}
              $prefixWidth={50}
              placeholder={t(R.strings.all)}
              options={listDoctor?.map(item => ({
                label: item?.name,
                value: item?._id,
              }))}
              onChange={ids => onChangeDoctor(ids as string[])}
              maxTagCount={0}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.year)}
              placeholder={t(R.strings.all)}
              $prefixWidth={70}
              picker="year"
              value={year ? Dates.getDate(`${year}-01-01`) : null}
              onChange={(_, value) => onChangeYear(value)}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.month)}
              placeholder={t(R.strings.all)}
              picker="month"
              value={
                year && month ? Dates.getDate(`${year}-${month}-01`) : null
              }
              onChange={onChangeMonth}
              allowClear
              $prefixWidth={70}
              format={FORMAT_ONLY_MONTH}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``
const Picker = styled(BaseDatePicker)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;

  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
  }
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }

  .ant-select-selection-item {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.9;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
const BaseTreeSelectWrapper = styled(BaseTreeSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`
