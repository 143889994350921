import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { PHONE_REGEX, REG_EMAIL } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFormRule, StatusEnum } from './type'

const FormItem = styled(BaseForm.Item)``

const FormInput = styled(BaseInput)``

const SelectBaseStyled = styled(BaseSelect)``

export const FormCommon = ({ isUpdate }: { isUpdate?: boolean }) => {
  const { t } = useTranslation()
  const rules: IFormRule = useMemo(() => {
    return {
      code: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_content_employeeid),
          }),
        },
      ],
      firstName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.first_name),
          }),
        },
      ],
      lastName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.last_name),
          }),
        },
      ],
      employeeId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.employee_id),
          }),
        },
      ],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone),
          }),
        },
        {
          pattern: PHONE_REGEX,
          message: t(R.strings.regex_phone),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
        {
          pattern: REG_EMAIL,
          message: t(R.strings.regex_email),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
    }
  }, [t])
  return (
    <BaseSpace size={8}>
      <BaseRow gutter={20} align={'top'} justify={'space-between'}>
        <BaseCol xl={12}>
          <FormItem
            name="firstName"
            label={t(R.strings.first_name)}
            rules={rules.firstName}
            required
          >
            <FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.first_name),
              })}
            />
          </FormItem>
        </BaseCol>
        <BaseCol xl={12}>
          <FormItem
            name="lastName"
            required
            label={t(R.strings.last_name)}
            rules={rules.lastName}
          >
            <FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.last_name),
              })}
            />
          </FormItem>
        </BaseCol>
      </BaseRow>
      <BaseRow gutter={20} align={'top'} justify={'space-between'}>
        <BaseCol xl={12}>
          <FormItem
            name="code"
            required
            label={t(R.strings.employee_id)}
            rules={rules.code}
          >
            <FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.employee_id),
              })}
            />
          </FormItem>
        </BaseCol>
        <BaseCol xl={12}>
          <FormItem
            name="phone"
            label={t(R.strings.phone)}
            rules={rules.phone}
            required
          >
            <FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.phone),
              })}
            />
          </FormItem>
        </BaseCol>
      </BaseRow>
      <FormItem
        name="email"
        label={t(R.strings.email)}
        rules={rules.email}
        required
      >
        <FormInput
          placeholder={t(R.strings.enter_field, {
            field: t(R.strings.email),
          })}
        />
      </FormItem>
      {isUpdate ? (
        <FormItem
          label={t(R.strings.status)}
          name="status"
          rules={rules.status}
          required
        >
          <SelectBaseStyled
            placeholder={t(R.strings.please_select_status)}
            suffixIcon={<SvgTableCloseIcon />}
          >
            {Object.values(StatusEnum)?.map((item, index) => {
              return (
                <Option value={item} key={index}>
                  {t(item)}
                </Option>
              )
            })}
          </SelectBaseStyled>
        </FormItem>
      ) : null}
    </BaseSpace>
  )
}
