import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import dayjs from 'dayjs'
import { FORMAT_ONLY_MONTH } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFilter } from '../../type'
import { NotePopover } from './layouts'
import { useGetAreaList } from 'app/react-query/hook/location'
import { StatusEnum } from 'app/common/enum'
import { usePagination } from 'app/hook'
import { useMemo } from 'react'
import { IClinic } from 'app/model/unit.model'
import { useGetJobTitleManagementList } from 'app/react-query/hook/job-title'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'

interface IProps {
  filter: IFilter
  onChange?: (value: IFilter) => void
}

export const FilterLayout = ({ filter, onChange }: IProps) => {
  const { t } = useTranslation()

  const { flattenDataList } = usePagination()

  const { data: clinicData, isLoading: loadingClinic } = useGetAreaList({
    typeid: 'clinic',
    status: StatusEnum.ACTIVE,
    pagesize: 30,
  })

  const { data: jobTitleData, isLoading: loadingJobTitle } = useGetJobTitleManagementList()

  const clinics: IClinic[] = useMemo(() => {
    return flattenDataList(clinicData)
  }, [clinicData])

  const jobTitles: IJobTitle[] = useMemo(() => {
    return flattenDataList(jobTitleData)?.data ?? []
  }, [jobTitleData])
  

  const onChangeYear = (_, year: string) => {
    onChange && onChange({ ...filter, year })
  }

  const onChangeMonth = (_, month: string) => {
    onChange && onChange({ ...filter, month })
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ ...filter, keyword: e.target.value })
  }

  const onChangeClinic = (clinicIds: string[]) => {
    onChange && onChange({ ...filter, clinicIds })
  }

  const onChangeJobTitle = (jobTitleIds: string[]) => {
    onChange && onChange({ ...filter, jobTitleIds })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[8, 0]} align={'top'} justify={'space-between'}>
        <BaseCol xl={22}>
          <BaseRow gutter={[8, 0]} align={'middle'} justify={'start'}>
            <BaseCol xl={4}>
              <BaseFormItem hiddenLabel>
                <BaseDatePicker
                  $prefix="Year *"
                  picker="year"
                  placeholder="Select year"
                  value={dayjs(`${filter?.year}-01-01`)}
                  onChange={onChangeYear}
                  $prefixWidth={100}
                  allowClear={false}
                />
              </BaseFormItem>
            </BaseCol>
            <BaseCol xl={4}>
              <BaseFormItem hiddenLabel>
                <BaseDatePicker
                  $prefix="Month *"
                  picker="month"
                  format={FORMAT_ONLY_MONTH}
                  placeholder="Select month"
                  value={dayjs(
                    `${filter?.year}-${
                      +filter?.month < 10 ? `0${filter?.month}` : filter?.month
                    }-01`,
                  )}
                  onChange={onChangeMonth}
                  $prefixWidth={160}
                  allowClear={false}
                />
              </BaseFormItem>
            </BaseCol>

            <BaseCol xl={4}>
              <BaseFormItem hiddenLabel>
                <BaseSelect
                  $prefix={t(R.strings.clinic)}
                  placeholder={t(R.strings.all)}
                  value={filter?.clinicIds}
                  $prefixWidth={90}
                  allowClear={false}
                  options={clinics?.map(itm => ({
                    label: itm?.name,
                    value: itm?.oldid,
                  }))}
                  onChange={value => onChangeClinic(value as string[])}
                  showSearch
                  maxTagCount={0}
                  style={{ minWidth: 120 }}
                  loading={loadingClinic}
                />
              </BaseFormItem>
            </BaseCol>

            <BaseCol xl={4}>
              <BaseFormItem hiddenLabel>
                <BaseSelect
                  $prefix={'Chức danh'}
                  placeholder={t(R.strings.all)}
                  value={filter?.clinicIds}
                  $prefixWidth={80}
                  allowClear={false}
                  options={jobTitles?.map(itm => ({
                    label: itm?.name,
                    value: itm?._id,
                  }))}
                  onChange={value => onChangeJobTitle(value as string[])}
                  showSearch
                  maxTagCount={0}
                  style={{ minWidth: 120 }}
                  loading={loadingJobTitle}
                />
              </BaseFormItem>
            </BaseCol>

            <BaseCol xl={4}>
              <BaseFormItem hiddenLabel>
                <BaseSelect
                  $prefix={'Loại đăng kí'}
                  placeholder={t(R.strings.all)}
                  value={filter?.status}
                  $prefixWidth={90}
                  allowClear={false}
                  options={[
                    { label: 'Có đăng kí', value: 1 },
                    { label: 'Không đăng kí', value: 2 },
                  ]}
                  onChange={value => onChangeJobTitle(value as string[])}
                  style={{ minWidth: 120 }}
                />
              </BaseFormItem>
            </BaseCol>

            <BaseCol xl={4}>
              <BaseFormItem hiddenLabel>
                <BaseInput
                  placeholder={t(R.strings.enter_name)}
                  value={filter?.keyword}
                  onChange={onChangeSearch}
                  allowClear
                />
              </BaseFormItem>
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol>
          <BaseFormItem hiddenLabel>
            <NotePopover />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const RootWrapper = styled(BaseRow)`
  padding: 8px;
`
