import { requestGetAllDegreeManagement } from 'app/api/degree'
import { DegreeMessageCode } from 'app/api/degree/constant'
import { IDegree } from 'app/api/degree/model/management'
import { requestGetAllDegreeClassificationManagement } from 'app/api/degreeclassification'
import { DegreeClassificationMessageCode } from 'app/api/degreeclassification/constant'
import { IDegreeClassification } from 'app/api/degreeclassification/model/management'
import { requestGetAllAccountManagement } from 'app/api/human'
import { IAccountGetAllManagement } from 'app/api/human/model/account-management'
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import { requestGetAllJobTitleManagement } from 'app/api/jobtitle'
import { JobTitleMessageCode } from 'app/api/jobtitle/constant'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import { requestGetAllLevelSkillManagement } from 'app/api/level-skill'
import { LevelSkillMessageCode } from 'app/api/level-skill/constant'
import { requestCreateProfileManagement } from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import {
  IPayloadCreateProfileManagement,
  IPayloadCreateWorkInfo,
} from 'app/api/profile/model/management'
import { requestGetAllSkillManagement } from 'app/api/skill'
import { SkillMessageCode } from 'app/api/skill/constant'
import { IIndustry, ILevelSkill, ISkill } from 'app/api/skill/model/management'
import { requestGetAllUnitManagement } from 'app/api/unit'
import { UnitTypeMessageCode } from 'app/api/unittype/constant'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { IUnit } from 'app/model/unit.model'
import { ProfileKeys } from 'app/react-query/query-key/profile-profile'
import { isEmpty, isEqual } from 'lodash'
import { PHONE_REGEX, REG_EMAIL, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { StatusUnitType } from '.'
import { IFormData, IFormRule } from './type'

export const useModalHook = props => {
  const { t } = useTranslation()
  const query = useQueryClient()

  const navigate = useNavigate()
  const [form] = BaseForm.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<StatusUnitType | undefined>()
  const [jobTitles, setJobTitles] = useState<IJobTitle[]>([])
  const [units, setUnit] = useState<IUnit[]>([])
  const [skills, setSkill] = useState<ISkill[]>([])
  const [industries, setIndustries] = useState<IIndustry[]>([])
  const [levelSkills, setLevelSkills] = useState<IIndustry[]>([])
  const [degrees, setDegrees] = useState<IDegree[]>([])
  const [accounts, setAccount] = useState<IAccountGetAllManagement[]>([])
  const [degreeClassifications, setDegreeClassifications] = useState<
    IDegreeClassification[]
  >([])

  const handleCancel = () => {
    form.resetFields()
    navigate(-1)
  }

  const handleSubmit = async (values: IFormData) => {
    setIsLoading(true)
    try {
      const mapWorkinfoData = (workinfoData, isMain = true) => {
        return !isEmpty(workinfoData)
          ? workinfoData.map(workinfo => ({
              ...workinfo,
              isMain: isMain,
              status: StatusEnum.ACTIVE,
            }))
          : undefined
      }

      const workinfos = mapWorkinfoData(values?.workinfos)
      const workinfoakas = mapWorkinfoData(values?.workinfoakas, false) ?? []

      const combinedWorkInfo: IPayloadCreateWorkInfo[] = [
        ...workinfos,
        ...workinfoakas,
      ]

      const degrees = values?.degrees?.map(degree => {
        const firstDocument = degree.document && degree.document[0]
        return {
          ...degree,
          document: {
            size: firstDocument?.size ?? null,
            type: firstDocument?.type ?? null,
            url: firstDocument?.response?.data?.imageUrl ?? null,
          },
        }
      })

      const payload: IPayloadCreateProfileManagement = {
        firstname: values?.firstname,
        lastname: values?.lastname,
        employeeid: values?.employeeid,
        employeeOldId: values?.employeeOldId,
        mobile: values?.mobile,
        email: values?.email,
        accountid: values?.accountid,
        action_account: values?.action_account,
        skills: values?.skills ?? undefined,
        avatar: values?.avatar ?? undefined,
        degrees: degrees ?? undefined,
        status: StatusEnum.ACTIVE,
        workinfos: combinedWorkInfo as IPayloadCreateWorkInfo[],
      }

      const response: ResponseType<string> =
        await requestCreateProfileManagement(payload)
      if (
        isEqual(
          response?.msgcode,
          ProfileMessageCode.ProfileManagement.successPostData,
        )
      ) {
        setIsLoading(false)
        handleCancel()
        form.resetFields()
        props?.fetchTable?.()
        query.fetchQuery({
          queryKey: ProfileKeys.allProfiles,
        })
        notificationController.success({
          message: t(R.strings.profile_content_create_profile_success),
          description: response?.message,
        })
      } else {
        notificationController.error({
          message: t(R.strings.profile_content_add),
          description: response?.message,
        })
      }

      setIsLoading(false)
      // eslint-disable-next-line
    } catch (error: any) {
      notificationController.error({
        message: t(R.strings.profile_content_add),
        description: error.message,
      })
      setIsLoading(false)
    }
  }

  const getJobTitles = async () => {
    try {
      const res: ResponseType<IJobTitle[]> =
        await requestGetAllJobTitleManagement()
      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IJobTitle[] = res?.data || []
        setJobTitles(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getAccount = async () => {
    try {
      const res: ResponseType<IAccountGetAllManagement[]> =
        await requestGetAllAccountManagement()
      if (
        isEqual(
          res?.msgcode,
          JobTitleMessageCode.JobTitleManagement.successGetData,
        )
      ) {
        const data: IAccountGetAllManagement[] = res?.data || []
        setAccount(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getUnitAll = async () => {
    try {
      const res: ResponseType<IUnit[]> = await requestGetAllUnitManagement()
      if (
        isEqual(
          res?.msgcode,
          UnitTypeMessageCode.UnitTypeManagement.successGetData,
        )
      ) {
        const data: IUnit[] = res?.data || []
        setUnit(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getSkillAll = async () => {
    try {
      const res: ResponseType<ISkill[]> = await requestGetAllSkillManagement()
      if (
        isEqual(res?.msgcode, SkillMessageCode.SkillManagement.successGetData)
      ) {
        const data: ISkill[] = res?.data || []
        setSkill(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getIndustryAll = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()
      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustries(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getLevelSkillAll = async () => {
    try {
      const res: ResponseType<ILevelSkill[]> =
        await requestGetAllLevelSkillManagement()
      if (
        isEqual(
          res?.msgcode,
          LevelSkillMessageCode.LevelSkillManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setLevelSkills(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getDegreeAll = async () => {
    try {
      const res: ResponseType<IDegree[]> = await requestGetAllDegreeManagement()
      if (
        isEqual(res?.msgcode, DegreeMessageCode.DegreeManagement.successGetData)
      ) {
        const data: IDegree[] = res?.data || []
        setDegrees(data)
      }
    } catch (error) {
      // console.log({ error })
    }
    return []
  }

  const getAllDegreeClassification = async () => {
    try {
      const res: ResponseType<IDegreeClassification[]> =
        await requestGetAllDegreeClassificationManagement()

      if (
        isEqual(
          res?.msgcode,
          DegreeClassificationMessageCode.DegreeClassificationManagement
            .successGetData,
        )
      ) {
        const data: IDegreeClassification[] = res?.data || []
        setDegreeClassifications(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    getJobTitles()
    getUnitAll()
    getAccount()
    getSkillAll()
    getIndustryAll()
    getLevelSkillAll()
    getDegreeAll()
    getAllDegreeClassification()
  }, [])

  const rules: IFormRule = useMemo(() => {
    return {
      firstname: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_field_first_name),
          }),
        },
      ],
      lastname: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_field_last_name),
          }),
        },
      ],
      action_account: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_field_account),
          }),
        },
      ],
      mobile: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_field_mobile),
          }),
        },
        {
          pattern: PHONE_REGEX,
          message: t(R.strings.regex_phone),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_field_email),
          }),
        },
        {
          pattern: REG_EMAIL,
          message: t(R.strings.regex_email),
        },
      ],
      employeeid: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.profile_field_employeeid),
          }),
        },
      ],
      status: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status),
          }),
        },
      ],
      industries: {
        _id: [
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.profile_field_skill),
            }),
          },
        ],
        industry: [
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.profile_field_industry),
            }),
          },
        ],
        level: [
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.profile_field_level),
            }),
          },
        ],
      },
      workinfos: {
        jobtitleid: [
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.profile_field_workinfo_job_title),
            }),
          },
        ],
        unitid: [
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.profile_field_workinfo_unitid),
            }),
          },
        ],
        startdate: [
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.profile_field_workinfo_start_date),
            }),
          },
        ],
        status: [
          {
            required: true,
            message: t(R.strings.require_field, {
              field: t(R.strings.status),
            }),
          },
        ],
      },
    }
  }, [t])

  return {
    form,
    t,
    isLoading,
    handleSubmit,
    handleCancel,
    rules,
    setStatus,
    status,
    jobTitles,
    units,
    accounts,
    skills,
    industries,
    levelSkills,
    degrees,
    degreeClassifications,
  }
}
