import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { isEqual } from 'lodash'
import { ClinicTypeEnumList, DefineClinicType } from '../../type'
import { useModalHook } from './hook'
import * as S from './styles'
import { IFormData } from './type'

enum StatusUnitOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export const initValues: IFormData = {
  code: '',
  name: '',
  description: '',
  _id: '',
  status: StatusUnitOptionsEnum.active,
}

export type StatusUnitType = keyof typeof StatusUnitOptionsEnum

export interface IPropsCreateUnit {
  fetchTable: () => void
}

export const Create = (props: IPropsCreateUnit) => {
  const {
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    unitGroups,
    unitTypes,
    jobTitles,
    type,
    onChangeClinicType,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      titleKeyI18n={R.strings.unit_content_add}
      isLoading={isLoading}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <S.FormItem
            name="code"
            label={t(R.strings.unit_content_id)}
            rules={rules.code}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.unit_content_id),
              })}
            />
          </S.FormItem>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.unit_content_unitTypeId)}
                name="unitTypeId"
                rules={rules.unitTypeId}
                required
              >
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings.unit_content_please_select_unitTypeId,
                  )}
                  options={unitTypes?.map(unit => ({
                    value: unit?._id,
                    label: unit?.name,
                  }))}
                  showSearch
                  onChange={id => onChangeClinicType(id as string)}
                />
              </S.FormItem>
            </BaseCol>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.unit_content_unit_department)}
                name="unitGroupId"
                rules={rules.unitGroupId}
                required
              >
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings.unit_content_please_select_unit_department,
                  )}
                  options={unitGroups?.map(unitGroup => ({
                    value: unitGroup?._id,
                    label: unitGroup?.name,
                  }))}
                  showSearch
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>

          {isEqual(type?.toLowerCase(), 'Clinic'.toLowerCase()) ? (
            <S.FormItem
              label={t(R.strings.clinic_type)}
              name="clinicType"
              rules={rules.clinicType}
              required
            >
              <S.SelectBaseStyled
                placeholder={t(R.strings.select_clinic_type)}
                options={ClinicTypeEnumList?.map(type => ({
                  value: type,
                  label: t(DefineClinicType[type].keyI18n),
                }))}
                showSearch
              />
            </S.FormItem>
          ) : null}

          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.unit_field_job_title_management)}
                name="jobTitleIdManagement"
              >
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings
                      .unit_content_please_select_unit_job_title_management,
                  )}
                  showSearch
                  options={jobTitles?.map(jobTitle => ({
                    value: jobTitle?._id,
                    label: jobTitle?.name,
                  }))}
                />
              </S.FormItem>
            </BaseCol>

            <BaseCol xl={12}>
              <S.FormItem
                label={t(R.strings.unit_field_job_title_vice)}
                name="jobTitleIdVice"
              >
                <S.SelectBaseStyled
                  placeholder={t(
                    R.strings.unit_content_please_select_unit_job_title_vice,
                  )}
                  showSearch
                  options={jobTitles?.map(jobTitle => ({
                    value: jobTitle?._id,
                    label: jobTitle?.name,
                  }))}
                />
              </S.FormItem>
            </BaseCol>
          </BaseRow>
          <S.FormItem
            name="name"
            label={t(R.strings.unit_content_name_create)}
            rules={rules.name}
            required
          >
            <S.FormInput
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.unit_content_name_create),
              })}
            />
          </S.FormItem>
          <S.FormItem
            name="description"
            label={t(R.strings.unit_content_description)}
            rules={rules.description}
            required
          >
            <S.FormInput.TextArea
              showCount
              maxLength={300}
              placeholder={t(R.strings.enter_field, {
                field: t(R.strings.unit_content_description),
              })}
            />
          </S.FormItem>
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.unit_content_add}
      confirmKeyI18n={R.strings.unit_content_add}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
