import R from 'app/assets/R'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { FormCommon } from '../hook'
import { useModalHook } from './hook'
import { IPropsUpdateAccount } from './type'
import { initValues } from '../type'

export const UpdateAccount = (props: IPropsUpdateAccount) => {
  const {
    form,
    visible,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
  } = useModalHook(props)

  return (
    <BaseModalManagement
      isLoading={isLoading}
      typeButtonOpen="update"
      titleKeyI18n={R.strings.update_account}
      content={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <FormCommon isUpdate />
        </BaseForm>
      }
      showModal={showModal}
      handleCancel={handleCancel}
      textOpenModalI18n={R.strings.update_account}
      confirmKeyI18n={R.strings.update_account}
      handleSubmit={form.submit}
      visible={visible}
    />
  )
}
